import { combineReducers } from 'redux';
import * as actions from './actions';

export const bootstrap = (state = {}, { type, payload }) => {
  if (type === actions.CLIENT_DATA_INITIALIZED) {
    return payload.bootstrap || state;
  }
  return state;
};

export const flags = (state = {}, { type, payload }) => {
  if (type === actions.CLIENT_DATA_INITIALIZED) {
    return payload.flags;
  }

  if (type === actions.FLAG_CHANGES_RECEIVED) {
    return {
      ...state,
      ...payload,
    };
  }
  return state;
};

export const user = (state = null, { type, payload }) => {
  if (type === actions.CLIENT_DATA_INITIALIZED) {
    return payload.user;
  }
  return state;
};

export const userHash = (state = null, { type, payload }) => {
  if (type === actions.CLIENT_DATA_INITIALIZED) {
    return payload.userHash;
  }

  return state;
};

export const reducer = combineReducers({
  bootstrap,
  flags,
  user,
  userHash,
});
