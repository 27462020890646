import { useProgressIndicator } from '@/lib/shared/hooks';
import Router from 'next/router';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { parse } from 'url';
import {
  getRoutePathname,
  getRouteQuery,
  getUrlFromAsPath,
} from '../../helpers';
import {
  beforeHistoryChange,
  routeChangeComplete,
  routeChangeStart,
} from '../../store';

// We can use the masked "asPath" value to figure out the underlying route details
const toPayload = (asPath) => {
  const { href = {} } = getUrlFromAsPath(asPath) || {};
  const { pathname, query } = parse(href, true);
  return { asPath, pathname, query };
};

RouterHistoryProvider.propTypes = {
  children: PropTypes.node,
};

export function RouterHistoryProvider({ children }) {
  const dispatch = useDispatch();
  const setIsProgressing = useProgressIndicator();
  /**
   * The router is only available client-side, so subscribe to all events when the
   * component is added to DOM
   */
  useEffect(() => {
    const onBeforeHistoryChange = (asPath) => {
      dispatch(beforeHistoryChange(toPayload(asPath)));
    };

    const onRouteChangeStart = (asPath) => {
      dispatch(routeChangeStart(toPayload(asPath)));
      setIsProgressing(true);
    };

    // Here we will use pathname and query from the actual Router to ensure accuracy
    const onRouteChangeComplete = (asPath) => {
      dispatch(
        routeChangeComplete({
          asPath,
          pathname: getRoutePathname(),
          query: getRouteQuery(),
        })
      );
      setIsProgressing(false);

      /*
       * Currently, using next/link to change routes resets the window scroll position to 0,
       * but navigating via next/router does not. Therefore, we should reset
       * the scroll position ourselves by subscribing to next/router route changes.
       */
      window.scrollTo(0, 0);
    };

    // Here we will use pathname and query from the actual Router to ensure accuracy
    const onRouteChangeError = (error, asPath) => {
      dispatch(
        routeChangeStart({
          asPath,
          error,
          pathname: getRoutePathname(),
          query: getRouteQuery(),
        })
      );
      setIsProgressing(false);
    };

    Router.events.on('beforeHistoryChange', onBeforeHistoryChange);
    Router.events.on('routeChangeComplete', onRouteChangeComplete);
    Router.events.on('routeChangeStart', onRouteChangeStart);
    Router.events.on('routeChangeError', onRouteChangeError);

    const unsubscribeFromEvents = () => {
      Router.events.off('beforeHistoryChange', onBeforeHistoryChange);
      Router.events.off('routeChangeComplete', onRouteChangeComplete);
      Router.events.off('routeChangeStart', onRouteChangeStart);
      Router.events.off('routeChangeError', onRouteChangeError);
    };
    return unsubscribeFromEvents;
  }, [dispatch, setIsProgressing]);

  return React.Children.only(children);
}
