import { APP_MOUNTED, getIsAppMounted } from '@/lib/shared/store';
import { delay } from 'redux-saga';
import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import { getInLocalStorage, setInLocalStorage } from '@/lib/utils';
import { USER_SETTINGS_LOCAL_STORAGE_KEY } from '../constants';
import * as actions from './actions';
import * as selectors from './selectors';

function* startNotificationTimeout(action) {
  const { duration, persist } = action.payload;
  const id = yield select(selectors.getLastNotificationId);

  if (!persist) {
    yield call(delay, duration);
    yield put(actions.notificationExpired(id));
  }
}

function* loadUserSettings() {
  const loadedState = getInLocalStorage(USER_SETTINGS_LOCAL_STORAGE_KEY);
  yield put(actions.userSettingsLoaded(loadedState));
}

function* saveUserSettings() {
  const userSettings = yield select(selectors.getUserSettings);

  yield setInLocalStorage(USER_SETTINGS_LOCAL_STORAGE_KEY, userSettings);
}

/**
 * @deprecated redux-saga's are deprecated.
 * Only register.js should import the saga file as we continue to migrate away
 */
export const saga = function* communicationSaga() {
  const isBrowser = typeof window !== 'undefined';
  const isMounted = yield select(getIsAppMounted);
  const loadUserSettingsOnMount = function* loadOnMount() {
    yield take(APP_MOUNTED);
    yield fork(loadUserSettings);
  };

  if (isBrowser) {
    yield fork(isMounted ? loadUserSettings : loadUserSettingsOnMount);
  }

  yield all([
    takeEvery(actions.NOTIFICATION_SHOWN, startNotificationTimeout),
    takeEvery([actions.TOOLTIP_SHOWN, actions.TOUR_SHOWN], saveUserSettings),
  ]);
};
