import { isMatch } from '@/lib/utils';
import _ from 'lodash';
import { createSelector } from 'reselect';
import { NAME } from '../constants';
import { Error, Request } from '../models';

// Error Selectors
const getErrorsState = (state) => _.get(state, [NAME, 'errors']);

export const getErrorById = (state, id) => _.get(state, [NAME, 'errors', id]);

export const getErrors = createSelector(getErrorsState, _.values);

export const getOrderedErrors = (state) =>
  _.orderBy(getErrors(state), ['lastUpdatedAt'], ['desc']);

export const getErrorsByTag = (state, tag) =>
  getErrors(state).filter((error) => error.tag === tag);

export const getOrderedErrorsByTag = (state, tag) =>
  _.orderBy(getErrorsByTag(state, tag), ['lastUpdatedAt'], ['desc']);

export const getLatestErrorByTag = (state, tag) =>
  _.first(getOrderedErrorsByTag(state, tag));

export const getLatestUnhandledErrorByTag = (state, tag) =>
  _.first(_.reject(getOrderedErrorsByTag(state, tag), Error.getIsDismissed));

export const findLatestUnhandledError = (state, { endpoint, method, tag }) => {
  const predicates = [
    endpoint ? (err) => isMatch(endpoint, err.endpoint) : _.constant(true),
    method ? (err) => err.method === method : _.constant(true),
    tag ? (err) => err.tag === tag : _.constant(true),
    (err) => !Error.getIsDismissed(err),
  ];
  return _.find(getOrderedErrors(state), (error) =>
    _.every(predicates, (predicate) => predicate(error))
  );
};

// Request Selectors
const getRequestsState = (state) => _.get(state, [NAME, 'requests']);

export const getRequestById = (state, id) =>
  _.get(state, [NAME, 'requests', id]);

export const getRequests = createSelector(getRequestsState, _.values);

export const getOrderedRequests = (state) =>
  _.orderBy(getRequests(state), ['lastUpdatedAt'], ['desc']);

export const getRequestsByTag = (state, tag) =>
  getRequests(state).filter((req) => req.tag === tag);

export const getOrderedRequestsByTag = (state, tag) =>
  _.orderBy(getRequestsByTag(state, tag), ['lastSentAt'], ['desc']);

/**
 * Keep in mind that any feature may send multiple types of requests with the
 * same tag. For example, most features currently use the NAME constant as a tag.
 * So, if you're trying to determine if a specific type of request (i.e. zones, venues)
 * is pending, tags may not be your best bet. Tags are most useful when the ID of an item
 * was used as the tag. Otherwise, consider querying by the action type instead.
 */
export const getLatestRequestByTag = (state, tag) =>
  _.first(getOrderedRequestsByTag(state, tag));

export const findLatestRequest = (state, { endpoint, method, tag }) => {
  const predicates = [
    endpoint ? (err) => isMatch(endpoint, err.endpoint) : _.constant(true),
    method ? (err) => method === err.method : _.constant(true),
    tag ? (err) => tag === err.tag : _.constant(true),
  ];
  return _.find(getOrderedRequests(state), (error) =>
    _.every(predicates, (predicate) => predicate(error))
  );
};

// Status Selectors

export const getLatestStatusByTag = (state, tag) => {
  const latestRequest = getLatestRequestByTag(state, tag);
  return Request.getStatus(latestRequest);
};

/**
 * Common Types of Queries:
 * 1. Query errors based on action type (i.e. UPDATE_VEHICLE_REQUEST_STARTED)
 * 2. Query loading status based on action type (i.e. ZONES_REQUEST_STARTED)
 * 3.
 */
