const { darkTheme } = require('@parkmobile/ui');

module.exports = {
  theme_primary_color_dark: darkTheme.colors.brandDark,
  theme_primary_color_light: darkTheme.colors.brandLight,
  theme_primary_color_main: darkTheme.colors.brand,
  theme_secondary_color_dark: darkTheme.colors.brandDark,
  theme_secondary_color_light: darkTheme.colors.brandLight,
  theme_secondary_color_main: darkTheme.colors.brand,
  theme_type: 'dark',
  theme_use_alternate_details_toolbar: true,
  theme_use_alternate_footer: true,
  theme_use_alternate_menu: true,
  theme_use_alternate_topbar: true,
};
