import _ from 'lodash';
import { DELETE, DROP, UPDATE } from './constants';

const customizer = (objValue, srcValue) => {
  // We don't want to overwrite a previously defined value with null;
  if (objValue && srcValue === null) return objValue;
  // Return undefined so that lodash can handle merging
  return undefined;
};

export const deepMergeStrategy = (oldItem, newItem) =>
  _.mergeWith({}, oldItem, newItem, customizer);

export const overwriteStrategy = (oldItem, newItem) => newItem;

export function getDeleteActionInfo(action = {}) {
  return _.get(action, ['meta', DELETE]);
}

export function getDropActionInfo(action = {}) {
  return _.get(action, ['meta', DROP]);
}

export function getUpdateActionInfo(action = {}) {
  return _.get(action, ['meta', UPDATE]);
}

/**
 * Takes an data object where each key is the unique ID of an item and
 * each value is the item itself. Returns a new object where each key
 * is the value of a field and each value is the unique ID of the item.
 */
export const indexIdsByField = (fieldAccessor) => (byId) =>
  Object.keys(byId).reduce(
    (index, id) => ({
      ...index,
      [fieldAccessor(byId[id])]: id,
    }),
    {}
  );

/**
 * Determines if an action is decorated to delete items from a resource
 * @param  {Object}  [action={}] The action to check
 * @return {Boolean}             Returns true/false if the action is a delete action
 */
export function isDeleteAction(action = {}) {
  return Boolean(getDeleteActionInfo(action));
}

/**
 * Determines if an action is decorated to drop a resource
 * @param  {Object}  [action={}] The action to check
 * @return {Boolean}             Returns true/false if the action is a replace action
 */
export function isDropAction(action = {}) {
  return Boolean(getDropActionInfo(action));
}

/**
 * Determines if an action is decorated to update a resource
 * @param  {Object}  [action={}] The action to check
 * @return {Boolean}             Returns true/false if the action is an update action
 */
export function isUpdateAction(action = {}) {
  return Boolean(getUpdateActionInfo(action));
}
