import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { withTheme } from 'styled-components';
import { hideIf, showIf } from 'react-render-helpers';
import { Icon } from '../icon/icon';
import {
  ModalStyles,
  StyledRoot,
  StyledTopbar,
  StyledHeaderTitle,
  StyledCloseButton,
  StyledContent,
  StyledFooter,
} from './modal-styles';

// select app root to automatically toggle aria-hidden
// wrapping with try/catch to avoid failure of tests
const setAppElement = () => {
  try {
    ReactModal.setAppElement('#__next');
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

setAppElement();

export const ModalComponent = ({
  className,
  content,
  contentLabel,
  customHeader,
  footer,
  hideCloseButton,
  isFullScreen,
  isWider,
  showContentPadding,
  showOverlayPaddingInFullScreen,
  subtitle,
  onClose,
  theme,
  title,
  width,
  ...props
}) => (
  <React.Fragment>
    <ModalStyles
      isFullScreen={isFullScreen}
      isWider={isWider}
      showOverlayPaddingInFullScreen={showOverlayPaddingInFullScreen}
    />
    <ReactModal
      className='modal__overlay__content'
      contentLabel={contentLabel}
      isFullScreen={isFullScreen}
      isWider={isWider}
      width='600px'
      onRequestClose={onClose}
      overlayClassName='modal__overlay'
      {...props}
    >
      <StyledRoot isFullScreen={isFullScreen} width={width} isWider={isWider}>
        <StyledTopbar>
          {hideIf(customHeader)(
            <div>
              {showIf(title)(
                <StyledHeaderTitle as='h1' autoFocus={true}>
                  {title}
                </StyledHeaderTitle>
              )}
              {showIf(subtitle)(<div>{subtitle}</div>)}
            </div>
          )}
          {showIf(customHeader)(<div>{customHeader}</div>)}
          {hideIf(hideCloseButton)(
            <StyledCloseButton
              aria-label='close modal'
              as='button'
              data-pmtest-id='close-modal'
              onClick={onClose}
            >
              <Icon iconName='close' size='medium' />
            </StyledCloseButton>
          )}
        </StyledTopbar>
        <StyledContent showContentPadding={showContentPadding}>
          {content}
        </StyledContent>
        {showIf(footer)(<StyledFooter>{footer}</StyledFooter>)}
      </StyledRoot>
    </ReactModal>
  </React.Fragment>
);

ModalComponent.propTypes = {
  className: PropTypes.string,
  content: PropTypes.node,
  contentLabel: PropTypes.string,
  customHeader: PropTypes.node,
  footer: PropTypes.node,
  hideCloseButton: PropTypes.bool,
  isFullScreen: PropTypes.bool,
  isWider: PropTypes.bool,
  onClose: PropTypes.func,
  showContentPadding: PropTypes.bool,
  showOverlayPaddingInFullScreen: PropTypes.bool,
  subtitle: PropTypes.string,
  theme: PropTypes.any,
  title: PropTypes.node,
};

ModalComponent.defaultProps = {
  contentLabel: 'Modal',
  hideCloseButton: false,
  showContentPadding: true,
  showOverlayPaddingInFullScreen: true,
  width: '360px',
};

ModalComponent.propTypes = {
  className: PropTypes.string,
  content: PropTypes.node,
  contentLabel: PropTypes.string,
  customHeader: PropTypes.node,
  footer: PropTypes.node,
  hideCloseButton: PropTypes.bool,
  isFullScreen: PropTypes.bool,
  isOpen: PropTypes.any,
  isWider: PropTypes.bool,
  onAfterOpen: PropTypes.func,
  onClose: PropTypes.func,
  shouldCloseOnOverlayClick: PropTypes.bool,
  showContentPadding: PropTypes.bool,
  showOverlayPaddingInFullScreen: PropTypes.bool,
  subtitle: PropTypes.string,
  theme: PropTypes.any,
  title: PropTypes.node,
  width: PropTypes.string,
};

export const Modal = withTheme(ModalComponent);
