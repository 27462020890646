import _ from 'lodash';
import * as actions from './actions';

/**
 * NextJS Router notes:
 * ROUTE STEPS:
 * - Client side route transitions occur in the following order:
 * - Emit routeChangeStart
 * - Fetch component (could be in route cache)
 * - Call getInitialProps to get route info (as long as we aren't shallow routing)
 * - Emit beforeHistoryChange
 * - Update state (window, router)
 * - Emit routeChangeComplete or routeChangeError if errors occured
 * Timing is not an issue if you subscribe to Router events and emit actions, however we are
 * optimistically updating the reducer state in routeChangeStart while the router state is not
 * updated until beforeHistoryChange occurs
 */

const initialState = {
  currentUrl: null,
  error: null,
  pendingUrl: null,
  previousUrl: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    // payload: { asPath, pathname, query }
    case actions.ROUTE_INITIALIZED_ON_SERVER:
      return {
        ...state,
        currentUrl: {
          asPath: _.get(action.payload, 'asPath'),
          pathname: _.get(action.payload, 'pathname'),
          query: _.get(action.payload, 'query'),
        },
        isServer: true,
        previousUrl: state.currentUrl,
      };

    case actions.ROUTE_CHANGE_START:
      return {
        ...state,
        pendingUrl: action.payload,
      };

    case actions.ROUTE_CHANGE_ERROR:
      return {
        ...state,
        error: action.payload.error,
        pendingUrl: null,
      };

    // Update the current, pending, and previous URLs when the transition is complete
    case actions.BEFORE_HISTORY_CHANGE:
      return {
        ...state,
        currentUrl: action.payload,
        pendingUrl: null,
        previousUrl: state.currentUrl,
      };

    // Update the current, pending, and previous URLs when the transition is complete
    case actions.ROUTE_CHANGE_COMPLETE:
      return {
        ...state,
        currentUrl: action.payload,
        pendingUrl: null,
      };

    default:
      return state;
  }
};
