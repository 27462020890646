const _ = require('lodash');
const fp = require('lodash/fp');

/**
 * @param {Array} inputArray The array to be sorted and filtered.
 * @param {Array} ortedArray The ordered array to sort and filter by.
 * @param {Object} [opts = {}] Options to change equalityFn.
 * @return {Array} Array with original contents from array property,
 * sorted and filtered by sortedArray.
 */
module.exports = function filterAndSortByArray(
  inputArray,
  sortedArray,
  opts = {}
) {
  const { equalityFn = (a, b) => a === b } = opts;

  if (!inputArray) return [];
  if (!sortedArray) return [];

  const getItemOrder = (item) =>
    _.findIndex(sortedArray, (x) => equalityFn(item, x));

  return fp.compose(
    // eslint-disable-next-line no-unused-vars
    fp.map(([index, item]) => item),
    fp.orderBy([([index]) => index], ['asc']),
    fp.filter(([index]) => index > -1),
    fp.map((item) => [getItemOrder(item), item])
  )(inputArray);
};
