import findKey from 'lodash/findKey';
import has from 'lodash/has';
import isFunction from 'lodash/isFunction';
import values from 'lodash/values';
import oneConfig from '@parkmobile/one-config';

const createSagaRegistry = () => {
  /* eslint-disable fp/no-let */
  let changeListener = null;
  let sagas = {};
  /* eslint-enable */

  const getName = (saga) => saga.displayName || findKey(sagas, saga) || 'saga';
  const getSagas = () => values({ ...sagas });

  const register = (name, saga) => {
    if (has(sagas, name)) return;

    // eslint-disable-next-line no-param-reassign
    saga.displayName = name;
    sagas = {
      ...sagas,
      [name]: saga,
    };

    if (oneConfig.get('ENABLE_LOGGING')) {
      // eslint-disable-next-line no-console
      console.log(`Registered saga: "${name}"`);
    }

    // Add this check last so that sagas get registered even when there's no change listener
    if (isFunction(changeListener)) {
      changeListener(saga);
    }
  };

  const setChangeListener = (fn) => {
    if (isFunction(fn)) {
      changeListener = fn;
    }

    return () => {
      changeListener = null;
    };
  };

  return {
    getName,
    getSagas,
    register,
    setChangeListener,
  };
};

export const sagaRegistry = createSagaRegistry();
