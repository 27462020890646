import styled from 'styled-components';

export const StyledRoot = styled.div(({ theme, modifiers }) => ({
  ...theme.applyTypographyStyle(modifiers.variant),
  ...(modifiers.inherit && {
    color: 'inherit',
  }),
  textAlign: modifiers.align,
  textTransform: modifiers.transform,
}));
