const { useState } = require('react');
const useIsomorphicLayoutEffect = require('./use-isomorphic-layout-effect');

/**
 * useMediaQuery
 * @param {string} mediaQuery - media query to match
 * @param {any} value - value to return if viewport matches media query
 * @param {any} fallback - fallback to return if viewport does not match media query
 * @returns {any} `value` or `fallback`
 */
module.exports = function useMediaQuery(mediaQuery, value, fallback) {
  const initialState =
    typeof window !== 'undefined'
      ? window.matchMedia(mediaQuery).matches
      : false;
  const [isMatch, setIsMatch] = useState(initialState);

  useIsomorphicLayoutEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery);
    const listener = (e) => {
      const matches = e.matches;
      setIsMatch(matches);
    };

    // use deprecated listener method to support older iOS versions
    mediaQueryList.addEventListener('change', listener);

    return () => {
      // use deprecated listener method to support older iOS versions
      mediaQueryList.removeEventListener('change', listener);
    };
  }, [mediaQuery]);

  return isMatch ? value : fallback;
};
