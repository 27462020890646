import _ from 'lodash';
import styled from 'styled-components';
import Color from 'color';

const getVariant = ({ theme, ...props }) => {
  const variants = {
    black: {
      backgroundColor: theme.getPaletteColor('neutral', '800'),
      backgroundColorActive: theme.getPaletteColor('neutral', '900'),
      outlineColor: theme.getPaletteColor('neutral', '800'),
      textColor: theme.getPaletteColor('common', 'white'),
    },
    danger: {
      backgroundColor: theme.getPaletteColor('error'),
      backgroundColorActive: theme.getPaletteColor('error', 'dark'),
      outlineColor: theme.getPaletteColor('error'),
      textColor: theme.getContrastTextColor('error'),
    },
    default: {
      backgroundColor: theme.isLight()
        ? 'rgba(0, 0, 0, 0.75)'
        : theme.getPaletteColor('common', 'white'),
      backgroundColorActive: theme.isLight()
        ? theme.getPaletteColor('neutral', '900')
        : theme.getPaletteColor('neutral', '50'),
      outlineColor: theme.isLight()
        ? 'rgba(0, 0, 0, 0.75)'
        : theme.getPaletteColor('common', 'white'),
      textColor: theme.isLight()
        ? theme.getPaletteColor('common', 'white')
        : theme.getPaletteColor('neutral', '800'),
    },
    primary: {
      backgroundColor: theme.getPaletteColor('primary'),
      backgroundColorActive: theme.getPaletteColor('primary', 'dark'),
      outlineColor: theme.getPaletteColor('primary'),
      textColor: theme.getContrastTextColor('primary'),
    },
    secondary: {
      backgroundColor: theme.getPaletteColor('secondary'),
      backgroundColorActive: theme.getPaletteColor('secondary', 'dark'),
      outlineColor: theme.getPaletteColor('secondary'),
      textColor: theme.getContrastTextColor('secondary'),
    },
    white: {
      backgroundColor: theme.getPaletteColor('neutral', '50'),
      backgroundColorActive: theme.getPaletteColor('neutral', '200'),
      outlineColor: theme.getPaletteColor('neutral', '50'),
      textColor: 'rgba(0, 0, 0, 0.75)',
    },
  };
  const variant = _.get(variants, props.variant) || _.get(variants, 'default');
  /* eslint-disable sort-keys */
  const raisedStyles = {
    backgroundColor: variant.backgroundColor,
    color: variant.textColor,
    '*': {
      color: variant.textColor,
    },
    '&:hover, &:focus, &:active': {
      backgroundColor: variant.backgroundColorActive,
    },
    '&[disabled]': {
      backgroundColor: theme.getActionStyle('disabledBackground'),
      color: theme.getActionStyle('disabled'),
      cursor: 'not-allowed',
      outline: 0,
      '*': {
        color: theme.getActionStyle('disabled'),
      },
      '&:hover, &:focus': {
        outline: 0,
      },
    },
  };
  const flatStyles = {
    backgroundColor: 'transparent',
    color: variant.outlineColor,
    '*': {
      color: variant.outlineColor,
    },
    '&:focus, &:hover, &: active': {
      backgroundColor: Color(variant.backgroundColor)
        .alpha(theme.getActionStyle('hoverOpacity'))
        .toString(),
    },
    '&[disabled]': {
      backgroundColor: 'transparent',
      color: theme.getActionStyle('disabled'),
      cursor: 'not-allowed',
      '*': {
        color: theme.getActionStyle('disabled'),
      },
    },
  };
  const outlineStyles = {
    backgroundColor: 'transparent',
    border: `1px solid ${variant.outlineColor}`,
    color: variant.outlineColor,
    '*': {
      color: variant.outlineColor,
    },
    '&:hover, &:active, &:focus': {
      backgroundColor: variant.outlineColor,
      color: variant.textColor,
      '*': {
        color: variant.textColor,
      },
    },
    '&[disabled]': {
      backgroundColor: 'transparent',
      border: `1px solid ${theme.getActionStyle('disabled')}`,
      color: theme.getActionStyle('disabled'),
      cursor: 'not-allowed',
      '*': {
        color: theme.getActionStyle('disabled'),
      },
    },
  };
  /* eslint-enable */
  if (props.type === 'raised') return raisedStyles;
  if (props.type === 'flat') return flatStyles;
  if (props.type === 'outline') return outlineStyles;
  return raisedStyles;
};

export const StyledRoot = styled.button(({ theme, ...props }) => ({
  '@media print': {
    visibility: 'hidden',
  },
  ...theme.applyAnimation('easeTransition'),
  ...theme.applyTypographyStyle('button'),
  alignItems: 'center !important',
  borderRadius: theme.getShape('borderRadius'),
  cursor: 'pointer',
  display: 'flex',
  height: theme.getShape('buttonHeight'),
  justifyContent: 'center',
  textAlign: 'center',
  ...getVariant({ theme, ...props }),
  ...(props.icon && {
    display: 'flex',
  }),
  ...(props.showMargin &&
    props.type !== 'flat' && {
      margin: theme.getSpacing(3, 0),
    }),
  ...(props.size === 'auto' && {
    padding: theme.getSpacing(0, 2),
  }),
  ...(props.size === 'small' && {
    maxWidth: '150px',
    width: '100%',
  }),
  ...(props.size === 'medium' && {
    maxWidth: '250px',
    width: '100%',
  }),
  ...(props.size === 'large' && {
    maxWidth: '300px',
    width: '100%',
  }),
}));

export const StyledContent = styled.div(() => ({
  textAlign: 'center',
}));

export const StyledIcon = styled.div(({ theme, ...props }) => ({
  height: '24px !important',
  width: '24px !important',
  ...(props.isLeft && {
    marginLeft: theme.getSpacing(-0.5),
    marginRight: theme.getSpacing(1),
  }),
  ...(props.isRight && {
    marginLeft: theme.getSpacing(1),
    marginRight: theme.getSpacing(-0.5),
  }),
}));
