import React from 'react';
import { Button, Image, Typography } from '@/lib/shared/components';
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as constants from '../../constants';
import * as Styles from './internal-error-styles';

InternalError.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onRetry: PropTypes.func,
  retryButtonText: PropTypes.string,
  title: PropTypes.string,
};

export function InternalError({
  className,
  message,
  onRetry,
  title,
  retryButtonText,
}) {
  const handleRetry = () => {
    if (_.isFunction(onRetry)) {
      onRetry();
    } else {
      window.location.reload();
    }
  };

  return (
    <Styles.Root className={className}>
      <Styles.Image
        as={Image}
        className='internal-error__image'
        imgSrc={constants.REQUEST_FAILED_IMG_SRC}
      />
      <Styles.Title as={Typography} component='h1' variant='h1'>
        {title || constants.REQUEST_FAILED_HEADER}
      </Styles.Title>
      <Styles.Message as={Typography}>
        {message || constants.REQUEST_FAILED_MESSAGE}
      </Styles.Message>
      <Styles.Button as={Button} onClick={handleRetry} size='auto'>
        {onRetry ? retryButtonText || 'Try Again' : 'Refresh'}
      </Styles.Button>
    </Styles.Root>
  );
}
