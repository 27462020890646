import { reducerRegistry } from './reducer-registry';
import { sagaRegistry } from './saga-registry';

export { configureStore } from './configure-store';

export const registerReducer = (name, reducer) => {
  reducerRegistry.register(name, reducer);
  return reducer;
};

export const registerSaga = (name, saga) => {
  sagaRegistry.register(name, saga);
  return saga;
};
