export const NAME = 'app';
export const LOGOUT_EVENT_NAME = 'LOGGED_OUT';

export const SUPPORTED_CARDS = [
  'americanexpress',
  'discover',
  'mastercard',
  'visa',
];
export const SUPPORTED_DIGITAL_PAYMENTS = ['applePay'];
