import { datadogRum } from '@datadog/browser-rum';
import { initialize as ldClientInitialize } from 'launchdarkly-js-client-sdk';
import oneConfig from '@parkmobile/one-config';
import { eventChannel } from 'redux-saga';
import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import { reduceCurrentValues } from '../helpers';
import { FeatureFlags } from '../models';
import * as actions from './actions';
import * as selectors from './selectors';

const LD_CLIENT_SIDE_ID = oneConfig.get('LD_CLIENT_SIDE_ID');

function createLaunchDarklyChannel({ client }) {
  // create channel and subscribe to changes

  return eventChannel((emit) => {
    client.on('ready', () => {
      emit(client.allFlags());
    });

    client.on('change', (changes) => {
      emit(reduceCurrentValues(changes));
    });

    return () => {
      client.close();
    };
  });
}

function* setDatadogDefaultContextAndUser() {
  const user = yield select(selectors.getUser);
  const userHash = yield select(selectors.getUserHash);
  const bootstrap = yield select(selectors.getBootstrap);
  const flags = yield select(selectors.getAllFlags);
  const datadogRumFlag = FeatureFlags.datadogRum(flags);
  const isDatadogRumEnabled = datadogRumFlag?.enabled;

  if (isDatadogRumEnabled) {
    datadogRum.setGlobalContext({ bootstrap, flags, user, userHash });
    datadogRum.setUser({ id: user.key });
  }
}

function* subscribeToFlagChanges({ client }) {
  const ldChannel = createLaunchDarklyChannel({ client });
  // until the end of time whenever the channel 'emits' dispatch an action with the channel updates

  /* eslint-disable fp/no-loops */
  while (typeof window !== 'undefined') {
    const updates = yield take(ldChannel);
    yield put(actions.flagChangesReceived(updates));
  }
  /* eslint-enable */
}

function* relayCustomEvents({ client }) {
  const sendCustomEvent = (action) => {
    const { data, eventName, metricValue } = action.payload;
    client.track(eventName, data, metricValue);
  };

  yield takeEvery(actions.CUSTOM_EVENT_LOGGED, sendCustomEvent);
}

function* initializeLaunchDarkly() {
  const user = yield select(selectors.getUser);
  const userHash = yield select(selectors.getUserHash);
  const bootstrap = yield select(selectors.getBootstrap);
  const client = ldClientInitialize(LD_CLIENT_SIDE_ID, user, {
    bootstrap,
    hash: userHash,
  });

  yield fork(subscribeToFlagChanges, { client });
  yield fork(relayCustomEvents, { client });
}

function* updateDatadogDefaultContext() {
  const newFlags = yield select(selectors.getAllFlags);
  const datadogRumFlag = FeatureFlags.datadogRum(newFlags);
  const isDatadogRumEnabled = datadogRumFlag?.enabled;

  if (isDatadogRumEnabled) {
    datadogRum.setGlobalContextProperty('flags', newFlags);
  }
}

/**
 * @deprecated redux-saga's are deprecated.
 * Only register.js should import the saga file as we continue to migrate away
 */
export const saga = function* featureFlagsSaga() {
  const isBrowser = typeof window !== 'undefined';

  if (isBrowser) {
    yield call(setDatadogDefaultContextAndUser);
    yield fork(initializeLaunchDarkly);
  }

  yield all([
    takeEvery(actions.FLAG_CHANGES_RECEIVED, updateDatadogDefaultContext),
  ]);
};
