import _ from 'lodash';
import { all, call, takeLatest } from 'redux-saga/effects';
import { format } from 'url';
import * as actions from './actions';
import * as helpers from '../helpers';

function* navigate(action) {
  const [route, params, options] = action.payload;
  const args = [_.isString(route) ? route : format(route), params, options];
  yield call(helpers.callRouterMethod, action.meta.routerMethod, ...args);
}

function* redirect(action) {
  const { res, url } = action.payload;
  yield call(helpers.redirect, [url, res]);
}

/**
 * @deprecated redux-saga's are deprecated.
 * Only register.js should import the saga file as we continue to migrate away
 */
export const saga = function* routingSaga() {
  yield all([
    takeLatest([actions.PUSH, actions.REPLACE], navigate),
    takeLatest(actions.REDIRECT, redirect),
  ]);
};
