/* eslint-disable no-console */
/* global google */
import {
  castBooleanValues,
  composeAsyncFns,
  containsSubstring,
  loadGoogleAPI,
  resolveAllPromises,
  resolvePromise,
} from '@/lib/utils';
import axios from 'axios';
import stringify from 'fast-json-stable-stringify';
import geolib from 'geolib';
import _ from 'lodash';
import fp from 'lodash/fp';
import moment from 'moment';
import oneConfig from '@parkmobile/one-config';
import * as Prismic from '@prismicio/client';
import * as constants from './constants';
import { AppConfig } from './models';

const config = oneConfig.config();
const PRISMIC_API_CDN_ENDPOINT = oneConfig.get('PRISMIC_API_CDN_ENDPOINT');
const PRISMIC_ACCESS_TOKEN = oneConfig.get('PRISMIC_ACCESS_TOKEN');
const { DATE_FORMATS, ZOOM_TYPES, TIMEZONES } = constants;

// --------- Public Helpers ---------------------

export function getCurrentPosition(opts) {
  return new Promise((resolve) => {
    if (!navigator || !navigator.geolocation) resolve(null);

    const onSuccess = ({ coords: { latitude, longitude } }) => {
      resolve({ latitude, longitude });
    };
    const onError = (error) => {
      // eslint-disable-next-line no-console
      console.warn(error);
      resolve(null);
    };
    const defaultOpts = { maximumAge: 60000, timeout: 10000 };
    navigator.geolocation.getCurrentPosition(
      onSuccess,
      onError,
      opts || defaultOpts
    );
  });
}

export function formatGeocodeResult(result) {
  const types = _.get(result, 'types', []);
  const location = _.get(result, 'geometry.location');
  const formattedAddress = _.get(result, 'formatted_address');
  const zoomType = types.find((type) => _.has(ZOOM_TYPES, type));
  const zoom = ZOOM_TYPES[zoomType];
  const addressComponents = _.get(result, 'address_components');
  // These generic fields are re-formatted for the United States equivalents.
  // See https://developers.google.com/maps/documentation/geocoding/intro for full documentation.
  const formatAddressComponents = fp.reduce((acc, current) => {
    if (_.includes(current.types, 'locality'))
      return { ...acc, city: current.long_name };
    if (_.includes(current.types, 'country'))
      return { ...acc, country: current.long_name };
    if (_.includes(current.types, 'postal_code'))
      return { ...acc, zipCode: current.long_name };
    if (_.includes(current.types, 'administrative_area_level_1'))
      return { ...acc, state: current.long_name };
    return acc;
  }, {});

  return {
    ...formatAddressComponents(addressComponents),
    formattedAddress,
    latitude: location.lat,
    longitude: location.lng,
    types,
    zoom,
  };
}

export function geocodeAddress(address) {
  const encodedAddress = encodeURIComponent(address);
  const query = `address=${encodedAddress}&key=${config.GOOGLE_API_KEY}`;
  const url = `https://maps.googleapis.com/maps/api/geocode/json?${query}`;
  return axios
    .get(url)
    .then(({ data: { results } }) => _.first(results))
    .then((result) => (result ? formatGeocodeResult(result) : null));
}

export async function geocodePosition(position) {
  await loadGoogleAPI();
  return new Promise((resolve) => {
    if (!window.google) resolve({});

    const geocoder = new google.maps.Geocoder();
    const latlng = {
      lat: _.get(position, 'latitude'),
      lng: _.get(position, 'longitude'),
    };

    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status !== 'OK') return resolve({});
      const { city, country, state, zipCode } = formatGeocodeResult(
        _.first(results)
      );
      return resolve({ city, country, state, zipCode });
    });
  });
}

/**
 * Convert to number if possible and return the result, otherwise return null.
 */
export function maybeToNumber(input) {
  if (_.isNil(input)) {
    return null;
  }
  const result = _.toNumber(input);
  return !_.isNaN(result) ? result : null;
}

export async function safelyAwait(fn, ...args) {
  try {
    const data = await fn(...args);
    return { data, ok: true };
  } catch (error) {
    return { error, ok: false };
  }
}

export function isLowerEnv() {
  return containsSubstring(
    window.location.hostname,
    _.values(constants.LOWER_ENVS)
  );
}

// Date Helpers
export function isValid(date) {
  return moment(date, _.values(DATE_FORMATS)).isValid();
}

/**
 * Returns the nearest, upcoming quarter hour.
 * @param  {Object} date A moment object
 * @param  {Object} now  A moment object
 * @return {String}      Returns a ISO8601 string
 */
export function toQuarterHour(date, now) {
  if (!now) {
    throw new Error('toQuarterHour expected current date as second parameter');
  }

  const getQuarterHourTime = (roundUp) => (momentObj) => {
    const startOfHour = momentObj.clone().startOf('hour');
    const minutes = momentObj.minutes();
    const newMinutes = roundUp
      ? Math.ceil(minutes / 15) * 15
      : Math.round(minutes / 15) * 15;
    return startOfHour.clone().add(newMinutes, 'minutes');
  };

  const getNearestQuarterHourTime = getQuarterHourTime(false);
  const getRoundedUpQuarterHourTime = getQuarterHourTime(true);

  const nearestQuarterHourTime = getNearestQuarterHourTime(date);

  const upcomingQuarterHourTime = nearestQuarterHourTime.isBefore(now)
    ? getRoundedUpQuarterHourTime(now)
    : nearestQuarterHourTime;

  return toISO8601(upcomingQuarterHourTime, { removeOffset: true });
}

const validateDate = _.memoize((dateString) => {
  const parseFormats = _.values(DATE_FORMATS);
  return moment(dateString, parseFormats).isValid();
});

export function isValidDate(dateString) {
  if (validateDate.cache.size > 100) {
    validateDate.cache.clear();
  }

  return validateDate(dateString);
}

const parseDate = _.memoize(
  (date, options) => {
    const {
      returnFormat = 'string',
      removeOffset = false,
      timeZone = '',
    } = options;
    const returnFormats = ['moment', 'string'];

    // Validate arguments
    if (!_.includes(returnFormats, returnFormat)) {
      throw new Error(
        `toISO8601 expected returnFormat to be one of ${returnFormats.join(
          ','
        )}.`
      );
    }

    const parseFormats = _.values(DATE_FORMATS);
    const timeZoneData = timeZone ? getTimeZoneData(timeZone) : null;
    const toMoment = () => {
      // Parse to moment in UTC.
      if (timeZoneData) return moment.utc(date, parseFormats);
      // Parse to moment while retaining offset. Defaults to user's local time if date is undefined.
      return moment.parseZone(date, parseFormats);
    };
    const maybeFormat = (momentObj) => {
      if (removeOffset) return momentObj.format(DATE_FORMATS.ISO8601_NO_OFFSET);
      if (returnFormat === 'string')
        return momentObj.format(DATE_FORMATS.ISO8601);
      return momentObj;
    };

    const momentObj = toMoment();

    if (!momentObj.isValid()) return null;

    // Handle the case when we convert to a provided timezone
    if (timeZoneData) {
      const dateIsInDST = getIsInUSADST(momentObj);
      const timeZoneDataIsInDST = _.get(timeZoneData, 'isdst');
      const timeZoneDataOffset = _.get(timeZoneData, 'offset');
      const timeZonedoesNotUseDST = _.get(timeZoneData, 'doesNotUseDST');

      /**
       * Generate offset based on whether passed-in date is in DST and whether
       * timeZoneData is using DST for its offset. If time zone from timeZoneData
       * does not observe DST, use its offset without any modifications.
       */
      momentObj.utcOffset(
        (timeZonedoesNotUseDST && timeZoneDataOffset) ||
          (!dateIsInDST && timeZoneDataIsInDST && timeZoneDataOffset - 1) ||
          (dateIsInDST && !timeZoneDataIsInDST && timeZoneDataOffset + 1) ||
          timeZoneDataOffset
      );
    }

    return maybeFormat(momentObj);
  },
  (date, options = {}) => {
    const dateKey = (date || '').toString();
    return stringify({
      date: dateKey,
      ...options,
    });
  }
);

/**
 * Parse a date and return it in ISO8601 format with or without offset information
 * @param  {String, Date} args  A String or Date object
 * @param  {Object} [opts={}]   Options for parsing and formatting
 * @return {String, Object}     A formatted date string or null object
 */
export function toISO8601(date = moment(), options = {}) {
  if (parseDate.cache.size > 100) {
    parseDate.cache.clear();
  }

  return parseDate(date, options);
}

export const areCoordsEqual = (x, y) => {
  if (!x || !y) return false;
  const parse = (val) => Number.parseFloat(`${val}`).toPrecision(9);
  const xLat = parse(x.latitude);
  const xLng = parse(x.longitude);
  const yLat = parse(y.latitude);
  const yLng = parse(y.longitude);
  return xLat === yLat && xLng === yLng;
};

export const computeDistance = _.memoize(
  (point1 = {}, point2 = {}) => {
    const toMiles = (meters) => meters * 0.000621371;
    try {
      return toMiles(geolib.getDistance(point1, point2));
    } catch (e) {
      console.log(e);
      return null;
    }
  },
  (point1 = {}, point2 = {}) =>
    stringify({
      point1,
      point2,
    })
);

export const getDistance = (point1, point2) => {
  if (!point1)
    throw Error(
      'Expected first argument to be an object with a latitude and longitude property'
    );
  if (!point2)
    throw Error(
      'Expected second argument to be an object with a latitude and longitude property'
    );

  if (
    (!point1.latitude && point1.latitude !== 0) ||
    (!point1.longitude && point1.longitude !== 0) ||
    (!point2.latitude && point2.latitude !== 0) ||
    (!point2.longitude && point2.longitude !== 0)
  ) {
    console.warn('Expected latitude and longitude properties to be numbers');
    console.log({ point1, point2 });
    return 0;
  }

  if (computeDistance.cache.size > 100) {
    computeDistance.cache.clear();
  }

  return computeDistance(point1, point2);
};

export const getInState = (name) => (path) => (state) => {
  if (_.isArray(path)) return _.get(state, [name, ...path]);
  return _.get(state, `${name}.${path}`);
};

export function getTimeZoneAbbr(name, timezones) {
  return timezones[name] ? timezones[name].abbr : '';
}

export const getWindowHeight = () => {
  try {
    return window.innerHeight;
  } catch (e) {
    return 0;
  }
};

export const getWindowWidth = () => {
  try {
    return window.innerWidth;
  } catch (e) {
    return 0;
  }
};

export const logger = {
  error: getLogFn(console.error),
  info: getLogFn(console.info),
  warning: getLogFn(console.warn),
};

/* Safari only shows the print dialog box via window.print after all network requests have finished.
 * The print command via document.execCommand bypasses this restriction for Safari
 * but does not work for Firefox and instead returns false.
 */
export function print() {
  const printCommandExists = document.execCommand('print', false, null);
  if (!printCommandExists) window.print();
}

/*  remapObject modifies the keys and values of an object
    based on a map:

    const tourDataMap = {
      text: value => ({ content: value }),
      tourStepID: value => ({ selector: `#${value}` }),
    };
*/

export const remapObject = (obj, map = {}) => {
  if (typeof obj !== 'object' || typeof map !== 'object') {
    throw new Error(
      'Parameters for remapObject were not of type object as expected'
    );
  }

  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    const remappedKeyPair = _.invoke(map, key, value) || { [key]: value };
    return {
      ...acc,
      ...remappedKeyPair,
    };
  }, {});
};

export const remapObjects = (objects, map) =>
  objects.reduce((acc, obj) => [...acc, remapObject(obj, map)], []);

export const sortByDistanceFrom = (items, getPosition1, position2) =>
  _.orderBy(
    items,
    [(item) => getDistance(getPosition1(item), position2)],
    ['asc']
  );

export const wrapValue = (value) => ({
  get: _.constant(value),
  getOr: (or) => value || or,
  map: (fn) => wrapValue(fn(value)),
});

function getLogFn(method) {
  return config.ENABLE_LOGGING
    ? // eslint-disable-next-line no-console
      (...message) => method(...message)
    : _.noop;
}

export function getTimeZoneData(timeZone) {
  return timeZone
    ? _.find(TIMEZONES, (zone) => _.includes(_.get(zone, 'text'), timeZone)) ||
        null
    : null;
}

export function getIsInUSADST(date = moment()) {
  const dateYear = date.year();
  const dstStart = moment
    .utc('Mar 02:00:00', 'MMM hh:mm:ss')
    .year(dateYear)
    .isoWeekday(14);
  const dstEnd = moment
    .utc('Nov 02:00:00', 'MMM hh:mm:ss')
    .year(dateYear)
    .isoWeekday(7);

  /**
   * Returns whether date is between dstStart and dstEnd,
   * inclusive to dstStart. Does not take into account for
   * the hour after DST (from 1:00 AM to 1:59 AM).
   */
  return date.isBetween(dstStart, dstEnd, null, '[)');
}

export const convertHTMLtoText = (value) => {
  if (!value) return null;
  const tempDivElement = document.createElement('div');
  tempDivElement.innerHTML = value;
  return tempDivElement.textContent || tempDivElement.innerText || null;
};

export const isValidUrl = (string) => {
  try {
    const url = new URL(string);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (e) {
    console.error(`Invalid URL supplied: ${e}`);
    return false;
  }
};

// --------- Prismic Helpers ---------------------
export function fetchLinks({ api, doc }) {
  const getData = fp.get('data');
  const documentWithUnresolvedPromises = _.reduce(
    doc,
    (acc, value, key) => {
      if (_.get(value, 'link_type') === 'Document' && _.has(value, 'id')) {
        return {
          ...acc,
          [key]: composeAsyncFns([getData, api.getByID.bind(api)])(value.id),
        };
      }
      if (_.get(value, 'link_type') === 'Document') {
        return { ...acc, [key]: null };
      }
      return { ...acc, [key]: value };
    },
    {}
  );

  const foldPromises = (resolved) =>
    _.reduce(
      resolved,
      (acc, value, key) => ({
        ...acc,
        [key]: getData(value),
      }),
      {}
    );

  return composeAsyncFns([castBooleanValues, foldPromises, resolveAllPromises])(
    documentWithUnresolvedPromises
  );
}

export async function fetchPageById({ api, appConfig, id }) {
  const pages = AppConfig.getPages(appConfig);
  const isPageInConfig = Boolean(pages.find(({ page }) => page.id === id));

  if (!isPageInConfig) {
    return {
      error: `Couldn't find a page with ID "${id}"`,
      ok: false,
    };
  }

  // Fetch the page by ID
  const result = await resolvePromise(api.getByID(id));

  // If an error occurred return it
  if (!result.ok) return result;

  const documentWithId = {
    id: result.id,
    ..._.get(result, 'data.data'),
  };

  // Return the page
  return {
    data: await fetchLinks({ api, doc: documentWithId }),
    ok: true,
  };
}

export async function fetchPageBySlug({ api, appConfig, slug }) {
  const pagesInAppConfig = AppConfig.getPages(appConfig);
  const pageIds = pagesInAppConfig.map(({ page }) => page.id).filter(Boolean);
  // Fetch all the custom pages with a matching slug
  const pagesQuery = await resolvePromise(
    api.getByType('page', {
      filters: [
        api.filter.any('my.page.url_slug', [slug, `/${slug}`]),
        api.filter.in('document.id', pageIds),
      ],
    })
  );

  // If an error occurred return it
  if (!pagesQuery.ok) return pagesQuery;

  // Find the result with an ID matching one in AppConfig's custom pages list
  const { results } = pagesQuery.data;
  const [pageResult] = results;

  // Return an error if we couldn't find the page
  if (!pageResult) {
    return {
      error: `Couldn't find a page with URL slug "${slug}"`,
      ok: false,
    };
  }

  const documentWithId = {
    id: pageResult.id,
    ..._.get(pageResult, 'data'),
  };

  // Return the page
  return {
    data: await fetchLinks({ api, doc: documentWithId }),
    ok: true,
  };
}

export function getPrismicApi({ req }) {
  if (req && req.prismic && req.prismic.api) {
    return req.prismic.api;
  }

  try {
    const api = Prismic.createClient(PRISMIC_API_CDN_ENDPOINT, {
      accessToken: PRISMIC_ACCESS_TOKEN,
      req,
    });
    api.filter = Prismic.filter;
    return api;
  } catch (e) {
    return null;
  }
}

/* Parses a flat theme settings object into a nested theme settings object,
 * which is then passed to the `createTheme` helper in order to generate
 * a full theme.
 *
 * This function receives an object from either dedicated theme .js files or
 * the theme present on an AppConfig object. Both are structured in the same way.
 */
export const parseThemeSettings = ({
  /* eslint-disable camelcase */
  theme_primary_color_dark,
  theme_primary_color_light,
  theme_primary_color_main,
  theme_primary_color_on,
  theme_primary_color_onDark,
  theme_primary_color_onLight,
  theme_primary_color_onMain,
  theme_secondary_color_dark,
  theme_secondary_color_light,
  theme_secondary_color_main,
  theme_secondary_color_on,
  theme_secondary_color_onDark,
  theme_secondary_color_onLight,
  theme_secondary_color_onMain,
  theme_type,
  theme_use_alternate_details_toolbar,
  theme_use_alternate_footer,
  theme_use_alternate_menu,
  theme_use_alternate_topbar,
  /* eslint-enable camelcase */
}) =>
  castBooleanValues({
    primary: {
      dark: theme_primary_color_dark,
      light: theme_primary_color_light,
      main: theme_primary_color_main,
      on: theme_primary_color_on,
      onDark: theme_primary_color_onDark,
      onLight: theme_primary_color_onLight,
      onMain: theme_primary_color_onMain,
    },
    secondary: {
      dark: theme_secondary_color_dark,
      light: theme_secondary_color_light,
      main: theme_secondary_color_main,
      on: theme_secondary_color_on,
      onDark: theme_secondary_color_onDark,
      onLight: theme_secondary_color_onLight,
      onMain: theme_secondary_color_onMain,
    },
    type: theme_type,
    useAlternateDetailsToolbar: theme_use_alternate_details_toolbar,
    useAlternateFooter: theme_use_alternate_footer,
    useAlternateMenu: theme_use_alternate_menu,
    useAlternateTopbar: theme_use_alternate_topbar,
  });

export const formatCurrency = ({ cultureCode, currency, price }) =>
  new Intl.NumberFormat(cultureCode, { currency, style: 'currency' }).format(
    price
  );

export const getIsNavigatorAgentDeviceMobile = () =>
  /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
