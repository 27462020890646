import get from 'lodash/get';
import { NAME } from '../constants';

export const getNotifications = (state) =>
  get(state, [NAME, 'notificationCenter', 'notifications'], []);

export const getLastNotificationId = (state) =>
  get(state, [NAME, 'notificationCenter', 'lastId']);

export const getCommunicationState = (state) => get(state, [NAME]);

export const getUserSettings = (state) =>
  get(state, [NAME, 'userSettings'], {});

export const getShownTooltips = (state) =>
  get(state, [NAME, 'userSettings', 'tooltipsShown'], []);

export const getIsTooltipPreviouslyShown = (state, tooltipId) =>
  getShownTooltips(state).includes(tooltipId);

export const getShownTours = (state) =>
  get(state, [NAME, 'userSettings', 'toursShown'], []);

export const getIsTourPreviouslyShown = (state, tourID) =>
  getShownTours(state).includes(tourID);
