import { trackMixpanelEvent } from '@/lib/analytics/helpers';
import { censorString } from '@/lib/utils';

export const DEVICE_OPTIONS = {
  desktop: 'desktop browser',
  inApp: 'in-app browser',
  mobile: 'mobile browser',
};

export function trackVisitDeviceType({ deviceType, whiteLabel }) {
  trackMixpanelEvent('Initialize Visit to Web', {
    device_type: deviceType,
    white_label: whiteLabel,
  });
}

export function trackNavigateToLogin() {
  trackMixpanelEvent('Navigate To Log In');
}

export function trackNavigateToSettings() {
  trackMixpanelEvent('Navigate To Account Settings');
}

export function trackSSORefreshTokenSuccess({
  refreshTokenUsed,
  newRefreshTokenReceived,
}) {
  trackMixpanelEvent('SSO Refresh Token Success', {
    new_refresh_token_received: censorString(newRefreshTokenReceived, 60),
    refresh_token_used: censorString(refreshTokenUsed, 60),
  });
}

export function trackSSORefreshTokenFailed({ error, refreshTokenUsed }) {
  trackMixpanelEvent('SSO Refresh Token Failed', {
    localized_error_description: error,
    refresh_token_used: censorString(refreshTokenUsed, 60),
  });
}

export function trackNavigateToB2BContactForm() {
  trackMixpanelEvent('Navigate to Business Contact Form');
}

// EasyPark
export function trackEasyParkLogoutSuccess() {
  trackMixpanelEvent('Log Out Success');
}
