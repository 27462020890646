import _ from 'lodash';
import { sha256 } from 'sha.js';
import { schema } from 'normalizr';

export const User = {
  getAddress: () => '',
  getEmail: (user, defaultValue) =>
    _.get(user, 'userProfile.email', defaultValue),
  getFirstName: (user, defaultValue) =>
    _.get(user, 'userProfile.firstName', defaultValue),
  getHashedEmail: (user) => {
    const email = User.getEmail(user);
    if (!email) return null;
    return new sha256().update(email).digest('hex');
  },
  getId: (user) => _.get(user, 'userId'),
  getLastName: (user, defaultValue) =>
    _.get(user, 'userProfile.lastName', defaultValue),
  getMobile: (user, defaultValue) =>
    _.get(user, 'userProfile.mobile', defaultValue),
  getProfile: (user, defaultValue) => _.get(user, 'userProfile', defaultValue),
  getSecureEmail: (user, defaultValue) => {
    const email = _.get(user, 'userProfile.email', defaultValue);
    if (email) {
      const emailParts = email.split('@');
      if (emailParts[0].length > 3) {
        return email.replace(/^(.).*(.).*@/, '$1***$2@');
      }
      return email.replace(/^(.).*@/, '$1***@');
    }
    return email;
  },
};

User.schemaName = 'users';

User.schema = new schema.Entity(
  User.schemaName,
  {},
  {
    idAttribute: User.getId,
  }
);
