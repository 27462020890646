import { NotificationCenterContainer } from '@/lib/communication/components';
import { FeatureFlags } from '@/lib/feature-flags/models';
import { getAllFlags } from '@/lib/feature-flags/store';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { hideIf } from '@parkmobile/ui';
import oneConfig from '@parkmobile/one-config';
import { Footer } from '../footer/footer';
import { Footer as GlobalFooter } from '../easypark/footer/footer';
import { Topbar } from '../topbar/topbar';
import { Topbar as GlobalTopbar } from '../easypark/topbar/topbar';
import { useLayout } from './layout-hook';
import * as styles from './layout-styles';

Layout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  clipContent: PropTypes.bool,
  display: PropTypes.oneOf([
    'fluid', // Body scrolling layout with header and footer
    'static', // Flex positioned layout with header only
  ]),
};

Layout.defaultProps = {
  clipContent: true,
  display: 'fluid',
};

export function Layout({ className, children, clipContent, display }) {
  const contentId = 'layout-content';
  const [data, handlers] = useLayout({ contentId });
  const { hideTopBar, hideFooter } = data;
  const isGlobalFlow = oneConfig.get('IS_EASYPARK');

  const flags = useSelector(getAllFlags);
  // #on-demand-sticky-start-parking-button - remove following condition when we deprecate flag
  const isOnDemandStickyStartParkingButtonEnabled =
    FeatureFlags.isOnDemandStickyStartParkingButtonEnabled(flags);

  const webeu201UseHardCodedCookiePolicyUrlsFF =
    FeatureFlags.webeu201UseHardCodedCookiePolicyUrls(flags);

  return (
    <styles.Root
      className={className}
      modifiers={{ isFluid: display === 'fluid' }}
    >
      {hideIf(hideTopBar)(
        isGlobalFlow ? (
          <styles.Topbar
            as={GlobalTopbar}
            modifiers={{ isSticky: display === 'fluid' }}
            onSkipToMain={handlers.onSkipToMain}
          />
        ) : (
          <styles.Topbar
            as={Topbar}
            modifiers={{ isSticky: display === 'fluid' }}
            onSkipToMain={handlers.onSkipToMain}
          />
        )
      )}
      <styles.NotificationCenter
        as={NotificationCenterContainer}
        modifiers={{ isFluid: display === 'fluid' }}
      />
      <styles.Content
        as='main'
        id={contentId}
        modifiers={{
          clipContent:
            !isOnDemandStickyStartParkingButtonEnabled || clipContent,
          fillHeight: display === 'fluid',
        }}
        role='main'
      >
        {React.Children.toArray(children)}
        {hideIf(hideFooter)(
          isGlobalFlow && webeu201UseHardCodedCookiePolicyUrlsFF ? (
            <styles.Footer
              as={GlobalFooter}
              condensed={display === 'static'}
              modifiers={{ condensed: display === 'static' }}
            />
          ) : (
            <styles.Footer
              as={Footer}
              condensed={display === 'static'}
              modifiers={{ condensed: display === 'static' }}
            />
          )
        )}
      </styles.Content>
    </styles.Root>
  );
}
