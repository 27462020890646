const { eventChannel, END } = require('redux-saga');

module.exports = function countdownChannel(secs) {
  return eventChannel((emitter) => {
    const iv = setInterval(() => {
      // eslint-disable-next-line no-param-reassign
      secs -= 1;
      if (secs >= 0) {
        emitter(secs);
      } else {
        // this causes the channel to close
        emitter(END);
      }
    }, 1000);
    // The subscriber must return an unsubscribe function
    return () => {
      clearInterval(iv);
    };
  });
};
