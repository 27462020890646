import { ROUTE_CHANGE_COMPLETE } from '@/lib/routing/store';
import { APP_MOUNTED } from '@/lib/shared/store';
import { all, put, takeEvery } from 'redux-saga/effects';
import * as actions from './actions';

function* onPageLoad() {
  yield put(actions.pageLoaded());
}

export const saga = function* appEffects() {
  yield all([takeEvery([APP_MOUNTED, ROUTE_CHANGE_COMPLETE], onPageLoad)]);
};
