/* eslint-disable no-underscore-dangle */
const { orderByDistance } = require('geolib');
const _ = require('lodash');

const isLatLngValid = (point) => {
  const latitude = point?.latitude;
  const longitude = point?.longitude;

  const isLatitudeValid =
    latitude !== '' &&
    !_.isNil(latitude) &&
    _.isFinite(_.toNumber(latitude)) &&
    latitude !== 0;
  const isLongitudeValid =
    longitude !== '' &&
    !_.isNil(longitude) &&
    _.isFinite(_.toNumber(longitude)) &&
    longitude !== 0;

  return isLatitudeValid && isLongitudeValid;
};

/**
 * Sorts an array of points by their distance from a given origin.
 * Moves points with invalid coordinates to the end of the array in the original order.
 * Returns original points if origin passed in is invalid.
 * @param {Object} origin - The origin from which the distances will be calculated.
 * @param {Object[]} points - The array of points to be sorted.
 * @param {number|string} points[].latitude - The latitude of the point.
 * @param {number|string} points[].longitude - The longitude of the point.
 * @param {Object} [options] - The options object.
 * @param {Function} [options.getCoords] - The function that returns the latLng of a point.
 * @returns {Object[]} - The sorted array of points.
 */
module.exports = function sortByDistance(origin, points, options = {}) {
  const {
    getCoords = (point) => ({
      latitude: point?.latitude,
      longitude: point?.longitude,
    }),
  } = options;

  if (!_.isArray(points)) return null;
  if (!isLatLngValid(origin)) return points;

  const isPointValid = (point) => isLatLngValid(getCoords(point));
  const [pointsWithValidCoords, pointsWithInvalidCoords] = _.partition(
    points,
    isPointValid
  );
  /**
   * orderByDistance returns an array of objects with keys of the points array,
   * NOT the original points. So we need to map the keys back to the original points. Ugh.
   */
  const toPoint = (point) => pointsWithValidCoords[Number(point.key)];
  const coords = _.map(pointsWithValidCoords, getCoords);
  const sortedPoints = _.map(orderByDistance(origin, coords), toPoint);
  return [...sortedPoints, ...pointsWithInvalidCoords];
};
