import { Area } from '@/api/easypark/entities/areas/models';
import { Parking } from '@/api/easypark/entities/parkings/models';
import {
  identifyMixpanelUser,
  trackMixpanelEvent,
  resetMixpanelInstance,
} from '@/lib/analytics/helpers';

// user
export function identifyUser({ ssoId }) {
  identifyMixpanelUser(`ssoid-${ssoId}`);
}

// start page
export function trackStartPageViewed() {
  trackMixpanelEvent('Start Page Seen');
}

export function trackStartPageContinue({
  area,
  enabledLocationServices = false,
  isAreaSupported,
  parkingType,
}) {
  trackMixpanelEvent('Continue Tapped on Start Page', {
    'Area Code': Area.getAreaNumber(area),
    'Area Is Supported': isAreaSupported,
    'Area Type': Area.getAreaType(area),
    'Location Permission': enabledLocationServices,
    'Parking Type': parkingType,
  });
}

// duration page
export function trackDurationPageViewed() {
  trackMixpanelEvent('Duration Page Seen');
}

export function trackDurationPageContinue({ durationInMinutes }) {
  trackMixpanelEvent('Continue Tapped on Duration Page', {
    'Duration in Minutes': durationInMinutes,
  });
}

// login page
export function trackLoginPageViewed() {
  trackMixpanelEvent('Authorisation Page Seen');
}

export function trackLoginPageContinue({ countryCode }) {
  trackMixpanelEvent('Continue Tapped on Authorisation Page', {
    'Country Code Selected': countryCode,
  });
}

export function trackTermsandConditionsViewed() {
  trackMixpanelEvent('TCs Screen Seen');
}

export function trackTermsAndConditionsConsent({ hasGivenConsent }) {
  trackMixpanelEvent('TCs Consent Choice Made', {
    'Consent Given': hasGivenConsent,
  });
}

export function trackPhoneNumberSubmit() {
  trackMixpanelEvent('Phone Number Added');
}

export function trackLoginSuccess({ logInMethod }) {
  trackMixpanelEvent('Log In Success', {
    'Log In Method': logInMethod,
  });
}

export function trackVerificationCodeScreenViewed() {
  trackMixpanelEvent('Verification Code Screen Seen');
}

export function trackVerificationCodeSent() {
  trackMixpanelEvent('Verification Code Sent');
}

export function trackVerificationCodeValidate({ hasValidated }) {
  trackMixpanelEvent('Verification Code Validation', {
    Success: hasValidated,
  });
}

// vehicle page
export function trackVehiclePageViewed() {
  trackMixpanelEvent('Vehicle Page Seen');
}

export function trackVehiclePageContinue() {
  trackMixpanelEvent('Continue Tapped on Vehicle Page');
}

// payment page
export function trackPaymentPageViewed() {
  trackMixpanelEvent('Payment Page Seen');
}

export function trackPaymentPageContinue({ paymentMethod }) {
  trackMixpanelEvent('Continue Tapped on Payment Page', {
    'Payment Method': paymentMethod,
  });
}

// review page
export function trackReviewPageViewed({
  area,
  durationInMinutes,
  parkingType,
  paymentMethod,
}) {
  trackMixpanelEvent('Purchase Confirmation Page Seen', {
    'Area Code': Area.getAreaNumber(area),
    'Area Type': Area.getAreaType(area),
    'Duration in Minutes': durationInMinutes,
    'Parking Type': parkingType,
    'Payment Method': paymentMethod,
  });
}

export function trackEmailAddressSubmit() {
  trackMixpanelEvent('Email Address Added');
}

export function trackReviewPageContinue({
  area,
  durationInMinutes,
  emailAdded,
  parkingType,
  paymentMethod,
}) {
  trackMixpanelEvent('Start Parking Tapped', {
    'Area Code': Area.getAreaNumber(area),
    'Area Type': Area.getAreaType(area),
    'Duration in Minutes': durationInMinutes,
    'Email Address Added': emailAdded,
    'Parking Type': parkingType,
    'Payment Method': paymentMethod,
  });
}

export function trackParkingStart({
  area,
  durationInMinutes,
  parking,
  parkingType,
  paymentMethod,
}) {
  trackMixpanelEvent('Started Parking', {
    'Area Code': Area.getAreaNumber(area),
    'Area Type': Area.getAreaType(area),
    'Duration in Minutes': durationInMinutes,
    'Parking ID': Parking.getParkingId(parking),
    'Parking Type': parkingType,
    'Payment Method': paymentMethod,
  });
}

export function trackParkingStartFailure({
  area,
  durationInMinutes,
  errorMessage,
  parkingType,
  paymentMethod,
}) {
  trackMixpanelEvent('Started Parking Failed', {
    'Area Code': Area.getAreaNumber(area),
    'Area Type': Area.getAreaType(area),
    'Duration in Minutes': durationInMinutes,
    'Failure Reason': errorMessage,
    'Parking Type': parkingType,
    'Payment Method': paymentMethod,
  });
}

// active sessions page

export function trackParkingExtension({ parking, paymentMethod }) {
  trackMixpanelEvent('Parking Extended', {
    'Area Code': Parking.getParkingAreaNo(parking),
    'Area Type': Parking.getParkingAreaType(parking),
    'Parking ID': Parking.getParkingId(parking),
    'Parking Type': Parking.getParkingSubType(parking),
    'Payment Method': paymentMethod,
  });
}

export function trackParkingExtensionFailure({
  areaCode,
  areaType,
  errorMessage,
  parkingId,
  parkingType,
  paymentMethod,
}) {
  trackMixpanelEvent('Parking Extended Failed', {
    'Area Code': areaCode,
    'Area Type': areaType,
    'Failure Reason': errorMessage,
    'Parking ID': parkingId,
    'Parking Type': parkingType,
    'Payment Method': paymentMethod,
  });
}

// reset Mixpanel instance
export function trackMixPanelInstanceReset() {
  resetMixpanelInstance('Instance Reset');
}
