import { Image, Typography } from '@/lib/shared/components';
import PropTypes from 'prop-types';
import h from 'react-hyperscript';
import * as constants from '../../constants';
import * as Styles from './not-found-styles';

NotFound.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  title: PropTypes.string,
};

export function NotFound({ className, message, title }) {
  return h(
    Styles.Root,
    {
      className,
    },
    [
      h(Styles.Image, {
        as: Image,
        imgSrc: constants.NOT_FOUND_IMG_SRC,
      }),
      h(
        Styles.Title,
        {
          as: Typography,
          component: 'h1',
          variant: 'h1',
        },
        title || constants.NOT_FOUND_HEADER
      ),
      h(
        Styles.Message,
        {
          as: Typography,
        },
        message || constants.NOT_FOUND_MESSAGE
      ),
    ]
  );
}
