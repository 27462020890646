export const NAME = 'global-onstreet-parking';
export const REDIRECT_LINK = '/global/start';
export const REDIRECT_TO_LOGIN_LINK = '/global/login';
export const EUROPEAN_LPN_REGEX_PATTERN = /^[a-zA-Z0-9\s\-.,]{1,10}$/;
export const LEGAL_URLS = {
  COOKIE_POLICY: {
    AT: {
      de: 'https://www.easypark.com/de-at/cookies',
      en: 'https://www.easypark.com/en-at/cookie-policy',
    },
    AU: {
      en: 'https://easypark.com.au/en_au',
    },
    BE: {
      en: 'https://www.easypark.com/en-be/cookie-policy',
      fr: 'https://www.easypark.com/fr-be/cookies',
      nl: 'https://www.easypark.com/nl-be/cookiebeleid',
    },
    CH: {
      de: 'https://www.easypark.com/de-ch/cookies',
      en: 'https://www.easypark.com/en-ch/cookie-policy',
      fr: 'https://www.easypark.com/it-ch/cookie-policy',
      it: 'https://www.easypark.com/it-ch/cookie-policy',
    },
    CZ: {
      cs: 'https://easypark.cz/cs',
      en: 'https://easypark.cz/en',
    },
    DE: {
      de: 'https://www.easypark.com/de/cookies',
      en: 'https://www.easypark.com/en-de/cookie-policy',
    },
    DK: {
      da: 'https://www.easypark.com/da-dk/cookie-politik',
      en: 'https://www.easypark.com/en-de/cookie-policy',
    },
    ES: {
      ca: 'https://www.easypark.com/ca-es/politica-de-cookies',
      en: 'https://www.easypark.com/en-es/cookie-policy',
      es: 'https://www.easypark.com/es-es/politica-de-cookies',
      eu: 'https://www.easypark.com/eu-es/cookie-politika',
      gl: 'https://www.easypark.com/gl-es/politica-de-cookies',
    },
    FI: {
      en: 'https://www.easypark.com/en-fi/cookie-policy',
      fi: 'https://www.easypark.com/fi-fi/evastekaytanto',
    },
    FR: {
      en: 'https://www.easypark.com/en-fr/cookie-policy',
      fr: 'https://www.easypark.com/fr-fr/cookies',
    },
    HU: {
      en: 'https://easypark.hu/en',
      hu: 'https://easypark.hu/hu',
    },
    IS: {
      en: 'https://easypark.is/en',
      is: 'https://easypark.is/is',
    },
    IT: {
      en: 'https://www.easypark.com/en-it/cookie-policy',
      it: 'https://www.easypark.com/it-it/cookie-policy',
    },
    NL: {
      en: 'https://www.easypark.com/en-nl/cookie-policy',
      nl: 'https://www.easypark.com/nl/cookiebeleid',
    },
    NO: {
      en: 'https://www.easypark.com/en-no/cookie-policy',
      no: 'https://www.easypark.com/no-no/informasjonskapsler',
    },
    NZ: {
      en: 'https://easypark.nz/en_nz',
    },
    PT: {
      en: 'https://www.easypark.com/en-pt/cookie-policy',
      pt: 'https://www.easypark.com/pt-pt/politica-de-cookies',
    },
    SE: {
      en: 'https://www.easypark.com/en-se/cookie-policy',
      sv: 'https://www.easypark.com/sv-se/cookie-policy',
    },
    SI: {
      en: 'https://www.easypark.com/en-si/cookie-policy',
      sl: 'https://www.easypark.com/si-si/politika-piskotkov',
    },
    SK: {
      en: 'https://easypark.sk/en',
      sk: 'https://easypark.sk/sk',
    },
  },

  PRIVACY_POLICY: {
    AT: {
      de: 'https://legals.easyparksystem.net/AT/privacy/de/privacy_at_de.pdf',
      en: 'https://legals.easyparksystem.net/AT/privacy/en/privacy_at_en.pdf',
    },
    AU: {
      en: 'https://legals.easyparksystem.net/AU/privacy/en/privacy_au_en.pdf',
    },
    BE: {
      en: 'https://legals.easyparksystem.net/BE/privacy/en/privacy_be_en.pdf',
      fr: 'https://legals.easyparksystem.net/BE/privacy/fr/privacy_be_fr.pdf',
      nl: 'https://legals.easyparksystem.net/BE/privacy/nl/privacy_be_nl.pdf',
    },
    CH: {
      de: 'https://legals.easyparksystem.net/CH/privacy/de/privacy_ch_de.pdf',
      en: 'https://legals.easyparksystem.net/CH/privacy/en/privacy_ch_en.pdf',
      fr: 'https://legals.easyparksystem.net/CH/privacy/fr/privacy_ch_fr.pdf',
      it: 'https://legals.easyparksystem.net/CH/privacy/it/privacy_ch_it.pdf',
    },
    CZ: {
      cs: 'https://legals.easyparksystem.net/CZ/privacy/cs/privacy_cs_cs.pdf',
      en: 'https://legals.easyparksystem.net/CZ/privacy/en/privacy_cs_en.pdf',
    },
    DE: {
      de: 'https://legals.easyparksystem.net/DE/privacy/de/privacy_de_de.pdf',
      en: 'https://legals.easyparksystem.net/DE/privacy/en/privacy_de_en.pdf',
    },
    DK: {
      da: 'https://legals.easyparksystem.net/DK/privacy/da/privacy_dk_da.pdf',
      en: 'https://legals.easyparksystem.net/DK/privacy/en/privacy_dk_en.pdf',
    },
    ES: {
      ca: 'https://legals.easyparksystem.net/ES/privacy/ca/privacy_es_ca.pdf',
      en: 'https://legals.easyparksystem.net/ES/privacy/en/privacy_es_en.pdf',
      es: 'https://legals.easyparksystem.net/ES/privacy/es/privacy_es_es.pdf',
    },
    FI: {
      en: 'https://legals.easyparksystem.net/FI/privacy/en/privacy_fi_en.pdf',
      fi: 'https://legals.easyparksystem.net/FI/privacy/fi/privacy_fi_fi.pdf',
      sv: 'https://legals.easyparksystem.net/FI/privacy/sv/privacy_fi_sv.pdf',
    },
    FO: {
      da: 'https://legals.easyparksystem.net/DK/privacy/da/privacy_dk_da.pdf',
      en: 'https://legals.easyparksystem.net/DK/privacy/en/privacy_dk_en.pdf',
    },
    FR: {
      en: 'https://legals.easyparksystem.net/FR/privacy/en/privacy_fr_en.pdf',
      fr: 'https://legals.easyparksystem.net/FR/privacy/fr/privacy_fr_fr.pdf',
    },
    HU: {
      en: 'https://legals.easyparksystem.net/HU/privacy/en/privacy_hu_en.pdf',
      hu: 'https://legals.easyparksystem.net/HU/privacy/hu/privacy_hu_hu.pdf',
    },
    IS: {
      en: 'https://legals.easyparksystem.net/IS/privacy/en/privacy_is_en.pdf',
      is: 'https://legals.easyparksystem.net/IS/privacy/is/privacy_is_is.pdf',
    },
    IT: {
      en: 'https://legals.easyparksystem.net/IT/privacy/en/privacy_it_en.pdf',
      it: 'https://legals.easyparksystem.net/IT/privacy/it/privacy_it_it.pdf',
    },
    ME: {
      en: 'https://legals.easyparksystem.net/RS/privacy/en/privacy_rs_en.pdf',
      sr: 'https://legals.easyparksystem.net/ME/privacy/sr/privacy_me_sr.pdf',
    },

    NL: {
      en: 'https://legals.easyparksystem.net/NL/privacy/en/privacy_nl_en.pdf',
      nl: 'https://legals.easyparksystem.net/NL/privacy/nl/privacy_nl_nl.pdf',
    },
    NO: {
      en: 'https://legals.easyparksystem.net/NO/privacy/en/privacy_no_en.pdf',
      nb: 'https://legals.easyparksystem.net/NO/privacy/no/privacy_no_no.pdf',
      nn: 'https://legals.easyparksystem.net/NO/privacy/no/privacy_no_no.pdf',
      no: 'https://legals.easyparksystem.net/NO/privacy/no/privacy_no_no.pdf',
    },
    NZ: {
      en: 'https://legals.easyparksystem.net/NZ/privacy/en/privacy_nz_en.pdf',
    },
    PT: {
      en: 'https://legals.easyparksystem.net/PT/privacy/en/privacy_pt_en.pdf',
      pt: 'https://legals.easyparksystem.net/PT/privacy/pt/privacy_pt_pt.pdf',
    },
    RS: {
      en: 'https://legals.easyparksystem.net/RS/privacy/en/privacy_rs_en.pdf',
      sr: 'https://legals.easyparksystem.net/RS/privacy/sr/privacy_rs_sr.pdf',
    },
    SE: {
      en: 'https://legals.easyparksystem.net/SE/privacy/en/privacy_se_en.pdf',
      sv: 'https://legals.easyparksystem.net/SE/privacy/sv/privacy_se_sv.pdf',
    },
    SI: {
      en: 'https://legals.easyparksystem.net/SI/privacy/en/privacy_si_en.pdf',
      sl: 'https://legals.easyparksystem.net/SI/privacy/sl/privacy_si_sl.pdf',
    },
    SK: {
      en: 'https://legals.easyparksystem.net/SK/privacy/en/privacy_sk_en.pdf',
      sk: 'https://legals.easyparksystem.net/SK/privacy/sk/privacy_sk_sk.pdf',
    },
  },

  TERMS_AND_CONDITIONS: {
    AT: {
      de: 'https://legals.easyparksystem.net/AT/tc/de/terms_at_de.pdf',
      en: 'https://legals.easyparksystem.net/AT/tc/en/terms_at_en.pdf',
    },
    AU: {
      en: 'https://d30uu2ev3c9rkd.cloudfront.net/desktop/pdfs/terms/terms_au_en.pdf',
    },
    BE: {
      en: 'https://legals.easyparksystem.net/BE/tc/en/terms_be_en.pdf',
      fr: 'https://legals.easyparksystem.net/BE/tc/fr/terms_be_fr.pdf',
      nl: 'https://legals.easyparksystem.net/BE/tc/nl/terms_be_nl.pdf',
    },
    CH: {
      de: 'https://legals.easyparksystem.net/CH/tc/de/terms_ch_de.pdf',
      en: 'https://legals.easyparksystem.net/CH/tc/en/terms_ch_en.pdf',
      fr: 'https://legals.easyparksystem.net/CH/tc/fr/terms_ch_fr.pdf',
      it: 'https://legals.easyparksystem.net/CH/tc/it/terms_ch_it.pdf',
    },
    CZ: {
      cs: 'https://legals.easyparksystem.net/CZ/tc/cs/terms_cs_cs.pdf',
      en: 'https://legals.easyparksystem.net/CZ/tc/en/terms_cs_en.pdf',
    },
    DE: {
      de: 'https://legals.easyparksystem.net/DE/tc/de/terms_de_de.pdf',
      en: 'https://legals.easyparksystem.net/DE/tc/en/terms_de_en.pdf',
    },
    DK: {
      da: 'https://legals.easyparksystem.net/DK/tc/da/terms_dk_da.pdf',
      en: 'https://legals.easyparksystem.net/DK/tc/en/terms_dk_en.pdf',
    },
    ES: {
      ca: 'https://legals.easyparksystem.net/ES/tc/ca/terms_es_ca.pdf',
      en: 'https://legals.easyparksystem.net/ES/tc/en/terms_es_en.pdf',
      es: 'https://legals.easyparksystem.net/ES/tc/es/terms_es_es.pdf',
    },
    FI: {
      en: 'https://legals.easyparksystem.net/FI/tc/en/terms_fi_en.pdf',
      fi: 'https://legals.easyparksystem.net/FI/tc/fi/terms_fi_fi.pdf',
      sv: 'https://legals.easyparksystem.net/FI/tc/sv/terms_fi_sv.pdf',
    },
    FO: {
      da: 'https://legals.easyparksystem.net/DK/tc/da/terms_dk_da.pdf',
      en: 'https://legals.easyparksystem.net/DK/tc/en/terms_dk_en.pdf',
    },
    FR: {
      en: 'https://legals.easyparksystem.net/FR/tc/en/terms_fr_en.pdf',
      fr: 'https://legals.easyparksystem.net/FR/tc/fr/terms_fr_fr.pdf',
    },
    HU: {
      en: 'https://legals.easyparksystem.net/HU/tc/en/terms_hu_en.pdf',
      hu: 'https://legals.easyparksystem.net/HU/tc/hu/terms_hu_hu.pdf',
    },
    IS: {
      en: 'https://legals.easyparksystem.net/IS/tc/en/terms_is_en.pdf',
      is: 'https://legals.easyparksystem.net/IS/tc/is/terms_is_is.pdf',
    },
    IT: {
      en: 'https://legals.easyparksystem.net/IT/tc/en/terms_it_en.pdf',
      it: 'https://legals.easyparksystem.net/IT/tc/it/terms_it_it.pdf',
    },
    ME: {
      en: 'https://legals.easyparksystem.net/RS/tc/en/terms_rs_en.pdf',
      sr: 'https://legals.easyparksystem.net/ME/tc/sr/terms_me_sr.pdf',
    },
    NL: {
      en: 'https://legals.easyparksystem.net/NL/tc/en/terms_nl_en.pdf',
      nl: 'https://legals.easyparksystem.net/NL/tc/nl/terms_nl_nl.pdf',
    },
    NO: {
      en: 'https://legals.easyparksystem.net/NO/tc/en/terms_no_en.pdf',
      nb: 'https://legals.easyparksystem.net/NO/tc/no/terms_no_no.pdf',
      nn: 'https://legals.easyparksystem.net/NO/tc/no/terms_no_no.pdf',
      no: 'https://legals.easyparksystem.net/NO/tc/no/terms_no_no.pdf',
    },
    NZ: {
      en: 'https://legals.easyparksystem.net/NZ/tc/en/terms_nz_en.pdf',
    },
    PT: {
      en: 'https://legals.easyparksystem.net/PT/tc/en/terms_pt_en.pdf',
      pt: 'https://legals.easyparksystem.net/PT/tc/pt/terms_pt_pt.pdf',
    },
    RS: {
      en: 'https://legals.easyparksystem.net/RS/tc/en/terms_rs_en.pdf',
      sr: 'https://legals.easyparksystem.net/RS/tc/sr/terms_rs_sr.pdf',
    },
    SE: {
      en: 'https://legals.easyparksystem.net/SE/tc/en/terms_se_en.pdf',
      sv: 'https://legals.easyparksystem.net/SE/tc/sv/terms_se_sv.pdf',
    },
    SI: {
      en: 'https://legals.easyparksystem.net/SI/tc/en/terms_si_en.pdf',
      sl: 'https://legals.easyparksystem.net/SI/tc/sl/terms_si_sl.pdf',
    },
    SK: {
      en: 'https://legals.easyparksystem.net/SK/tc/en/terms_sk_en.pdf',
      sk: 'https://legals.easyparksystem.net/SK/tc/sk/terms_sk_sk.pdf',
    },
  },
};

export const SUPPORTED_COUNTRIES = {
  AT: {
    currency: 'EUR',
    emoji: '🇦🇹',
    name: 'Austria',
    phone: '43',
  },
  BE: {
    currency: 'EUR',
    emoji: '🇧🇪',
    name: 'Belgium',
    phone: '32',
  },
  CH: {
    currency: 'CHE',
    emoji: '🇨🇭',
    name: 'Switzerland',
    phone: '41',
  },
  CZ: {
    currency: 'CZK',
    emoji: '🇨🇿',
    name: 'Czech Republic',
    phone: '420',
  },
  DE: {
    currency: 'EUR',
    emoji: '🇩🇪',
    name: 'Germany',
    phone: '49',
  },
  DK: {
    currency: 'DKK',
    emoji: '🇩🇰',
    name: 'Denmark',
    phone: '45',
  },
  ES: {
    currency: 'EUR',
    emoji: '🇪🇸',
    name: 'Spain',
    phone: '34',
  },
  FI: {
    currency: 'EUR',
    emoji: '🇫🇮',
    name: 'Finland',
    phone: '358',
  },
  FO: {
    countryCodeOverride: 'DK',
    currency: 'DKK',
    emoji: '🇫🇴',
    name: 'Faroe Islands',
    phone: '298',
  },
  FR: {
    currency: 'EUR',
    emoji: '🇫🇷',
    name: 'France',
    phone: '33',
  },
  HU: {
    currency: 'HUF',
    emoji: '🇭🇺',
    name: 'Hungary',
    phone: '36',
  },
  IS: {
    currency: 'ISK',
    emoji: '🇮🇸',
    name: 'Iceland',
    phone: '354',
  },
  IT: {
    currency: 'EUR',
    emoji: '🇮🇹',
    name: 'Italy',
    phone: '39',
  },
  NL: {
    currency: 'EUR',
    emoji: '🇳🇱',
    name: 'Netherlands',
    phone: '31',
  },
  NO: {
    currency: 'NOK',
    emoji: '🇳🇴',
    name: 'Norway',
    phone: '47',
  },
  PT: {
    currency: 'EUR',
    emoji: '🇵🇹',
    name: 'Portugal',
    phone: '351',
  },
  SE: {
    currency: 'SEK',
    emoji: '🇸🇪',
    name: 'Sweden',
    phone: '46',
  },
  SI: {
    currency: 'EUR',
    emoji: '🇸🇮',
    name: 'Slovenia',
    phone: '386',
  },
  SK: {
    currency: 'EUR',
    emoji: '🇸🇰',
    name: 'Slovakia',
    phone: '421',
  },
};
