import styled from 'styled-components';

const getFill = (theme, modifiers) => {
  if (modifiers.variant === 'full-color') return null;
  if (modifiers.color) return modifiers.color;
  if (modifiers.inheritColor) return 'currentColor !important';
  if (modifiers.disabled) return theme.getTextColor('disabled');
  if (modifiers.variant === 'primary-text')
    return theme.getTextColor('primary');
  if (modifiers.variant === 'hint-text') return theme.getTextColor('hint');
  if (modifiers.variant === 'primary')
    return theme.getPaletteColor('primary', 'main');
  if (modifiers.variant === 'primary-dark')
    return theme.getPaletteColor('primary', 'dark');
  if (modifiers.variant === 'black')
    return theme.getPaletteColor('common', 'black');
  if (modifiers.variant === 'white')
    return theme.getPaletteColor('common', 'white');
  if (modifiers.variant === 'secondary')
    return theme.getPaletteColor('secondary');
  if (modifiers.variant === 'warning') return theme.getPaletteColor('warning');
  if (modifiers.variant === 'error') return theme.getPaletteColor('error');
  return theme.getTextColor('primary');
};

export const StyledRoot = styled.div(({ modifiers }) => ({
  alignItems: 'center',
  display: 'flex',
  flex: '0 0 auto',
  height: modifiers.auto ? '100%' : '40px',
  justifyContent: 'center',
  position: 'relative',
  userSelect: 'none',
  // Keep the width and height at 40px to ensure adequate touch targets on mobile
  width: modifiers.auto ? '100%' : '40px',
}));

export const StyledContent = styled.div(({ theme, modifiers }) => ({
  alignItems: 'center',
  display: 'flex',
  flex: '0 0 auto',
  justifyContent: 'center',
  ...(modifiers.size === 'auto' && {
    height: '100%',
    width: '100%',
  }),
  ...(modifiers.size === 'x-large' && {
    height: '36px',
    width: '36px',
  }),
  ...(modifiers.size === 'large' && {
    height: '32px',
    width: '32px',
  }),
  ...(modifiers.size === 'medium' && {
    height: '24px',
    width: '24px',
  }),
  ...(modifiers.size === 'small' && {
    height: '16px',
    width: '16px',
  }),
  ...(modifiers.disabled && {
    '&:hover, &:focus': {
      outline: '0',
      textDecoration: 'none',
    },
    cursor: 'not-allowed',
  }),
  /* eslint-disable-next-line sort-keys */
  '& svg': {
    height: '100%',
    width: '100%',
    /* eslint-disable-next-line sort-keys */
    '& title': {
      pointerEvents: 'none',
    },
    '*': {
      fill: getFill(theme, modifiers),
    },
  },
}));
