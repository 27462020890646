import PropTypes from 'prop-types';
import React from 'react';
import h from 'react-hyperscript';
import { showIf } from 'react-render-helpers';
import { Icon } from '../icon/icon';
import { StyledRoot, StyledContent, StyledIcon } from './button-styles';

const sizes = ['auto', 'small', 'medium', 'large'];
// NOTE: white and black variants are constant regardless whether app has
// light or dark theme applied. These are mostly for overlaying buttons on images.
const variants = [
  'default',
  'primary',
  'secondary',
  'danger',
  'white',
  'black',
];
const types = ['raised', 'outline', 'flat'];

export class Button extends React.PureComponent {
  static defaultProps = {
    iconPosition: 'left',
    showMargin: false,
    size: 'large',
    type: 'raised',
    variant: 'default',
  };

  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    iconName: PropTypes.string,
    iconPosition: PropTypes.oneOf(['left', 'right']),
    onClick: PropTypes.func,
    showMargin: PropTypes.bool,
    size: PropTypes.oneOf(sizes),
    type: PropTypes.oneOf(types),
    variant: PropTypes.oneOf(variants),
  };

  el = null;

  handleClick = (e) => {
    const { onClick } = this.props;

    if (onClick) {
      e.preventDefault();
      e.stopPropagation();
      onClick(e);
    }

    this.el.blur();
  };

  setRef = (el) => {
    this.el = el;
  };

  render() {
    const {
      children,
      className,
      iconName,
      iconPosition,
      onClick,
      showMargin,
      size,
      variant, // Theme prop conflicts with Styled Components, so we can alias as variant
      type,
      ...props
    } = this.props;

    const showLeftIcon = iconName && iconPosition === 'left';
    const showRightIcon = iconName && iconPosition === 'right';
    const icon = h(StyledIcon, {
      as: Icon,
      iconName,
      inheritColor: true,
      isLeft: showLeftIcon,
      isRight: showRightIcon,
      size: 'auto',
    });

    return h(
      StyledRoot,
      {
        ...props,
        className,
        iconName,
        iconPosition,
        onClick: this.handleClick,
        ref: this.setRef,
        showMargin,
        size: type === 'flat' ? 'auto' : size,
        type,
        variant,
      },
      [
        showIf(showLeftIcon)(() => icon),
        h(StyledContent, {}, [children]),
        showIf(showRightIcon)(() => icon),
      ]
    );
  }
}
