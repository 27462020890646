import _ from 'lodash';
import {
  actionChannel,
  all,
  call,
  fork,
  put,
  take,
  takeEvery,
} from 'redux-saga/effects';
import { batchActions } from 'redux-batched-actions';
import { buffers, channel } from 'redux-saga';
import * as helpers from '../helpers';

function* handleClientRequests() {
  const incomingReqBuffer = buffers.expanding(100);
  const incomingReqChannel = yield actionChannel(
    helpers.shouldResolveAction,
    incomingReqBuffer
  );
  const batchedUpdateChannel = yield call(channel, buffers.expanding(100));
  const shouldBatchResult = (action) =>
    _.get(helpers.getRequestActionInfo(action), 'allowBatching', false);
  const pendingBatchedActions = [];
  const areBatchedActionsPending = () =>
    !incomingReqBuffer.isEmpty() || pendingBatchedActions.length > 0;

  // Function to handle the start of a request action
  const handleStart = (action) => {
    if (shouldBatchResult(action)) {
      pendingBatchedActions.push(action);
    }
  };

  // Function to handle the end of a request action
  function* handleComplete(action, nextAction) {
    if (shouldBatchResult(action)) {
      const index = pendingBatchedActions.indexOf(action);
      pendingBatchedActions.splice(index, 1);
      yield put(batchedUpdateChannel, nextAction);
    } else {
      yield put(nextAction);
    }
  }

  // Let's continuously watch the batch update channel for result actions to batch
  yield fork(watchUpdateChannel, {
    areBatchedActionsPending,
    srcChannel: batchedUpdateChannel,
  });

  // Let's continuously watch the request channel for requests to resolve
  yield all(
    [0, 1, 2, 3, 4, 5].map(() =>
      fork(watchRequestChannel, {
        onComplete: handleComplete,
        onStart: handleStart,
        srcChannel: incomingReqChannel,
      })
    )
  );
}

function* watchUpdateChannel({ srcChannel, areBatchedActionsPending }) {
  // eslint-disable-next-line fp/no-loops
  while (true) {
    const actions = [];
    const startTime = new Date().getTime();

    // eslint-disable-next-line fp/no-loops
    do {
      const currentTime = new Date().getTime();

      const elapsedTime = parseInt((currentTime - startTime) / 1000, 10);

      /* If elapsedTime is greater than 1 second,
      break from loop and dispatch batched actions. */
      if (elapsedTime > 1) {
        break;
      }

      const action = yield take(srcChannel);
      actions.push(action);
    } while (actions.length < 10 && areBatchedActionsPending());

    yield put(batchActions(actions));
  }
}

function* watchRequestChannel({ srcChannel, onComplete, onStart }) {
  // eslint-disable-next-line fp/no-loops
  while (true) {
    const action = yield take(srcChannel);
    yield call(onStart, action);
    yield call(resolveRequestAction, action, onComplete);
  }
}

function* resolveRequestAction(action, onComplete = null) {
  try {
    const request = yield call(helpers.getRequestActionInfo, action);
    const nextAction = yield call(helpers.resolveRequestAction, request);

    if (onComplete) {
      yield call(onComplete, action, nextAction);
    } else {
      yield put(nextAction);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

/**
 * @deprecated redux-saga's are deprecated. only register.js should import the saga file
 * as we continue to migrate away
 */
export const saga = function* apiSaga(action) {
  const isServer = typeof window === 'undefined';

  if (action) {
    // We need to dispatch a second ignored action. This lets reducers handle
    // this action as expected without causing sagas to send the request twice.
    yield put(helpers.createIgnoredRequestAction(action));
    yield fork(resolveRequestAction, action);
  } else if (isServer) {
    yield takeEvery(helpers.shouldResolveAction, resolveRequestAction);
  } else {
    yield call(handleClientRequests);
  }
};
