import _ from 'lodash';

export const PaymentDeviceV2 = {
  getCreditCardType: (paymentDevice) =>
    _.upperFirst(_.get(paymentDevice, 'data.cardVendor')),
  getMaskedCreditCardNumber: (paymentDevice) =>
    _.get(paymentDevice, 'data.maskedCardNumber'),
  getType: (paymentDevice) => _.get(paymentDevice, 'type'),
};

export const Account = {
  getBillingAccount: (account) => _.get(account, 'billingAccount', {}),
  getBillingAccountId: (account) =>
    _.get(Account.getBillingAccount(account), 'id'),
  getCanManagePaymentDevice: (account) =>
    _.get(account, 'permissions.canManagePaymentDevice', false),
  getParkingUserId: (account) => _.get(account, 'parkingUserId'),
  getPaymentDeviceV2: (account) => _.get(account, 'paymentDeviceV2', {}),
};

export const User = {
  getAccounts: (user) => _.get(user, 'accounts', []),
  getAccountWithActivePaymentDevice: (user) =>
    _.find(
      User.getAccounts(user),
      (account) =>
        Account.getCanManagePaymentDevice(account) &&
        Account.getPaymentDeviceV2(account)
    ),
  getAccountWithManageablePaymentDevice: (user) =>
    _.find(User.getAccounts(user), Account.getCanManagePaymentDevice),
  getParkingUserIdFromAccountWithActivePaymentDevice: (user) =>
    _.get(User.getAccountWithActivePaymentDevice(user), 'parkingUserId'),
  getSSOId: (userDetails) => (userDetails ? _.get(userDetails, 'ssoId') : ''),
  getUsername: (userDetails) =>
    userDetails ? _.get(userDetails, 'username') : '',
};
