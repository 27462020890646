const _ = require('lodash');
const oneConfig = require('@parkmobile/one-config');

const getServersideIsAuthenticated = (req) => {
  const config = oneConfig.config();
  const EASYPARK_IS_AUTHENTICATED_COOKIE =
    config.EASYPARK_IS_AUTHENTICATED_COOKIE;

  return Boolean(_.get(req, `cookies.${EASYPARK_IS_AUTHENTICATED_COOKIE}`));
};
module.exports = getServersideIsAuthenticated;
