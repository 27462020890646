import { fetch } from '@/lib/api/helpers';
import _ from 'lodash';
import { isEmail } from 'validator';

export const appleLogin = async (data, { raw = false } = {}) => {
  const result = await fetch({
    data,
    endpoint: '/login/apple',
    method: 'POST',
  });

  if (raw) return result;

  return _.get(result, 'data');
};

export const googleLogin = async ({ accessToken }, { raw = false } = {}) => {
  const data = {
    accessToken,
  };

  const result = await fetch({
    data,
    endpoint: '/login/google',
    method: 'POST',
  });

  if (raw) return result;

  return _.get(result, 'data');
};

export const login = async (
  { password, recaptchaV3Token, username },
  { raw = false } = {}
) => {
  const data = isEmail(username)
    ? {
        email: username,
        password,
      }
    : {
        mobileNumber: username,
        password,
      };

  const result = await fetch({
    data,
    endpoint: '/login',
    headers: {
      ...(recaptchaV3Token ? { 'x-recaptcha-v3-token': recaptchaV3Token } : {}),
    },
    method: 'POST',
  });

  if (raw) return result;

  return _.get(result, 'data');
};

export const logout = async ({ raw = false } = {}) => {
  const result = await fetch({
    endpoint: '/logout',
    method: 'POST',
  });

  if (raw) return result;

  return _.get(result, 'data');
};

export const refresh = async ({ raw = false } = {}) => {
  const timeout = 30 * 1000; // 30 seconds, to match the proxy server timeout

  const result = await fetch({
    endpoint: '/refresh',
    method: 'POST',
    timeout,
  });

  if (raw) return result;

  return _.get(result, 'data');
};

export const register = async (
  { email, password, recaptchaV3Token, redirect } = {},
  { raw = false } = {}
) => {
  const data = {
    email,
    password,
    redirect,
  };
  const result = await fetch({
    data,
    endpoint: '/register',
    headers: {
      ...(recaptchaV3Token ? { 'x-recaptcha-v3-token': recaptchaV3Token } : {}),
    },
    method: 'POST',
    // set register timeout to 10 seconds to ensure backend has
    // enough time to process request and send response
    timeout: 1000 * 10,
  });

  return raw ? result : _.get(result, 'data');
};

// request account verification code via email
export const sendCode = async ({ raw = false } = {}) => {
  const result = await fetch({
    endpoint: '/email-verification/send-code',
    method: 'POST',
    // set timeout to 10 seconds to ensure backend has
    // enough time to process request and send response
    timeout: 1000 * 10,
  });

  return raw ? result : _.get(result, 'data');
};

// verify an email address via emailed code
export const verifyCode = async ({ code } = {}, { raw = false } = {}) => {
  const data = { code };
  const result = await fetch({
    data,
    endpoint: '/email-verification/verify-code',
    method: 'POST',
    // set timeout to 10 seconds to ensure backend has
    // enough time to process request and send response
    timeout: 1000 * 10,
  });

  return raw ? result : _.get(result, 'data');
};

// send magic link via code
export const sendMagicLink = async (
  { email, phoneNumber, recaptchaV3Token, redirect } = {},
  { raw = false } = {}
) => {
  const data = {
    email,
    phoneNumber,
    redirect,
  };
  const result = await fetch({
    data,
    endpoint: '/login/send-login-token',
    headers: {
      ...(recaptchaV3Token ? { 'x-recaptcha-v3-token': recaptchaV3Token } : {}),
    },
    method: 'POST',
    // set timeout to 10 seconds to ensure backend has
    // enough time to process request and send response
    timeout: 1000 * 10,
  });

  return raw ? result : _.get(result, 'data');
};

export const loginWithMagicLink = async (
  { magicLinkToken },
  { raw = false } = {}
) => {
  const result = await fetch({
    data: { token: magicLinkToken },
    endpoint: '/login/magic-link',
    method: 'POST',
    // set timeout to 10 seconds to ensure backend has
    // enough time to process request and send response
    timeout: 1000 * 10,
  });

  if (raw) return result;

  return _.get(result, 'data');
};

// user is guaranteed to have email since the magic link is provided via email
export const updatePasswordWithMagicToken = async (
  { email, newPassword, token } = {},
  { raw = false } = {}
) => {
  const data = { email, newPassword, token };
  const result = await fetch({
    data,
    endpoint: '/change-password/magic-token',
    method: 'PUT',
    // set timeout to 10 seconds to ensure backend has
    // enough time to process request and send response
    timeout: 1000 * 10,
  });

  return raw ? result : _.get(result, 'data');
};

// differs from magic link flow in that user need not be logged in
export const updatePasswordWithResetToken = async (
  { newPassword, token } = {},
  { raw = false } = {}
) => {
  const data = { newPassword, token };
  const result = await fetch({
    data,
    endpoint: '/change-password/reset-token',
    method: 'PUT',
    // set timeout to 10 seconds to ensure backend has
    // enough time to process request and send response
    timeout: 1000 * 10,
  });

  return raw ? result : _.get(result, 'data');
};

export const sendResetPasswordEmail = async (
  { email } = {},
  { raw = false } = {}
) => {
  const result = await fetch({
    data: { recipient: email },
    endpoint: '/proxy/parkmobileapi/message/resetpassword',
    method: 'POST',
  });

  return raw ? result : _.get(result, 'data');
};
