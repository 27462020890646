import axios from 'axios';
import cookiesHelper from 'axios/lib/helpers/cookies';
import https from 'https';
import LRUCache from 'lru-cache';
import urlIsSameOrigin from 'axios/lib/helpers/isURLSameOrigin';
import _ from 'lodash';
import oneConfig from '@parkmobile/one-config';
import hash from 'object-hash';
// eslint-disable-next-line
import * as constants from './constants';

const { ENABLE_LOGGING, NODE_ENV } = oneConfig.config();

function log(message) {
  // eslint-disable-next-line no-console
  if (ENABLE_LOGGING) console.log(message);
}

function logRequest({ url }) {
  log(`Resolving API request for URL: ${url}`);
}

function logSuccess({ url }) {
  log(`Successfully resolved request for URL: ${url}`);
}

function logFailure({ error, url }) {
  log(`Failed to resolve request for URL: ${url} and received error: ${error}`);
}

export function defaultGetErrorMessage(err) {
  return (
    // All header names are lowercased per axios docs
    _.get(err, 'response.headers.pmerrormessage') ||
    _.get(err, 'response.data.error') ||
    _.get(err, 'message') ||
    constants.DEFAULT_ERROR_MESSAGE
  );
}

export function extractAuthorization(req) {
  const { JWT_COOKIE } = oneConfig.config();
  return req && req.cookies && req.cookies[JWT_COOKIE];
}

// convert cookies object to a key-value pair string
export function extractCookies(req) {
  const jsonCookies = _.get(req, 'cookies');
  const stringifiedCookies = _.map(
    jsonCookies,
    (value, key) => `${key}=${value}`
  ).join(';');

  return stringifiedCookies;
}

function getApi() {
  const isServer = typeof window === 'undefined';
  const isDev = NODE_ENV !== 'production';

  // In development we don't want self-signed certs to cause an error. See
  // this issue: https://github.com/axios/axios/issues/535
  if (isServer && isDev) {
    return axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false,
      }),
    });
  }

  return axios.create({});
}

const buildHeaders = ({ authorization, baseURL, cookies, headers, method }) => {
  const isClient = typeof window !== 'undefined';
  const isGetMethod = method === 'GET';
  const xsrfValue =
    isClient && !isGetMethod && urlIsSameOrigin(baseURL)
      ? cookiesHelper.read(constants.XSRF_COOKIE_NAME)
      : undefined;
  const xsrfHeader = xsrfValue
    ? { [constants.XSRF_HEADER_NAME]: xsrfValue }
    : {};
  const authorizationHeader = authorization
    ? { Authorization: `JWT ${authorization}` }
    : {};
  const applicationHeader = urlIsSameOrigin(baseURL)
    ? { SourceAppKey: oneConfig.get('SOURCE_APP_KEY') }
    : {};
  const customHeaders = headers || {};
  const cookiesHeader = cookies ? { cookie: cookies } : {};

  return {
    ...applicationHeader,
    ...authorizationHeader,
    ...xsrfHeader,
    ...cookiesHeader,
    ...customHeaders,
  };
};

const buildAxiosConfig = ({
  authorization,
  baseURL: preferredBaseURL,
  cookies,
  endpoint: url,
  headers,
  method,
  timeout = 5000,
  ...rest
}) => {
  const isClient = typeof window !== 'undefined';
  const defaultBaseURL = isClient
    ? `${oneConfig.get('HOST')}/api`
    : `${oneConfig.get('INTERNAL_HOST')}/api`;
  const baseURL = preferredBaseURL || defaultBaseURL;

  return {
    baseURL,
    headers: buildHeaders({ authorization, baseURL, cookies, headers, method }),
    method,
    timeout,
    url,
    ...rest,
  };
};

/**
 * Fetches with axios.
 *
 * @param   {Object}  request  A request. Consists of axios arguments and an
 *                             optional getErrorMessage argument that may be used
 *                             to modify the error message.
 *
 * @return  {Promise}
 */
export function fetch(request) {
  const axiosConfig = buildAxiosConfig(request);
  const handleSuccess = (response) => {
    const url = _.get(response, 'config.url');
    logSuccess({ url });
    const result = {
      data: _.get(response, 'data'),
      headers: _.get(response, 'headers'),
      statusCode: _.get(response, 'status'),
      statusText: _.get(response, 'statusText'),
    };
    return result;
  };
  const handleFailure = (error) => {
    const res = _.get(error, 'response');
    const url = _.get(error, 'config.url');
    logFailure({ error, url });
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (res) {
      const result = {
        data: _.get(res, 'data'),
        statusCode: _.get(res, 'status'),
        statusText: _.get(res, 'statusText'),
      };
      throw result;
    }
    /**
     * The request was made but no response was received
     * (`error.request` is an instance of XMLHttpRequest in the browser)
     * or
     * Something happened in setting up the request that triggered an Error
     */
    const message = _.get(error, 'message');
    const result = {
      data: { message },
      statusCode: null,
      statusText: null,
    };
    throw result;
  };

  logRequest({ url: axiosConfig.url });

  return getApi().request(axiosConfig).then(handleSuccess).catch(handleFailure);
}

// Request Action API
export const buildRequestConfig = ({
  allowCache = false,
  authorization,
  cookies,
  endpoint,
  failure,
  getErrorMessage,
  getMetadata,
  id,
  success,
  tag,
  ...axiosArgs
}) => ({
  ...buildAxiosConfig({ ...axiosArgs, authorization, cookies, endpoint }),
  allowCache,
  id,
});

const buildResultAction = (request, result) => {
  const {
    endpoint,
    failure,
    getErrorMessage = defaultGetErrorMessage,
    getMetadata = () => null,
    method,
    success,
    tag,
  } = request;

  const { cached, data, error, status, id, timestamp } = result;

  const metadata = getMetadata(error, data);

  const getInputAction = (action, payload) =>
    _.isFunction(action) ? action(payload) : { payload, type: action };

  const getSuccessResultAction = () => {
    const successAction = getInputAction(success, data);
    return {
      ...successAction,
      meta: {
        ...(successAction.meta || {}),
        [constants.SUCCESS]: {
          cached,
          data,
          endpoint,
          id,
          metadata,
          method,
          status,
          tag,
          timestamp,
        },
      },
    };
  };

  const getFailureResultAction = () => {
    const errorMessage = getErrorMessage(error);
    const payload = { error: errorMessage, status };
    const failureAction = getInputAction(failure, payload);
    return {
      ...failureAction,
      meta: {
        ...(failureAction.meta || {}),
        [constants.FAILURE]: {
          cached: false,
          endpoint,
          id,
          message: errorMessage,
          metadata,
          method,
          serverDidRespond: !_.isNil(status),
          serverDidTimeout: _.isNil(status),
          status,
          tag,
          timestamp,
        },
      },
    };
  };

  return error ? getFailureResultAction() : getSuccessResultAction();
};

function createResolver({ cache, entries }) {
  if (entries) {
    cache.load(entries || []);
  }

  function handleError({ id, url }) {
    return (error = {}) => {
      const status = _.get(error, 'response.status');
      const timestamp = new Date().toISOString();

      logFailure({ error, url });

      cache.del(id);

      return {
        cached: false,
        error,
        id,
        status,
        timestamp,
      };
    };
  }

  function handleSuccess({ id, url }) {
    return (response = {}) => {
      const isBrowser = typeof window !== 'undefined';
      const result = {
        cached: false,
        data: response.data,
        id,
        status: response.status,
        timestamp: new Date().toISOString(),
      };

      logSuccess({ url });

      if (isBrowser) {
        cache.set(id, { ...result, cached: true });
      }

      return result;
    };
  }

  return async ({ id, ...config }) => {
    const url = `${config.baseURL}${config.url}`;
    const isBrowser = typeof window !== 'undefined';

    if (isBrowser && config.allowCache && cache.has(id)) {
      log(`Returning cached response for URL: ${url}`);
      return Promise.resolve(cache.get(id));
    }

    logRequest({ url });

    return getApi()
      .request(config)
      .then(handleSuccess({ cache, id, url }))
      .catch(handleError({ cache, id, url }));
  };
}

const resolveRequest = createResolver({
  cache: LRUCache({
    length: () => 1, // Function to compute size of an entry
    max: constants.CACHE_MAX_SIZE,
    maxAge: constants.CACHE_MAX_AGE,
  }),
});

export async function resolveRequestAction(request) {
  const result = await resolveRequest(buildRequestConfig(request));
  return buildResultAction(request, result);
}

/**
 * Creates a copy of a request action with a special ignored property to indicate that
 * it should not be handled by any sagas. This allows it to be dispatched and handled
 * by reducers only.
 *
 * @param   {Object}  action  A request action
 *
 * @return  {Object}          A new request action with the ignored flag set
 */
export function createIgnoredRequestAction(action) {
  const addIgnored = (req) => ({ ...req, ignored: true });

  if (_.has(action, constants.REQUEST)) {
    const req = _.get(action, constants.REQUEST);
    return {
      ...action,
      [constants.REQUEST]: addIgnored(req),
    };
  }

  if (_.has(action, ['meta', constants.REQUEST])) {
    const req = _.get(action, ['meta', constants.REQUEST]);
    return {
      ...action,
      meta: {
        ...action.meta,
        [constants.REQUEST]: addIgnored(req),
      },
    };
  }

  return action;
}

export function getFailureActionInfo(action) {
  return _.get(action, ['meta', constants.FAILURE]);
}

/**
 * Calculate a unique ID based on the request parameters, but
 * make sure not to include passwords so they aren't stored in memory.
 */
function calculateRequestId({ data = {}, endpoint, headers, method }) {
  return hash(
    { data, endpoint, headers, method },
    {
      excludeKeys(key) {
        return _.includes(key.toLowerCase(), 'password');
      },
    }
  );
}

export function getRequestActionInfo(action) {
  const requestInfo =
    _.get(action, [constants.REQUEST]) ||
    _.get(action, ['meta', constants.REQUEST], {});

  return {
    ...requestInfo,
    id: calculateRequestId(requestInfo),
  };
}

export function getSuccessActionInfo(action) {
  return _.get(action, ['meta', constants.SUCCESS]);
}

export function isFailureAction(action) {
  return _.has(action, ['meta', constants.FAILURE]);
}

export function isRequestAction(action) {
  // We need to support the legacy method of specify the property
  // in the action's root instead of in a meta key.
  return (
    _.has(action, [constants.REQUEST]) ||
    _.has(action, ['meta', constants.REQUEST])
  );
}

export function isSuccessAction(action) {
  return _.has(action, ['meta', constants.SUCCESS]);
}

export function shouldResolveAction(action) {
  const { ignored = false } = getRequestActionInfo(action);
  return isRequestAction(action) && !ignored;
}
