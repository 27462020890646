import { toISO8601, formatCurrency } from '@/lib/shared/helpers';
import _ from 'lodash';
import fp from 'lodash/fp';
import moment from 'moment';
import { schema } from 'normalizr';
import {
  PARKING_ERRORS,
  TIMEBLOCK_TYPES,
  ZONE_DURATION_TYPES,
  ZONE_SERVICES,
  ZONE_TYPES,
} from './constants';

const reduce = fp.reduce.convert({ cap: false });

export const PaymentOptions = {
  getAcceptedAlternativePaymentMethodTypes: (paymentOptions) =>
    fp.compose(
      // Filter should change as more alternative payment methods are supported.
      fp.map((type) => {
        const lowercaseType = _.toLower(type);
        return _.replace(lowercaseType, 'accepted', '');
      }),
      reduce(
        (acc, curr, key) => [...acc, ...(curr === true ? [[key]] : [])],
        []
      )
    )(paymentOptions),
  getAcceptedCreditCardBrands: (paymentOptions) =>
    fp.compose(
      fp.map(fp.toLower),
      fp.getOr([], 'creditCardTypesAccepted')
    )(paymentOptions),
  getAcceptedPaymentMethods: (paymentOptions) => [
    ...PaymentOptions.getAcceptedCreditCardBrands(paymentOptions),
    ...PaymentOptions.getAcceptedAlternativePaymentMethodTypes(paymentOptions),
  ],
  isAndroidPayAccepted: (paymentOptions) =>
    _.get(paymentOptions, ['androidPayAccepted']),
  isApplePayAccepted: (paymentOptions) =>
    _.get(paymentOptions, ['applePayAccepted']),
  isChasePayAccepted: (paymentOptions) =>
    _.get(paymentOptions, ['chasePayAccepted']),
  isMasterpassStandardAccepted: (paymentOptions) =>
    _.get(paymentOptions, ['masterpassStandardAccepted']),
  isMasterpassV7Accepted: (paymentOptions) =>
    _.get(paymentOptions, ['masterpassV7Accepted']),
  isPaypalAccepted: (paymentOptions) =>
    _.get(paymentOptions, ['paypalAccepted']),
  isWalletAccepted: (paymentOptions) =>
    _.get(paymentOptions, ['walletAccepted']),
};

export const Zone = {
  canPurchase: (zoneDetails) =>
    Zone.getAvailable(zoneDetails) && Zone.getHasInventory(zoneDetails),
  getAcceptedAlternativePaymentMethodTypes: (zoneDetails) =>
    fp.compose(
      PaymentOptions.getAcceptedAlternativePaymentMethodTypes,
      Zone.getPaymentOptions
    )(zoneDetails),
  getAcceptedCreditCardBrands: (zoneDetails) =>
    fp.compose(
      PaymentOptions.getAcceptedCreditCardBrands,
      Zone.getPaymentOptions
    )(zoneDetails),
  getAccessCodeOption: (zoneDetails) =>
    _.get(zoneDetails, 'parkInfo.accessCodeOption'),
  getAvailable: (zoneDetails, defaultValue = false) =>
    _.get(zoneDetails, 'zoneInfo.lotQuote.available', defaultValue),
  getCapeLotId: (zoneDetails) => _.get(zoneDetails, 'zoneInfo.capeLotId'),
  getCity: (zoneDetails, defaultValue) =>
    _.get(zoneDetails, 'zoneInfo.city', defaultValue),
  // it doesn't matter which consecutive timeblockId is passed for days/hours/minutes rates
  getConsecutiveTimeBlockId: (zoneDetails) =>
    fp.compose(
      fp.getOr(null, 'timeblockId'),
      fp.find({ timeBlockType: TIMEBLOCK_TYPES.consecutive }),
      Zone.getTimeBlocks
    )(zoneDetails),
  getCountry: (zoneDetails) => _.get(zoneDetails, 'zoneInfo.country'),
  getCurrencySymbol: () => '$',
  getCustomFields: (zoneDetails) =>
    _.get(zoneDetails, 'zoneInfo.lotQuote.productMetafields'),
  getDayDurationSelections: (zoneDetails) =>
    _.get(zoneDetails, 'parkInfo.durationSelections.dayDurationSelections') ||
    [],
  /**
   * Parse day rates into consumable response
   * These values come back in the duration selection as an array
   * of day values i.e. ['1', '2', '3']
   * so we parse them into a flattened format with values in minutes.
   * @param {Object} zoneDetails - Zone entity response
   * @return {Array.<Object>} rates - array of rates
   * @return {Number} rates.id - timeblockid of rate (can be any consecutive timeblockid)
   * @return {String} rates.label - formatted rate value i.e. '1 day' or '2 days'
   * @return {String} rates.name - name of rate (hardcoded and NOT returned from api)
   * @return {Number} rates.value - rate values in minutes
   */
  getDayRates: (zoneDetails) => {
    const durationSelections = Zone.getDayDurationSelections(zoneDetails);
    const timeBlockId = Zone.getConsecutiveTimeBlockId(zoneDetails);

    return _.map(durationSelections, (durationSelection) => ({
      id: timeBlockId,
      label:
        durationSelection > 1
          ? `${durationSelection} Days`
          : `${durationSelection} Day`,
      name: 'By the day',
      value: 1440 * durationSelection,
    }));
  },
  getDistance: (zoneDetails, defaultValue) => {
    const miles = _.get(zoneDetails, 'distanceMiles');
    return miles ? _.toNumber(miles) : defaultValue;
  },
  getEarlyBirdConditions: (zoneDetails, defaultValue) =>
    _.get(zoneDetails, 'zoneInfo.lotQuote.earlyBirdConditions', defaultValue),
  getEntranceImage: (zoneDetails, defaultValue) => {
    const imageUrl = _.get(zoneDetails, 'zoneInfo.zoneEntranceImageUrl');
    return _.isString(imageUrl) ? imageUrl : defaultValue;
  },
  getFormattedAddress: (
    zone,
    includes = {
      city: true,
      state: true,
      street: true,
      zipCode: true,
    }
  ) => {
    const street = includes.street ? Zone.getStreet(zone) : null;
    const city = includes.city ? Zone.getCity(zone) : null;
    const state = includes.state ? Zone.getState(zone) : null;
    const zipCode = includes.zipCode ? Zone.getZipCode(zone) : null;
    const prefixComma = (val) => (val ? `, ${val}` : '');

    return [
      street || '',
      street ? prefixComma(city) : city || '',
      city ? prefixComma(state) : state || '',
      state ? prefixComma(zipCode) : zipCode || '',
    ].join('');
  },
  getGoogleMapsURL: (zoneDetails, defaultValue) => {
    const { latitude, longitude } = Zone.getLatLng(zoneDetails);
    const mapUrl = 'https://www.google.com/maps/dir/?api=1&destination=';
    const destinationUrl = `${mapUrl}${latitude}%2c${longitude}`;
    return destinationUrl ? `${destinationUrl}` : defaultValue;
  },
  getGpsPoints: (zoneDetails, defaultValue) =>
    _.get(zoneDetails, 'gpsPoints', defaultValue) || [],
  getHasInventory: (zoneDetails) =>
    _.get(zoneDetails, 'zoneInfo.lotQuote.hasInventory'),
  getHourMinuteDurationSelections: (zoneDetails) =>
    _.get(
      zoneDetails,
      'parkInfo.durationSelections.hourMinuteDurationSelections'
    ) || [],
  /**
   * Parse hour and minute rates into consumable response.
   * These values come back in the duration selection as an array
   * of objects broken out by hour i.e. { hour: 1, minutes: [15, 30, 45]}
   * so we parse them into a flattened format.
   * @param {Object} zoneDetails - Zone entity response
   * @return {Array.<Object>} rates - array of rates
   * @return {Number} rates.id - timeblockid of rate (can be any consecutive timeblockid)
   * @return {String} rates.label - formatted rate value i.e. '1 hour' or '2 hours'
   * @return {String} rates.name - name of rate (hardcoded and NOT returned from api)
   * @return {Number} rates.value - rate value in minutes
   * @return {Array.<Object>} rates.minutes - array of minute options
   * @return {String} rates.minutes.label - name of minutes  (hardcoded and NOT returned from api)
   * @return {String} rates.minutes.name - name of rate (hardcoded and NOT returned from api)
   * @return {Number} rates.minutes.value - number of minutes
   */
  getHourMinuteRates: (zoneDetails) => {
    const createLabel = (amount, unit) => {
      if (amount === 1) return `${amount} ${unit}`;
      return `${amount} ${unit}s`;
    };

    const durationSelections =
      Zone.getHourMinuteDurationSelections(zoneDetails);
    const timeBlockId = Zone.getConsecutiveTimeBlockId(zoneDetails);

    return _.map(durationSelections, (durationSelection) => ({
      id: timeBlockId,
      label: createLabel(durationSelection?.hour, 'Hour'),
      minutes: _.map(durationSelection?.minutes, (minute) => ({
        label: createLabel(minute, 'Minute'),
        name: 'minute',
        value: minute,
      })),
      name: 'hour',
      value: durationSelection?.hour * 60,
    }));
  },
  /**
   * Parse hour and minute rates into consumable response.
   * These values come back in the duration selection as an array
   * of objects broken out by hour i.e. { hour: 1, minutes: [15, 30, 45]}
   * so we parse them into a flattened format.
   * @param {Object} zoneDetails - Zone entity response
   * @return {Array.<Object>} rates - array of rates
   * @return {Number} rates.id - timeblockid of rate (can be any consecutive timeblockid)
   * @return {String} rates.label - formatted rate value i.e. '1 hour, 30 mintues'
   * @return {String} rates.name - name of rate (hardcoded and NOT returned from api)
   * @return {Number} rates.value - rate value in minutes
   */
  getHourMinuteRatesOld: (zoneDetails) => {
    const calculateValue = (hours, minutes) => hours * 60 + minutes;
    const createLabel = (amount, unit, separator = '') => {
      if (amount === 0) return '';
      if (amount === 1) return `${amount} ${unit}${separator}`;
      return `${amount} ${unit}s${separator}`;
    };
    const durationSelections =
      Zone.getHourMinuteDurationSelections(zoneDetails);
    const timeBlockId = Zone.getConsecutiveTimeBlockId(zoneDetails);

    return _.flatMap(durationSelections, (selection) => {
      const hourSelection = _.get(selection, 'hour');
      const minuteSelections = _.get(selection, 'minutes');
      return _.map(minuteSelections, (minutes) => {
        const separator = minutes === 0 ? '' : ', ';
        const hoursLabel = createLabel(hourSelection, 'Hour', separator);
        const minutesLabel = createLabel(minutes, 'Minute');
        return {
          id: timeBlockId,
          label: `${hoursLabel}${minutesLabel}`,
          name: 'By the hour and the minute',
          value: calculateValue(hourSelection, minutes),
        };
      });
    });
  },
  getInternalZoneCode: (zoneDetails) =>
    zoneDetails ? _.toString(_.get(zoneDetails, 'internalZoneCode')) : null,
  getIsGarage: (zoneDetails) =>
    _.get(zoneDetails, 'typeId') === ZONE_TYPES.garage,
  getIsOnStreet: (zoneDetails) =>
    _.get(zoneDetails, 'typeId') === ZONE_TYPES.onStreet,
  getIsParkingAllowed: (zoneDetails) =>
    _.get(zoneDetails, 'parkInfo.isParkingAllowed'),
  getIsPayBySpace: (zoneDetails) => _.get(zoneDetails, 'parkInfo.isPayBySpace'),
  getIsReservation: (zoneDetails) =>
    _.get(zoneDetails, 'typeId') === ZONE_TYPES.reservation,
  getIsSpaceValidationRequired: (zoneDetails) =>
    _.get(zoneDetails, 'parkInfo.isRequireSpaceValidation'),
  getIsStartDuration: (zoneDetails) =>
    _.get(zoneDetails, 'parkInfo.isStartDuration'),
  getIsVirtualAccessCodeRequired: (zoneDetails) =>
    Zone.getAccessCodeOption(zoneDetails) === 'Virtual',
  getLatitude: (zoneDetails) =>
    _.get(zoneDetails, ['gpsPoints', 0, 'latitude']) ||
    _.get(zoneDetails, 'zoneInfo.latitude'),
  getLatLng: (zoneDetails) => ({
    latitude: Zone.getLatitude(zoneDetails),
    longitude: Zone.getLongitude(zoneDetails),
  }),
  getLocationName: (zoneDetails, defaultValue) =>
    _.get(zoneDetails, 'locationName') || defaultValue,
  getLongitude: (zoneDetails) =>
    _.get(zoneDetails, ['gpsPoints', 0, 'longitude']) ||
    _.get(zoneDetails, 'zoneInfo.longitude'),
  getMaxParkingTimeInMinutes: (zoneDetails) =>
    _.get(zoneDetails, 'parkInfo.maxParkingTime.totalMinutes'),
  getMustPrintPermit: (zoneDetails) =>
    _.findIndex(Zone.getZoneServices(zoneDetails), {
      code: 'MUST_PRINT_PERMIT',
    }) > -1,
  /**
   * `ParkingNotAllowedReason` is parameterized, so in order to
   * make an equality match using the `PARKING_ERRORS` enum
   * we return the non-parameterized error
   */
  getParkingNotAllowedError: (zoneDetails) => {
    const parkingNotAllowedReason =
      Zone.getParkingNotAllowedReason(zoneDetails);
    const values = _.values(PARKING_ERRORS);
    const error = _.find(values, (value) =>
      _.includes(parkingNotAllowedReason, value)
    );
    return error || null;
  },
  /**
   * This value is parameterized e.g. the `PAYMENT_METHOD_NOT_ACCEPTED`
   * error (examples shown below) will return the payment methods
   * accepted for a particular zone:
   * - '...Accepted payment methods are: jcb, mastercard'
   * - '...Accepted payment methods are: visa, american express'
   * so doing a string equality check is not always possible.
   * use the above `getParkingNotAllowedError` so you can
   * make equality checks based on `PARKING_ERRORS` enum
   */
  getParkingNotAllowedReason: fp.get('parkInfo.parkingNotAllowedReason'),
  getPaymentOptions: (zoneDetails) =>
    _.get(zoneDetails, ['parkInfo', 'paymentOptions']),
  /**
   * Parse predefined rates into consumable response.
   * These values only exist in the timeblock object
   * and will have a unit of either days, hours, or minutes
   * so we take the max value and convert to minutes and then
   * parse them into a consistent response
   * @param {Object} zoneDetails - Zone entity response
   * @return {Array.<Object>} rates - array of rates
   * @return {Number} rates.id - timeblockid of rate
   * @return {String} rates.label - formatted rate value
   * @return {String} rates.name - name of rate (returns from api)
   * @return {Number} rates.value - rate value in minutes
   */
  getPredefinedRates: (zoneDetails) => {
    const calculateValue = (value, unit) => {
      if (unit === 'Days') return value * 1440;
      if (unit === 'Hours') return value * 60;
      return value;
    };
    const createLabel = (value, unit) => {
      if (value > 1) return `${value} ${unit}`;
      return `${value} ${unit.slice(0, -1)}`;
    };

    const timeBlocks = Zone.getPredefinedTimeBlocks(zoneDetails);
    return _.map(timeBlocks, (timeBlock) => {
      const id = _.get(timeBlock, 'timeblockId');
      const name = _.get(timeBlock, 'name');
      const unit = _.get(timeBlock, 'timeBlockUnit');
      const value = _.get(timeBlock, 'maximumValue');
      return {
        id,
        label: createLabel(value, unit),
        name,
        value: calculateValue(value, unit),
      };
    });
  },
  getPredefinedTimeBlocks: (zoneDetails) => {
    const timeBlocks = Zone.getTimeBlocks(zoneDetails);
    return _.filter(
      timeBlocks,
      (timeBlock) =>
        _.get(timeBlock, 'timeBlockType') === TIMEBLOCK_TYPES.predefined
    );
  },
  getProductId: (zoneDetails) =>
    _.get(zoneDetails, 'zoneInfo.lotQuote.productId'),
  /**
   * @param {Object} zoneDetails - Zone entity response
   * @return {Object} rateMap - returns an object keyed by the rates
   * (i.e 'hour-minutes', 'days', or, in the case of predefined rates,
   * the name of the rate from the api). Each rate will include:
   *  - id - timeblockid (from rate)
   *  - label - name (from rate)
   *  - options - array of rates returned from the various zone rate methods
   */
  getRateOptionsMap: (zoneDetails) => {
    const hourMinuteRates = Zone.getHourMinuteRates(zoneDetails);
    const hasHourMinuteRates = !_.isEmpty(hourMinuteRates);
    const dayRates = Zone.getDayRates(zoneDetails);
    const hasDayRates = !_.isEmpty(dayRates);
    const predefinedRates = _.reduce(
      Zone.getPredefinedRates(zoneDetails),
      (result, rate) => {
        const name = rate?.name;
        return {
          ...result,
          ...{
            [_.kebabCase(name)]: {
              id: rate?.id,
              label: _.startCase(name),
              options: [rate],
            },
          },
        };
      },
      {}
    );

    // create rate map
    const rates = {
      ...(hasHourMinuteRates
        ? {
            'hour-minutes': {
              id: _.get(_.first(hourMinuteRates), 'id'),
              label: 'By the hour and the minute',
              options: hourMinuteRates,
            },
          }
        : {}),
      ...(hasDayRates
        ? {
            days: {
              id: _.get(_.first(dayRates), 'id'),
              label: 'By the day',
              options: dayRates,
            },
          }
        : {}),
      ...predefinedRates,
    };

    return rates;
  },
  /**
   * @param {Object} zoneDetails - Zone entity response
   * @return {Object} rateMap - returns an object keyed by the rates
   * (i.e 'hour-minutes', 'days', or, in the case of predefined rates,
   * the name of the rate from the api). Each rate will include:
   *  - id - timeblockid (from rate)
   *  - label - name (from rate)
   *  - options - array of rates returned from the various zone rate methods
   */
  getRateOptionsMapOld: (zoneDetails) => {
    const hourMinuteRates = Zone.getHourMinuteRatesOld(zoneDetails);
    const hasHourMinuteRates = !_.isEmpty(hourMinuteRates);
    const dayRates = Zone.getDayRates(zoneDetails);
    const hasDayRates = !_.isEmpty(dayRates);
    const predefinedRates = _.reduce(
      Zone.getPredefinedRates(zoneDetails),
      (result, rate) => {
        const name = _.get(rate, 'name');
        return {
          ...result,
          ...{
            [_.kebabCase(name)]: {
              id: _.get(rate, 'id'),
              label: _.startCase(name),
              options: [rate],
            },
          },
        };
      },
      {}
    );

    // create rate map
    const rates = {
      ...(hasHourMinuteRates
        ? {
            'hour-minutes': {
              id: _.get(_.first(hourMinuteRates), 'id'),
              label: 'By the hour and the minute',
              options: hourMinuteRates,
            },
          }
        : {}),
      ...(hasDayRates
        ? {
            days: {
              id: _.get(_.first(dayRates), 'id'),
              label: 'By the day',
              options: dayRates,
            },
          }
        : {}),
      ...predefinedRates,
    };

    return rates;
  },
  getSignageCode: (zoneDetails) => _.get(zoneDetails, 'signageCode'),
  getStartTime: (zoneDetails) => _.get(zoneDetails, 'zoneInfo.lotQuote.start'),
  getStartTimeParsed: (zoneDetails, opts = {}) => {
    const { removeOffset = true } = opts;
    const start = _.get(zoneDetails, 'zoneInfo.lotQuote.start');
    return toISO8601(start, {
      removeOffset,
    });
  },
  getState: (zoneDetails, defaultValue) =>
    _.get(zoneDetails, 'zoneInfo.state') || defaultValue,
  getStopTime: (zoneDetails) => _.get(zoneDetails, 'zoneInfo.lotQuote.stop'),
  getStopTimeParsed: (zoneDetails, opts = {}) => {
    const { removeOffset = true } = opts;
    const stop = _.get(zoneDetails, 'zoneInfo.lotQuote.stop');
    return toISO8601(stop, {
      removeOffset,
    });
  },
  getStreet: (zoneDetails, defaultValue) =>
    _.get(zoneDetails, 'zoneInfo.street') || defaultValue,
  getSupplierId: (zoneDetails) => _.get(zoneDetails, 'supplierId'),
  getSupplierName: (zoneDetails) => _.get(zoneDetails, 'supplierName'),
  getTariffInfo: (zoneDetails, defaultValue) =>
    _.get(zoneDetails, 'zoneInfo.tariffInfo', defaultValue),
  getTimeBlocks: (zoneDetails) => _.get(zoneDetails, 'parkInfo.timeBlocks'),
  getTotalCost: (zoneDetails, defaultValue) => {
    const cost = _.get(zoneDetails, 'zoneInfo.lotQuote.totalCost');
    return cost ? _.toNumber(cost).toFixed(2) : defaultValue;
  },
  getTotalMaxParkingTime: fp.get('parkInfo.totalMaxParkingTime.totalMinutes'),
  getZipCode: (zoneDetails, defaultValue) =>
    _.get(zoneDetails, 'zoneInfo.zipCode', defaultValue),
  getZoneDescription: (zoneDetails, defaultValue) =>
    _.get(zoneDetails, 'zoneInfo.description', defaultValue),
  getZoneDurationType: fp.get('parkInfo.zoneDurationType'),
  getZoneHoursOfOperation: (zoneDetails) =>
    _.get(zoneDetails, 'zoneInfo.hoursOfOperation', ''),
  getZoneServices: (zoneDetails, defaultValue) =>
    _.get(zoneDetails, 'zoneServices') || defaultValue,
  getZoneType: (zoneDetails) => _.get(zoneDetails, 'typeId'),
  hasAccessCodes: (zoneDetails) => {
    const accessCodeOption = Zone.getAccessCodeOption(zoneDetails);
    /* note: ignore accessCodeOption of 'Virtual', since the user cannot
     * currently use an access code on web for this type yet.
     */
    return accessCodeOption === 'Required' || accessCodeOption === 'Optional';
  },
  hasDuplicateParkingError: (zoneDetails) => {
    const parkingNotAllowedError = Zone.getParkingNotAllowedError(zoneDetails);
    return (
      parkingNotAllowedError === PARKING_ERRORS.DUPLICATE_PARKING ||
      parkingNotAllowedError === PARKING_ERRORS.VEHICLE_ALREADY_PARKED
    );
  },
  hasEarlyBirdConditions: (zoneDetails) =>
    _.get(zoneDetails, 'zoneInfo.earlyBird'),
  hasKnownZoneServices: (zoneDetails) => {
    const includesBy = (items, predicate) => _.findIndex(items, predicate) > -1;
    const isKnown = ({ code }) => includesBy(ZONE_SERVICES, { code });
    const services = Zone.getZoneServices(zoneDetails, []);
    return services.filter(isKnown).length > 0;
  },
  hasLotQuote: (zoneDetails) => !!_.get(zoneDetails, 'zoneInfo.lotQuote'),
  hasMaxParkingTimeError: (zoneDetails) => {
    const parkingNotAllowedError = Zone.getParkingNotAllowedError(zoneDetails);
    return parkingNotAllowedError === PARKING_ERRORS.MAXIMUM_PARKING_TIME;
  },
  hasNoParkingBlockError: (zoneDetails) => {
    const parkingNotAllowedError = Zone.getParkingNotAllowedError(zoneDetails);
    return parkingNotAllowedError === PARKING_ERRORS.NO_PARKING_BLOCK;
  },
  hasTheseServices: (zone, services) => {
    if (_.isEmpty(services)) return true;

    return _.every(services, ({ code }) =>
      _.find(Zone.getZoneServices(zone), { code })
    );
  },
  hasZoneServices: (zoneDetails) => {
    const services = Zone.getZoneServices(zoneDetails, []);
    return services.length > 0;
  },
  isAccessCodeRequired: (zoneDetails) =>
    Zone.getAccessCodeOption(zoneDetails) === 'Required',
  isAlternativePaymentMethodTypeAccepted: (zoneDetails, type) =>
    fp.compose(
      fp.includes(_.toLower(type)),
      fp.map(_.toLower),
      Zone.getAcceptedAlternativePaymentMethodTypes
    )(zoneDetails),
  isCreditCardBrandAccepted: (zoneDetails, brand) =>
    fp.compose(
      fp.includes(_.toLower(brand)),
      Zone.getAcceptedCreditCardBrands
    )(zoneDetails),
  /* Note that this access code comes from Cape and is associated with the
   * lot's product, which is different from Phonixx's concept of an access code.
   * Thus, this only applies to Reservations, not OnDemand.
   */
  isGrantedAccess: (zoneDetails) =>
    _.get(zoneDetails, 'zoneInfo.lotQuote.accessCodeUse'),
  isLoadingZone: (zoneDetails) =>
    Boolean(
      _.find(
        Zone.getZoneServices(zoneDetails),
        (service) => service?.code === 'LOADING'
      )
    ),
  isStartDurationStopWorkflow: (zoneDetails) => {
    const durationType = Zone.getZoneDurationType(zoneDetails);
    return durationType === ZONE_DURATION_TYPES.startDurationStop;
  },
  isStartDurationWorkflow: (zoneDetails) => {
    const durationType = Zone.getZoneDurationType(zoneDetails);
    return durationType === ZONE_DURATION_TYPES.startDuration;
  },
  isStartStopWorkflow: (zoneDetails) => {
    const durationType = Zone.getZoneDurationType(zoneDetails);
    return durationType === ZONE_DURATION_TYPES.startStop;
  },
  isVehicleRequired: (zoneDetails) => _.get(zoneDetails, 'isVehicleRequired'),
};

export const ZoneOptions = {
  getAccessCodeOption: fp.get('accessCodeOption'),
  getInternalZoneCode: fp.get('internalZoneCode'),
  getIsAccessCodeRequired: (zoneOptions) =>
    ZoneOptions.getAccessCodeOption(zoneOptions) === 'Accepted',
  getPayBySpace: fp.get('payBySpace'),
  getRatesBySpace: fp.get('ratesBySpace'),
  getSpaceValidationFlag: fp.get('spaceValidationFlag'),
  getSpaceValidator: fp.get('spaceValidator'),
};

// ZonePricing is used for both auth and unauth zone pricing requests.
export const ZonePricing = {
  getCultureCode: fp.get('cultureCode'),
  getCurrency: fp.get('currency'),
  getCurrencySymbol: fp.get('currencySymbol'),
  getMaxParkingTime: fp.get('maxParkingTime.totalMinutes'),
  getParkingDuration: (zonePricing) => {
    const parkingStart = ZonePricing.getParkingStartUTC(zonePricing);
    const parkingStop = ZonePricing.getParkingStopUTC(zonePricing);
    const startTime = moment.utc(parkingStart);
    const endTime = moment.utc(parkingStop);
    const diff = endTime.diff(startTime);
    const duration = moment.duration(diff);
    return duration.asMinutes();
  },
  getParkingStartLocal: fp.get('parkingStartTimeLocal'),
  getParkingStartUTC: fp.get('parkingStartTimeUtc'),
  getParkingStopLocal: fp.get('parkingStopTimeLocal'),
  getParkingStopUTC: fp.get('parkingStopTimeUtc'),
  getPrices: (zonePricing) => {
    const price = _.get(zonePricing, 'price');
    return _.isArray(price) ? price : [price];
  },
};

export const Price = {
  getMembershipTypeId: fp.get('membershipTypeId'),
  getParkingDiscount: fp.get('parkingDiscount'),
  getParkingPrice: fp.get('parkingPrice'),
  getParkingPriceFormatted: (priceInfo, opts = {}, defaultValue) => {
    const { cultureCode = 'en-US', currency = 'USD' } = opts;
    const price = Price.getParkingPrice(priceInfo);
    return price || price === 0
      ? formatCurrency({ cultureCode, currency, price })
      : defaultValue;
  },
  /**
   * total price is inclusive of discounts
   * so to get the price before discounts
   * we ADD discounts and total price
   */
  getSubtotalPrice: (priceInfo) => {
    const totalPrice = Price.getTotalPrice(priceInfo);
    const discounts = Price.getTotalDiscounts(priceInfo);
    return _.add(totalPrice, discounts);
  },
  getSubtotalPriceFormatted: (priceInfo, opts = {}, defaultValue) => {
    const { cultureCode = 'en-US', currency = 'USD' } = opts;
    const price = Price.getSubtotalPrice(priceInfo);
    return price || price === 0
      ? formatCurrency({ cultureCode, currency, price })
      : defaultValue;
  },
  getTotalDiscounts: (priceInfo) => {
    const parkingDiscount = Price.getParkingDiscount(priceInfo);
    const transactionFeeDiscount = Price.getTransactionFeeDiscount(priceInfo);
    return _.add(parkingDiscount, transactionFeeDiscount);
  },
  getTotalDiscountsFormatted: (priceInfo, opts = {}, defaultValue) => {
    const { cultureCode = 'en-US', currency = 'USD' } = opts;
    const price = Price.getTotalDiscounts(priceInfo);
    return price || price === 0
      ? formatCurrency({ cultureCode, currency, price })
      : defaultValue;
  },
  getTotalPrice: fp.get('totalPrice'),
  getTotalPriceFormatted: (priceInfo, opts = {}, defaultValue) => {
    const { cultureCode = 'en-US', currency = 'USD' } = opts;
    const price = Price.getTotalPrice(priceInfo);
    return price || price === 0
      ? formatCurrency({ cultureCode, currency, price })
      : defaultValue;
  },
  getTransactionFee: fp.get('serviceFee'),
  getTransactionFeeDiscount: fp.get('serviceFeeDiscount'),
  getTransactionFeeFormatted: (priceInfo, opts = {}, defaultValue) => {
    const { cultureCode = 'en-US', currency = 'USD' } = opts;
    const price = Price.getTransactionFee(priceInfo);
    return price || price === 0
      ? formatCurrency({ cultureCode, currency, price })
      : defaultValue;
  },
};

Zone.schemaName = 'zones';
Zone.schema = new schema.Entity(
  Zone.schemaName,
  {},
  {
    idAttribute: Zone.getInternalZoneCode,
  }
);
