import PropTypes from 'prop-types';
import React from 'react';
import h from 'react-hyperscript';
import * as styles from './tooltip-styles';

export const Tooltip = React.forwardRef((props, ref) => {
  const { className, tooltipInstance, type, ...rest } = props;
  return h(styles.Tooltip, {
    as: tooltipInstance,
    autoPosition: true,
    modifiers: { position: type },
    type,
    ...rest,
    className,
    ref,
  });
});

Tooltip.displayName = 'Tooltip';
Tooltip.propTypes = {
  className: PropTypes.string,
  tooltipInstance: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};
