const oneConfig = require('@parkmobile/one-config');
const getCookie = require('./get-cookie');

const getClientsideIsAuthenticated = () => {
  const config = oneConfig.config();
  const EASYPARK_IS_AUTHENTICATED_COOKIE =
    config.EASYPARK_IS_AUTHENTICATED_COOKIE;
  const token = getCookie(EASYPARK_IS_AUTHENTICATED_COOKIE);
  return Boolean(token);
};

module.exports = getClientsideIsAuthenticated;
