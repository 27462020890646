const _ = require('lodash');
const { useCallback } = require('react');
const { format: formatFns } = require('date-fns');
const { fr, enUS, it, sv } = require('date-fns/locale');
const { useTranslation } = require('react-i18next');

const locales = {
  en: enUS,
  fr,
  it,
  sv,
};

/**
 * Custom hook to format dates according to the current locale.
 * @returns {Function} A function to format dates.
 */
module.exports = function useFormatDateLocale() {
  const { i18n } = useTranslation();

  const format = useCallback(
    /**
     * Formats a date according to the given format string and current locale.
     * @param {Date | String | Number} date - The date to be formatted.
     * @param {String} formatStr - The format string.
     * @returns {String} - The formatted date string.
     */
    (date, formatStr) => {
      const normalizedLanguage = _.replace(i18n.language, /-.*/, '');
      const locale = locales[normalizedLanguage] || locales.en;
      return formatFns(date, formatStr, { locale });
    },
    [i18n.language]
  );

  return format;
};
