import { showIf } from '@parkmobile/ui';
import PropTypes from 'prop-types';
import React from 'react';
import h from 'react-hyperscript';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Hydrate } from 'react-query/hydration';
import { ReactQueryDevtools } from 'react-query/devtools';

const queryClientOptions = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 1000 * 60 * 15,
    },
  },
};

ReactQueryProvider.propTypes = {
  dehydratedState: PropTypes.object,
  enableDevtools: PropTypes.bool,
};

export function ReactQueryProvider({
  children,
  dehydratedState,
  enableDevtools = true,
}) {
  const queryClientRef = React.useRef();
  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient(queryClientOptions);
  }

  return h(QueryClientProvider, { client: queryClientRef.current }, [
    h(Hydrate, { state: dehydratedState }, [
      ...React.Children.toArray(children),
    ]),
    showIf(enableDevtools)(() => h(ReactQueryDevtools)),
  ]);
}
