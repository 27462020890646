const uuidv4 = require('uuid/v4');
const moment = require('moment');

module.exports = function createIcsBlob({
  description,
  domain = 'https://app.parkmobile.io',
  endDate,
  location,
  startDate,
  summary,
}) {
  const ics = `BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//ParkMobile//${domain}//EN
BEGIN:VEVENT
UID:${uuidv4()}
SUMMARY:${summary}
DESCRIPTION:${description}
LOCATION:${location}
DTSTAMP:${moment().utc().format('YYYYMMDD[T]HHmmss[Z]')}
DTSTART:${moment(startDate).utc().format('YYYYMMDD[T]HHmmss[Z]')}
DTEND:${moment(endDate).utc().format('YYYYMMDD[T]HHmmss[Z]')}
END:VEVENT
END:VCALENDAR`;

  const blob = new Blob([ics], { type: 'text/calendar' });
  return blob;
};
