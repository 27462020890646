export const reduceCurrentValues = (changes) =>
  Object.keys(changes).reduce((result, key) => {
    const isUndefined = typeof changes[key].current === 'undefined';
    const val = isUndefined ? changes[key].previous : changes[key].current;

    return {
      ...result,
      [key]: val,
    };
  }, {});
