const nextRoutes = require('next-routes');
const { ROUTES_LIST } = require('./constants');

const router = nextRoutes();

module.exports = router;

// Add all of the pathnames in the pages directory as routes
ROUTES_LIST.forEach((pathname) => {
  router.add(pathname, pathname, pathname);
});
