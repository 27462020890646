import styled, { createGlobalStyle, css, keyframes } from 'styled-components';

const backgroundFade = keyframes`
  0% {
    background-color: rgba(0, 0, 0, 0);
  }

  100% {
    background-color: ${({ theme }) => theme.getBackgroundColor('overlay')};
  }
`;

const slideUp = keyframes`
  0% {
    margin-top: 100%;
    opacity: 0;
  }

  100% {
    margin-top: 0;
    opacity: 1;
  }
`;

/* stylelint-disable order/properties-alphabetical-order, declaration-colon-newline-after */
export const ModalStyles = createGlobalStyle`
  .ReactModal__Body--open {
    overflow: hidden;
  }

 .modal__overlay {
    align-items: center;
    animation: ${backgroundFade} 300ms;
    background-color: ${({ theme }) =>
      `${theme.getBackgroundColor('overlay')} !important`};
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: ${({ theme }) => theme.zIndices.modal};
  }

  .modal__overlay__content {
    align-items: center;
    display: flex;
    flex: 1;
    height: 100%;
    justify-content: center;
    outline: none;
    width: 100%;
    ${({ theme, isFullScreen, showOverlayPaddingInFullScreen }) =>
      isFullScreen &&
      theme.applyMediaQuery('mobileSmall', {
        alignItems: 'flex-end',
        ...(showOverlayPaddingInFullScreen && {
          paddingTop: theme.getShape('topbarHeight'),
        }),
      })}
  }
`;

const RootCss = css`
  animation: ${slideUp} 300ms;
  background-color: ${({ theme }) => theme.getBackgroundColor('paper')};
  border: none;
  border-radius: ${({ theme }) => theme.getShape('borderRadius')};
  box-shadow: ${({ theme }) => theme.getShadow('default')};
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  max-height: 100%;
  max-width: ${({ width }) => width};
  outline: none;
  overflow - y: auto;
  position: relative;
  width: 100%;
  ${({ theme, isFullScreen }) =>
    isFullScreen &&
    theme.applyMediaQuery('mobileSmall', {
      maxWidth: 'none',
    })};
`;
/* stylelint-enable order/properties-alphabetical-order, declaration-colon-newline-after */

export const StyledRoot = styled.div`
  ${RootCss};
`;

export const StyledTopbar = styled.div(({ theme }) => ({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'row',
  flexShrink: 0,
  padding: theme.getSpacing(0, 2, 1, 2),
}));

export const StyledHeaderTitle = styled.div(({ theme }) => ({
  ...theme.applyTypographyStyle('h2'),
  color: theme.getTextColor('primary'),
  paddingTop: theme.getSpacing(2),
}));

export const StyledCloseButton = styled.div(({ theme }) => ({
  border: 'none',
  borderRadius: theme.radii.circular,
  cursor: 'pointer',
  flex: '0 0 auto',
  marginLeft: 'auto',
  marginRight: theme.getSpacing(-1),
  marginTop: theme.getSpacing(0.5),
  // eslint-disable-next-line sort-keys
  '&:focus': {
    backgroundColor: theme.colors.state('selected'),
    border: 'none',
    outline: 'none',
  },
}));

export const StyledContent = styled.div(({ theme, showContentPadding }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflow: 'auto',
  ...(showContentPadding && {
    padding: theme.getSpacing(2),
  }),
}));

export const StyledFooter = styled.div(({ theme }) => ({
  padding: theme.getSpacing(0, 2, 2, 2),
}));
