import oneConfig from '@parkmobile/one-config';
import _ from 'lodash';
import { createMiddleware } from 'redux-beacon';
import GoogleTagManager from '@redux-beacon/google-tag-manager';
import logger from '@redux-beacon/logger';
import { User } from '@/api/entities/users/models';
import { ANALYTICS } from './constants';

export function trackMixpanelEvent(...args) {
  // short circuit if analytics are disabled
  if (oneConfig.get('ENABLE_ANALYTICS') === false) return null;

  const shouldLog = oneConfig.get('ENABLE_LOGGING');
  if (shouldLog)
    /* eslint-disable-next-line no-console */
    console.log(
      '%cMixPanel Event:',
      'color: blue; font-weight: bold;',
      ...args
    );

  return window?.mixpanel?.track?.(...args);
}

export function identifyMixpanelUser(...args) {
  // short circuit if analytics are disabled
  if (oneConfig.get('ENABLE_ANALYTICS') === false) return null;

  const shouldLog = oneConfig.get('ENABLE_LOGGING');
  if (shouldLog)
    /* eslint-disable-next-line no-console */
    console.log(
      '%cMixPanel User:',
      'color: green; font-weight: bold;',
      ...args
    );

  return window?.mixpanel?.identify?.(...args);
}

export function createAnalyticsMiddleware() {
  const enableLogging =
    oneConfig.get('ENABLE_GTM') && oneConfig.get('ENABLE_LOGGING');
  const gtm = GoogleTagManager();
  const eventsMapper = (action) => {
    if (isTagActionLegacy(action)) {
      return getTagActionInfo(action);
    }
    return [];
  };

  const gaMiddleware = createMiddleware(
    eventsMapper,
    gtm,
    enableLogging ? { logger } : {}
  );

  return gaMiddleware;
}

export function getTagActionInfo(action) {
  return _.get(action, ['meta', ANALYTICS]);
}

export function isTagActionLegacy(action) {
  return !!getTagActionInfo(action);
}

export const trackGTMEvent = (eventDefinitionOrOptions) =>
  _.isFunction(eventDefinitionOrOptions)
    ? eventDefinitionOrOptions
    : () => eventDefinitionOrOptions;

export const withAnalytics =
  (eventDefinitionOrOptions) =>
  (actionCreatorOrType) =>
  (...args) => {
    const action = _.isFunction(actionCreatorOrType)
      ? actionCreatorOrType(...args)
      : { type: actionCreatorOrType };

    return _.merge(action, {
      meta: {
        [ANALYTICS]: [trackGTMEvent(eventDefinitionOrOptions)],
      },
    });
  };

export function resetMixpanelInstance(...args) {
  // short circuit if analytics are disabled
  if (oneConfig.get('ENABLE_ANALYTICS') === false) return null;

  const shouldLog = oneConfig.get('ENABLE_LOGGING');
  if (shouldLog)
    /* eslint-disable-next-line no-console */
    console.log(
      '%cMixPanel User:',
      'color: green; font-weight: bold;',
      ...args
    );

  return window?.mixpanel?.reset?.();
}

export function loadBrazeServiceWorker() {
  if (oneConfig.get('ENABLE_ANALYTICS') === false) return;

  if ('serviceWorker' in navigator) {
    window.addEventListener('load', async () => {
      await navigator.serviceWorker.register('/service-worker.js');
    });
  }
}

export function identifyBrazeUser(user) {
  if (!window.braze || oneConfig.get('ENABLE_ANALYTICS') === false) return;
  if (user) {
    const userId = User.getId(user);
    const email = User.getEmail(user);
    window.braze.changeUser(userId);
    window.braze.getUser().setEmail(email);
  } else {
    window.braze.wipeData();
  }
}
