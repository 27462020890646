const _ = require('lodash');
const fp = require('lodash/fp');
// currently this is the only file that SHOULD NOT use aliasing as it is imported in the server
const { areHostnamesEqual } = require('../utils');
const { FEATURE_TOGGLES } = require('./constants');

const AppConfig = _.extend(
  // Generate the feature toggle getter methods from keys
  _.reduce(
    FEATURE_TOGGLES,
    (result, value, key) => ({
      ...result,
      [`get${_.upperFirst(key)}`]: fp.get(value),
    }),
    {}
  ),
  {
    getAfterAuthenticationLink: (appConfig) => {
      const afterAuthenticationLink = _.get(
        appConfig,
        'after_authentication_href'
      );
      const homePageRedirectUrl = AppConfig.getHomePageRedirectUrl(appConfig);
      if (afterAuthenticationLink) return afterAuthenticationLink;
      if (homePageRedirectUrl) return homePageRedirectUrl;
      return '/';
    },
    getAppStoreHref: fp.get('app_store_href'),
    getDefaultLocation: fp.get('default_location'),
    getDefaultLocationName: fp.get('default_location_name'),
    getDefaultZoom: fp.get('default_zoom'),
    getEnvironments: fp.getOr([], 'environments'),
    getEventToolbarBackHref: fp.get('event_toolbar_back_href'),
    getFacebookHref: fp.get('facebook_href'),
    getFaviconLogoSrc: fp.get('favicon_logo_src'),
    getFooterAccessibilityThemeLogoSrc: fp.get(
      'footer_accessibility_theme_logo_src'
    ),
    getFooterLogoSrc: fp.get('footer_logo_src'),
    getHomePage: (appConfig) =>
      _.find(AppConfig.getPages(appConfig), {
        is_home_page: true,
      }),
    getHomePageRedirectUrl: fp.get('home_page_redirect_url'),
    getId: fp.get('id'),
    getInstagramHref: fp.get('instagram_href'),
    getLinkedinHref: fp.get('linkedin_href'),
    getLocationsPage: fp.get('locations_page'),
    getName: fp.get('name'),
    getPackageToolbarBackHref: fp.get('package_toolbar_back_href'),
    getPages: (appConfig) => _.get(appConfig, 'pages') || [],
    getPermitAccessibilityThemeLogoSrc: fp.get(
      'permit_accessibility_theme_logo_src'
    ),
    getPermitLogoSrc: fp.get('permit_logo_src'),
    getPlayStoreHref: fp.get('play_store_href'),
    getSourceAppKeyByBackup: (appConfig) => {
      const firstEnvironment = _.head(_.get(appConfig, 'environments'));
      return firstEnvironment.source_app_key;
    },
    getSourceAppKeyByHostname: (appConfig, hostname) =>
      _.get(
        _.find(AppConfig.getEnvironments(appConfig), (environment) =>
          areHostnamesEqual(environment.hostname, hostname)
        ),
        'source_app_key'
      ),
    getTheme: fp.get('theme'),
    getTopbar: fp.get('topbar'),
    getTopbarAccessibilityThemeLogoSrc: fp.get(
      'topbar_accessibility_theme_logo_src'
    ),
    getTopbarLogoSrc: fp.get('topbar_logo_src'),
    getTwitterHref: fp.get('twitter_href'),
  }
);

const Topbar = {
  getContactHref: (topbar) => _.get(topbar, 'contact_href'),
  getHelpHref: (topbar) => _.get(topbar, 'help_href'),
  getHideTopBar: (topbar) => _.get(topbar, 'hide_top_bar'),
  getLogoHref: (topbar) => _.get(topbar, 'logo_href') || '/',
  getMenuMainLinks: (topbar) => _.get(topbar, 'menu_main_links') || [],
  getPrimaryLinks: (topbar) => _.get(topbar, 'primary_links') || [],
};

module.exports = { AppConfig, Topbar };
