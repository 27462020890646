import { combineReducers } from 'redux';
import * as actions from './actions';

export const isAccountMergedModalOpen = (state = false, action) => {
  switch (action.type) {
    case actions.ACCOUNT_MERGED_MODAL_OPENED:
      return true;
    case actions.ACCOUNT_MERGED_MODAL_CLOSED:
      return false;
    default:
      return state;
  }
};

export const magicLinkUsername = (state = null, { payload, type }) => {
  if (type === actions.MAGIC_LINK_USERNAME_SET) {
    return payload;
  }

  return state;
};

export const reducer = combineReducers({
  isAccountMergedModalOpen,
  magicLinkUsername,
});
