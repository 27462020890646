export const NAME = 'areas';

export const SUPPORTED_AREA_TYPES = {
  ADMINISTRATIVE: 'Administrative',
  ON_STREET: 'OnStreet',
  SURFACE_LOT: 'SurfaceLot',
};
export const AREA_BUCKET_TYPES = {
  NORMAL_BUCKET: 'NORMAL_BUCKET',
  PERSONNEL_BUCKET: 'PERSONNEL_BUCKET',
  RESIDENTIAL_BUCKET: 'RESIDENTIAL_BUCKET',
};
