import {
  trackMixpanelEvent,
  identifyMixpanelUser,
} from '@/lib/analytics/helpers';

// LOG IN ANALYTICS

export function identifyUser({ userId }) {
  identifyMixpanelUser(userId);
}

export function trackNavigateToLogin() {
  trackMixpanelEvent('Navigate To Log In');
}

export function trackLoginAttempted({ logInMethod }) {
  trackMixpanelEvent('Log In Attempted', { log_in_method: logInMethod });
}

export function trackLoginSuccess({ logInMethod, userId }) {
  trackMixpanelEvent('Log In Success', {
    log_in_method: logInMethod,
    pm_user_id: userId,
  });
}

export function trackLoginFailure({ errorCode, logInMethod }) {
  trackMixpanelEvent('Log In Failure', {
    error_code: errorCode,
    log_in_method: logInMethod,
  });
}

/**
 *
 * @param {'login' | 'signup'} flow
 * @param {'email' | 'sms'} method
 */
export function trackSendMagicLinkAttempted({ flow, method }) {
  trackMixpanelEvent('Send Magic Link Attempted', {
    flow,
    method,
  });
}

/**
 *
 * @param {'login' | 'signup'} flow
 * @param {'email' | 'sms'} method
 */
export function trackSendMagicLinkSuccess({ flow, method }) {
  trackMixpanelEvent('Send Magic Link Success', {
    flow,
    method,
  });
}

/**
 *
 * @param {'email' | 'sms'} method
 */
export function trackResendMagicLinkAttempted({ method }) {
  trackMixpanelEvent('Resend Magic Link Attempted', { method });
}

/**
 * @param {number} errorCode
 * @param {'login' | 'signup'} flow
 * @param {'email' | 'sms'} method
 */
export function trackSendMagicLinkFailure({ errorCode, flow, method }) {
  trackMixpanelEvent('Send Magic Link Failure', {
    error_code: errorCode,
    flow,
    method,
  });
}

// SIGN UP ANALYTICS

export function trackNavigateToSignUp() {
  trackMixpanelEvent('Navigate To Sign Up');
}

export function trackSignUpAttempted({ signUpMethod }) {
  trackMixpanelEvent('Sign Up Attempted', { sign_up_method: signUpMethod });
}

export function trackSignUpSuccess({ signUpMethod, userId }) {
  trackMixpanelEvent('Sign Up Success', {
    pm_user_id: userId,
    sign_up_method: signUpMethod,
  });
}

export function trackSignUpFailure({ errorCode, signUpMethod }) {
  trackMixpanelEvent('Sign Up Failure', {
    error_code: errorCode,
    sign_up_method: signUpMethod,
  });
}

// EMAIL VERIFICATION ANALYTICS

export function trackVerifyEmailAddressSelectResend() {
  trackMixpanelEvent('Verify Email Address Select Resend');
}

export function trackVerifyEmailAddressSelectSubmitCode() {
  trackMixpanelEvent('Verify Email Address Select Submit Code');
}

export function trackVerifyEmailAddressSelectSkip() {
  trackMixpanelEvent('Verify Email Address Select Skip');
}

// FORGOT PASSWORD - REQUEST MAGIC LOGIN EMAIL

export function trackSubmitEmailForgotPasswordAttempted() {
  trackMixpanelEvent('Submit Email Forgot Password Attempted');
}

export function trackSubmitEmailForgotPasswordSuccess() {
  trackMixpanelEvent('Submit Email Forgot Password Success');
}

export function trackSubmitEmailForgotPasswordFailure({ errorCode }) {
  trackMixpanelEvent('Submit Email Forgot Password Failure', {
    'Error Code': errorCode,
  });
}

// FORGOT PASSWORD - MAGIC LINK LOGIN

export function trackMagicLinkLoginAttempted() {
  trackMixpanelEvent('Magic Link Login Attempted');
}

export function trackMagicLinkLoginSuccess() {
  trackMixpanelEvent('Magic Link Login Success');
}

export function trackMagicLinkLoginFailure({ errorCode }) {
  trackMixpanelEvent('Magic Link Login Failure', {
    'Error Code': errorCode,
  });
}

// FORGOT PASSWORD - START PARKING

export function trackStartParking() {
  trackMixpanelEvent('Start Parking From Forgot Password');
}

// FORGOT PASSWORD - CREATE NEW PASSWORD

export function trackCreateNewPasswordAttempted() {
  trackMixpanelEvent('Create New Password Attempted');
}

export function trackCreateNewPasswordSuccess() {
  trackMixpanelEvent('Create New Password Success');
}

export function trackCreateNewPasswordFailure({ errorCode }) {
  trackMixpanelEvent('Create New Password Failure', {
    'Error Code': errorCode,
  });
}
