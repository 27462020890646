module.exports = {
  theme_primary_color_dark: '#808e95',
  theme_primary_color_light: '#e2f1f8',
  theme_primary_color_main: '#b0bec5',
  theme_secondary_color_dark: '#212121',
  theme_secondary_color_light: '#484848',
  theme_secondary_color_main: '#2e2e2e',
  theme_use_alternate_footer: true,
  theme_use_alternate_menu: true,
  theme_use_alternate_topbar: true,
};
