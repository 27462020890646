const { Loader } = require('@googlemaps/js-api-loader');
const oneConfig = require('@parkmobile/one-config');

const GOOGLE_API_KEY = oneConfig.get('GOOGLE_API_KEY');

const opts = {
  apiKey: GOOGLE_API_KEY,
  libraries: ['places'],
  version: 'quarterly',
};
const loader = typeof window !== 'undefined' ? new Loader(opts) : null;

/**
 * @returns {Promise} Promise that resolves when google maps api is loaded
 */
const loadAPI = () =>
  new Promise((resolve, reject) => {
    if (!loader) {
      resolve();
    } else {
      loader
        .load()
        .then(resolve)
        .catch((e) => {
          // eslint-disable-next-line
          console.warn(e);
          reject(e);
        });
    }
  });

module.exports = loadAPI;
