module.exports = function getCookie(key) {
  // escape the cookie key where necessary
  function escape(s) {
    return s.replace(/([.*+?^$(){}|[\]/\\])/g, '\\$1');
  }

  try {
    const match = document.cookie.match(
      RegExp(`(?:^|;\\s*)${escape(key)}=([^;]*)`)
    );
    return match ? match[1] : null;
  } catch (e) {
    // silence is golden
  }

  return null;
};
