import _ from 'lodash';
import { AREA_BUCKET_TYPES, SUPPORTED_AREA_TYPES } from './constants';

export const Area = {
  getAreaName: (areaDetails) =>
    areaDetails ? _.get(areaDetails, 'areaName') : '',
  getAreaNumber: (areaDetails) =>
    areaDetails ? _.get(areaDetails, 'areaNo') : '',
  getAreaType: (areaDetails) =>
    areaDetails ? _.get(areaDetails, 'areaType') : '',
  getCity: (areaDetails) => (areaDetails ? _.get(areaDetails, 'city') : ''),
  getCountryCode: (areaDetails) =>
    areaDetails ? _.get(areaDetails, 'countryCode') : '',
  getFullCountryName: (areaIdDetails) =>
    areaIdDetails ? _.get(areaIdDetails, 'parkingArea.countryName') : '',
  getHasParkingSpotNumbers: (areaDetails) =>
    areaDetails ? _.get(areaDetails, 'hasParkingSpotNumbers') : false,
  getId: (areaDetails) =>
    areaDetails ? _.toString(_.get(areaDetails, 'id')) : null,
  getIsAreaTypeSupported: (areaDetails) =>
    Object.values(SUPPORTED_AREA_TYPES).includes(Area.getAreaType(areaDetails)),
  getIsBucketTypeParking: (areaDetails, inputInterface) =>
    _.includes(
      Area.getParkingTypesByInputInterface(areaDetails, inputInterface),
      AREA_BUCKET_TYPES.NORMAL_BUCKET
    ),
  getIsMultipleChoiceArea: (areaDetails) =>
    areaDetails ? _.get(areaDetails, 'multipleChoiceArea') : false,
  getIsParkingAllowed: (areaDetails) =>
    areaDetails ? !_.get(areaDetails, 'parkingNotAllowed') : false,
  getIsParkingTypeSupported: (
    areaDetails,
    inputInterface,
    supportedParkingTypes
  ) =>
    _.some(
      Area.getParkingTypesByInputInterface(areaDetails, inputInterface),
      (type) => supportedParkingTypes?.includes(type)
    ),
  getLatitude: (areaDetails) => _.get(areaDetails, 'displayPoint.lat'),
  getLatLng: (areaDetails) => ({
    latitude: Area.getLatitude(areaDetails),
    longitude: Area.getLongitude(areaDetails),
  }),
  getLocationName: (areaDetails, defaultValue) => {
    if (!areaDetails) {
      return defaultValue;
    }

    const city = Area.getCity(areaDetails);
    const country = Area.getCountryCode(areaDetails);

    if (city && country) {
      return `${city}, ${country}`;
    }

    return city || country || '';
  },
  getLongitude: (areaDetails) => _.get(areaDetails, 'displayPoint.lon'),
  getMultipleChoiceOptions: (areaDetails) =>
    areaDetails ? _.get(areaDetails, 'multipleChoiceDetails.options') : [],
  getParkingOperatorId: (areaDetails) =>
    _.get(areaDetails, 'parkingOperatorId'),
  getParkingTypesByInputInterface: (areaDetails, inputInterface) => {
    const defaultParkingTypes =
      _.get(areaDetails, 'parkingTypesByInputInterface.__defaults__') || [];

    const specificParkingTypes = inputInterface
      ? _.get(areaDetails, `parkingTypesByInputInterface.${inputInterface}`)
      : undefined;
    return specificParkingTypes !== undefined
      ? specificParkingTypes
      : defaultParkingTypes;
  },
  getUniqueParkingTypeArea: (areaDetails, inputInterface) => {
    const areaParkingTypes = Area.getParkingTypesByInputInterface(
      areaDetails,
      inputInterface
    );
    const hasOnlyOneParkingType = areaParkingTypes?.length === 1;
    const parkingType = hasOnlyOneParkingType
      ? _.first(areaParkingTypes)
      : null;
    return parkingType;
  },
};
