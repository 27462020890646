const handleBeforeUnload = (e) => {
  // Cancel the event
  e.preventDefault();
  // Chrome requires returnValue to be set
  e.returnValue = '';
};

const enable = () => {
  if (typeof window !== 'undefined') {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return;
  }

  // eslint-disable-next-line no-console
  console.warn(
    "Window is undefined, so can't subscribe to the beforeunload event"
  );
};

const disable = () => {
  if (typeof window !== 'undefined') {
    window.removeEventListener('beforeunload', handleBeforeUnload);
    return;
  }

  // eslint-disable-next-line no-console
  console.warn(
    "Window is undefined, so can't unsubscribe from the beforeunload event"
  );
};

module.exports = {
  disable,
  enable,
};
