import styled from 'styled-components';

export const Root = styled.div(({ modifiers, theme }) => ({
  backgroundColor: theme.getBackgroundColor('default'),
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  left: 0,
  overflow: 'hidden',
  position: 'absolute',
  right: 0,
  top: 0,
  width: '100%',
  ...(modifiers.isFluid && {
    display: 'block',
    overflow: 'visible',
    position: 'relative',
  }),
  // eslint-disable-next-line sort-keys
  '@media print': {
    display: 'block',
    position: 'relative',
  },
}));

export const Topbar = styled.div(({ modifiers, theme }) => ({
  '@media print': {
    display: 'none',
  },
  ...(modifiers.isSticky && {
    position: 'sticky',
    top: 0,
    zIndex: theme.getZIndex(2),
  }),
}));

export const NotificationCenter = styled.div(({ modifiers, theme }) => ({
  ...(modifiers.isFluid && {
    left: 0,
    position: 'fixed',
    right: 0,
    top: theme.getShape('topbarHeight'),
    zIndex: theme.getZIndex(2),
  }),
}));

export const Content = styled.div(({ modifiers, theme }) => ({
  '&:focus': {
    outline: 'none',
  },
  alignItems: 'center',
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  height: '100%',
  // eslint-disable-next-line sort-keys
  '@media print': {
    display: 'block',
  },
  ...(modifiers.fillHeight && {
    minHeight: `calc(100vh - ${theme.getShape('topbarHeight')})`,
  }),
  ...(modifiers.clipContent && {
    overflowX: 'hidden',
    overflowY: 'hidden',
  }),
}));

export const Footer = styled.div(({ theme, modifiers = {} }) => ({
  '@media print': {
    display: 'none',
  },
  ...(modifiers.condensed && {
    [theme.breakpoints.below('md')]: {
      display: 'none',
    },
  }),
}));
