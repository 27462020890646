import {
  Box,
  Button,
  FormControl,
  Input,
  Modal,
  ModalHeader,
  Notification,
  Space,
  Spinner,
  Text,
} from '@parkmobile/ui';
import PropTypes from 'prop-types';
import React from 'react';
import * as authServiceAPI from '@/api/services/authentication/api';
import { useWelcomeBackModal } from './welcome-back-modal-hooks';

WelcomeBackModal.propTypes = {
  apis: PropTypes.shape({
    authentication: PropTypes.object,
  }),
};

WelcomeBackModal.defaultProps = {
  apis: {
    authentication: authServiceAPI,
  },
};

export function WelcomeBackModal({ apis }) {
  const [data, handlers] = useWelcomeBackModal({ apis });

  const {
    confirmPasswordError,
    createNewPasswordRequestError,
    isCreatingNewPassword,
    isMagicLoginEnabled,
    isOpen,
    passwordCaption,
    passwordError,
    uiState,
    UI_STATES,
  } = data;

  const {
    onCancelCreatePassword,
    onClose,
    onCreateNewPasswordClicked,
    onResendLoginEmailClicked,
    onStartParking,
    onSubmitNewPassword,
    registerFields,
  } = handlers;

  const loadingContent = (
    <Box>
      <Space height='xl' />
      <Box alignItems='center' display='flex' justifyContent='center'>
        <Spinner />
      </Box>
      <Space height='xl' />
    </Box>
  );

  const errorLoggingInContent = (
    <Box>
      <Space height='md' />
      <Notification variant='error'>
        <Text variant='caption'>
          <b>We couldn&apos;t log you in.</b>
        </Text>
        <Space height='md' />
        <Text variant='caption'>
          Please try again by requesting a new login email.
        </Text>
      </Notification>
      <Space height='xl' />
      <Button
        color='neutral'
        data-pmtest-id='request-forgot-password-button'
        fullWidth={true}
        onClick={onResendLoginEmailClicked}
      >
        Request another login email
      </Button>
    </Box>
  );

  const welcomeBackContent = (
    <Box>
      <Space height='xl' />
      <Text>
        {isMagicLoginEnabled
          ? 'Ready to park?'
          : 'You can start parking, or create a new password for your account.'}
      </Text>
      <Space height='xl' />
      <Box display='grid' gridGap='md' gridTemplateColumns='1fr'>
        <Button
          color='neutral'
          data-pmtest-id='close-welcome-modal-button'
          onClick={onStartParking}
        >
          Start Parking
        </Button>
        {!isMagicLoginEnabled && (
          <Button
            color='brand'
            data-pmtest-id='change-password-button'
            onClick={onCreateNewPasswordClicked}
            variant='text'
          >
            Create New Password
          </Button>
        )}
      </Box>
    </Box>
  );

  const createNewPasswordContent = (
    <Box
      as='form'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      maxWidth='400px'
      onSubmit={onSubmitNewPassword}
      width='100%'
    >
      <Space height='xl' />
      {createNewPasswordRequestError && (
        <>
          <Notification dismissable={false} variant='error'>
            <Text variant='caption'>{createNewPasswordRequestError}</Text>
          </Notification>
          <Space height='xl' />
        </>
      )}
      <FormControl
        caption={passwordCaption}
        error={passwordError}
        id='password'
        label='New Password'
      >
        <Input
          data-pmtest-id='password-input'
          fullWidth={true}
          id='password'
          label='New Password'
          name='password'
          ref={registerFields.password()}
          required={true}
          type='password'
        />
      </FormControl>
      <Space height='lg' />
      <FormControl
        error={confirmPasswordError}
        id='confirmPassword'
        label='Confirm Password'
      >
        <Input
          data-pmtest-id='confirm-password-input'
          fullWidth={true}
          id='confirmPassword'
          label='Confirm Password'
          name='confirmPassword'
          ref={registerFields.confirmPassword()}
          required={true}
          type='password'
        />
      </FormControl>
      <Space height='lg' />
      <Box display='flex' justifyContent='flex-end'>
        <Button
          color='neutral'
          data-pmtest-id='cancel-create-new-password-button'
          onClick={onCancelCreatePassword}
          variant='secondary'
        >
          Cancel
        </Button>
        <Space width='md' />
        <Button
          color='neutral'
          data-pmtest-id='create-new-password-submit-button'
          loading={isCreatingNewPassword}
          onClick={onSubmitNewPassword}
        >
          Save
        </Button>
      </Box>
    </Box>
  );

  const uiStateContentMap = {
    [UI_STATES.errorLoggingIn]: {
      content: errorLoggingInContent,
      title: 'Logging You In...',
    },
    [UI_STATES.loading]: {
      content: loadingContent,
      title: 'Logging You In...',
    },
    [UI_STATES.welcomeBack]: {
      content: welcomeBackContent,
      title: 'Welcome Back!',
    },
    [UI_STATES.createNewPassword]: {
      content: createNewPasswordContent,
      title: 'Create New Password',
    },
  };

  return (
    <Modal
      id='welcome-back-modal'
      isFullScreen={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalHeader>
        <Text variant='h1'>{uiStateContentMap?.[uiState].title}</Text>
      </ModalHeader>
      {/* Not using ModalBody because it currently adds unnecessary padding beneath content */}
      {uiStateContentMap?.[uiState].content}
    </Modal>
  );
}
