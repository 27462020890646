export const NAME = 'parkings';

// Please use this as a reference to understand what interface type is:
// https://easypark.jira.com/wiki/spaces/DP/pages/2558623761/Parkings
export const INPUT_INTERFACE_TYPES = Object.freeze({
  B2C_WEB: 'B2C_WEB',
  B2C_WEB_COST_NEUTRAL: 'B2C_WEB_COST_NEUTRAL',
  MPP_WEB_subextpark: 'MPP_WEB_subextpark',
});

export const PARKING_SUB_TYPES = Object.freeze({
  ANPR_TIME: 'ANPR_TIME',
  EVC_TIME: 'EVC_TIME',
  EXT_PRE_BOOKING: 'EXT_PRE_BOOKING',
  EXT_TIME: 'EXT_TIME',
  NORMAL_BUCKET: 'NORMAL_BUCKET',
  NORMAL_TIME: 'NORMAL_TIME',
  NORMAL_TOLLING: 'NORMAL_TOLLING',
  PERSONNEL_BUCKET: 'PERSONNEL_BUCKET',
  PERSONNEL_TIME: 'PERSONNEL_TIME',
  PRE_BOOKING: 'PRE_BOOKING',
  RESIDENTIAL_BUCKET: 'RESIDENTIAL_BUCKET',
  RESIDENTIAL_TIME: 'RESIDENTIAL_TIME',
});

// Human-readable values labels
export const PARKING_SUB_TYPES_HUMAN_READABLE = {
  ANPR_TIME: 'ANPR Time',
  EVC_TIME: 'EVC Time',
  EXT_PRE_BOOKING: 'External Pre Booking',
  EXT_TIME: 'External TIME',
  NORMAL_BUCKET: 'Normal Bucket',
  NORMAL_TIME: 'Normal Time',
  NORMAL_TOLLING: 'Normal Tolling',
  PERSONNEL_BUCKET: 'Personnel Bucket',
  PERSONNEL_TIME: 'Personnel Time',
  PRE_BOOKING: 'Pre-Booking',
  RESIDENTIAL_BUCKET: 'Residential Bucket',
  RESIDENTIAL_TIME: 'Residential Time',
};
export const RESIDENTIAL_PARKING_SUB_TYPES = [
  'RESIDENTIAL_BUCKET',
  'RESIDENTIAL_TIME',
];
export const NON_STOP_EXTEND_PARKING_SUB_TYPES = Object.freeze([
  PARKING_SUB_TYPES.NORMAL_BUCKET,
  PARKING_SUB_TYPES.PERSONNEL_BUCKET,
  PARKING_SUB_TYPES.RESIDENTIAL_BUCKET,
  PARKING_SUB_TYPES.ANPR_TIME,
  PARKING_SUB_TYPES.EXT_PRE_BOOKING,
  PARKING_SUB_TYPES.NORMAL_TOLLING,
]);
