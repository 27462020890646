/**
 * Return an array of list items in friendly text format
 * @param {string[]} list - An array of strings containing list items to join
 * @param {string} [separator=,] - string to use as separator
 * @param {string} [conjuction=and] - string to use as conjuction between last and second last value
 * @return {string} A string joined by separators and a conjunction (eg., 'dogs, cats, and horses')
 */
module.exports = function humanizeList(
  list,
  separator = ',',
  conjunction = 'and'
) {
  if (!list || list.length === 0) return '';

  return list.length > 2
    ? list
        .join(`${separator} `)
        .replace(
          new RegExp(`${separator} ([^${separator}]*)$`),
          `${separator} ${conjunction} $1`
        )
    : list.join(` ${conjunction} `);
};
