export const NAME = 'api';

// 15 minute cache
export const CACHE_MAX_AGE = 1000 * 60 * 15;

// 1000 items in cache
export const CACHE_MAX_SIZE = 1000;

export const DEFAULT_ERROR_MESSAGE = 'Oops! Something went wrong.';

export const FAILURE = `${NAME}/FAILURE`;

export const REQUEST = `${NAME}/REQUEST`;

export const SUCCESS = `${NAME}/SUCCESS`;

export const XSRF_COOKIE_NAME = 'XSRF-TOKEN';

export const XSRF_HEADER_NAME = 'X-XSRF-TOKEN';
