import { getAppConfig, getUseAccessibilityTheme } from '@/lib/shared/store';
import { parseThemeSettings } from '@/lib/shared/helpers';
import { AppConfig } from '@/lib/shared/models';
import _ from 'lodash';
import Head from 'next/head';
import oneConfig from '@parkmobile/one-config';
import PropTypes from 'prop-types';
import React from 'react';
import h from 'react-hyperscript';
import { connect } from 'react-redux';
import { showIf, hideIf } from 'react-render-helpers';
import { ThemeProvider } from 'styled-components';
import { createTheme } from '../../../../styles';
import fallbackThemeSettings from '../../../../styles/themes/default';
import adaThemeSettings from '../../../../styles/themes/pm-ada';

class StylesComponent extends React.PureComponent {
  static propTypes = {
    appConfig: PropTypes.object,
    children: PropTypes.node,
    useAccessibilityTheme: PropTypes.bool,
  };

  state = {
    mounted: false,
  };

  componentDidMount() {
    this.setState(() => ({ mounted: true }));
  }

  render() {
    const { appConfig, children, useAccessibilityTheme } = this.props;

    const appThemeSettings = AppConfig.getTheme(appConfig);
    const appThemeExists = Boolean(appThemeSettings);

    // get appropriate js theme
    const themeSettings = useAccessibilityTheme
      ? adaThemeSettings
      : appThemeExists
      ? appThemeSettings
      : fallbackThemeSettings;
    const parsedThemeSettings = parseThemeSettings(themeSettings);
    const theme = createTheme(parsedThemeSettings);

    // get appropriate css filepath
    const adaCssFilename = 'pm-ada.min.css';
    const appCssFilename = `${oneConfig.get('APP_ID')}.min.css`;
    const fallbackCssFilename = `${oneConfig.get('FALLBACK_THEME_ID')}.min.css`;
    const manifest = oneConfig.get('MANIFEST');

    return h(React.Fragment, {}, [
      h(
        Head,
        {
          key: 'styles-provider-head',
        },
        [
          /**
           * Head Component does not accept nested children so each element
           * must have its own conditional
           */
          h('link', {
            href: 'https://use.typekit.net/ajt2xhb.css',
            rel: 'stylesheet',
          }),
          h('meta', {
            content: theme.getPaletteColor('primary'),
            name: 'theme-color',
          }),
          showIf(appThemeExists)(() =>
            h('link', {
              href: `/public/static/css/${_.get(manifest, [appCssFilename])}`,
              rel: 'stylesheet',
            })
          ),
          hideIf(appThemeExists)(() =>
            h('link', {
              href: `/public/static/css/${_.get(manifest, [
                fallbackCssFilename,
              ])}`,
              rel: 'stylesheet',
            })
          ),
          showIf(useAccessibilityTheme)(() =>
            h('link', {
              href: `/public/static/css/${_.get(manifest, [adaCssFilename])}`,
              rel: 'stylesheet',
            })
          ),
        ]
      ),
      h(
        ThemeProvider,
        {
          theme,
        },
        [React.Children.only(children)]
      ),
    ]);
  }
}

export const StylesProvider = connect((state) => ({
  appConfig: getAppConfig(state),
  useAccessibilityTheme: getUseAccessibilityTheme(state),
}))(StylesComponent);
