import {
  getLatestRequestByTag,
  getLatestUnhandledErrorByTag,
} from '@/lib/api/store';
import _ from 'lodash';
import * as actions from './actions';
import { NAME } from '../constants';

export const getAllFlags = (state) => _.get(state, [NAME, 'flags']);

export const getBootstrap = (state) => _.get(state, [NAME, 'bootstrap']);

export const getUser = (state) => _.get(state, [NAME, 'user']);

export const getUserKey = (state) => _.get(state, [NAME, 'user', 'key']);

export const getUserHash = (state) => _.get(state, [NAME, 'userHash']);

export const getUpdateFlagsRequest = (state) =>
  getLatestRequestByTag(state, actions.UPDATE_FLAGS_REQUEST_STARTED);

export const getUpdateFlagsRequestError = (state) =>
  getLatestUnhandledErrorByTag(state, actions.UPDATE_FLAGS_REQUEST_STARTED);
