import _ from 'lodash';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { User } from '@/api/entities/users/models';
import { accountRequestStarted, getUser } from '@/api/entities/users/store';
import { FeatureFlags } from '@/lib/feature-flags/models';
import { getAllFlags } from '@/lib/feature-flags/store';
import { getLink } from '@/lib/routing/helpers';
import { humanizeList } from '@/lib/utils';

import {
  trackCreateNewPasswordAttempted,
  trackCreateNewPasswordFailure,
  trackCreateNewPasswordSuccess,
  trackMagicLinkLoginAttempted,
  trackMagicLinkLoginFailure,
  trackMagicLinkLoginSuccess,
  trackStartParking,
} from '@/views/authentication/analytics';

export function useWelcomeBackModal({ apis }) {
  const flags = useSelector(getAllFlags);

  // ---------------- BASIC MODAL FUNCTIONALITY  ----------------

  const UI_STATES = {
    createNewPassword: 'createNewPassword',
    errorLoggingIn: 'errorLoggingIn',
    loading: 'loading',
    welcomeBack: 'welcomeBack',
  };

  const [uiState, setUiState] = useState(UI_STATES.loading);
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);

  // ---------------- MAGIC LINK ----------------

  const dispatch = useDispatch();
  const router = useRouter();
  const magicLinkToken = router?.query?.login_token;

  const {
    isLoading: isLoggingIn,
    isSuccess: hasLoggedIn,
    mutateAsync: loginWithMagicLink,
  } = useMutation(apis.authentication.loginWithMagicLink, {
    onError: (error) => {
      trackMagicLinkLoginFailure({ errorCode: error?.statusCode });
      setUiState(UI_STATES.errorLoggingIn);
    },
    onMutate: () => {
      trackMagicLinkLoginAttempted();
    },
    onSuccess: () => {
      trackMagicLinkLoginSuccess();
      dispatch(accountRequestStarted);
      setUiState(UI_STATES.welcomeBack);
    },
  });

  // If the user has a magic link token, attempt to login with it and show the modal
  useEffect(() => {
    if (magicLinkToken) {
      loginWithMagicLink({ magicLinkToken });
      setIsOpen(true);
    }
  }, [loginWithMagicLink, magicLinkToken]);

  const isMagicLoginEnabled = FeatureFlags.isMagicLoginEnabled(flags);
  const onResendLoginEmailClicked = () => {
    router.push(
      ..._.values(getLink(isMagicLoginEnabled ? '/login' : '/forgot-password'))
    );
    onClose();
  };

  // ---------------- START PARKING ----------------

  const onStartParking = () => {
    trackStartParking();
    onClose();
  };

  // ---------------- CREATE NEW PASSWORD ----------------

  const onCreateNewPasswordClicked = () => {
    setUiState(UI_STATES.createNewPassword);
  };

  const {
    errors: createNewPasswordFormErrors,
    getValues: getFieldValue,
    handleSubmit,
    register: registerField,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const isComplexPasswordValidationEnabled =
    FeatureFlags.isComplexPasswordValidationEnabled(flags);

  // Links and text
  const passwordRequirements = isComplexPasswordValidationEnabled
    ? humanizeList([
        '8-25 characters',
        '1 lowercase letter',
        '1 uppercase letter',
        '1 number',
        '1 of the following special characters !"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~',
      ])
    : humanizeList(['8-25 characters']);
  const passwordCaption = `Your password must contain ${passwordRequirements}`;

  const validatePassword = (value = '') => {
    // handle invalid characters separately
    if (!/^[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]*$/.test(value)) {
      return 'Your password can only contain letters, numbers, and the following special characters !"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~';
    }

    const simplePasswordRequirementErrors = [
      _.inRange(value.length, 8, 26) || '8-25 characters',
    ].filter(_.isString);
    const complexPasswordRequirementErrors = [
      _.inRange(value.length, 8, 26) || '8-25 characters',
      /[a-z]/.test(value) || '1 lowercase letter',
      /[A-Z]/.test(value) || '1 uppercase letter',
      /[0-9]/.test(value) || '1 number',
      /[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/.test(value) ||
        '1 of the following special characters !"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~',
    ].filter(_.isString);
    const passwordErrors = isComplexPasswordValidationEnabled
      ? complexPasswordRequirementErrors
      : simplePasswordRequirementErrors;

    const prefix =
      passwordErrors.length === 5
        ? 'Your password must contain'
        : 'Your password needs';
    return (
      passwordErrors.length === 0 || `${prefix} ${humanizeList(passwordErrors)}`
    );
  };

  const registerFields = {
    confirmPassword: () =>
      registerField({
        required: 'Confirm password is required',
        validate: (value = '') => {
          if (getFieldValue('password') !== value) {
            return 'Both password fields must match';
          }
          return true;
        },
      }),
    password: () =>
      registerField({
        required: 'Password is required',
        validate: validatePassword,
      }),
  };

  const {
    mutateAsync: createNewPassword,
    error: createNewPasswordError,
    isLoading: isCreatingNewPassword,
  } = useMutation(apis.authentication.updatePasswordWithMagicToken, {
    onError: (error) => {
      trackCreateNewPasswordFailure({ errorCode: error?.statusCode });
    },
    onSuccess: () => {
      trackCreateNewPasswordSuccess();
      onClose();
    },
  });

  const createNewPasswordErrorMessage = createNewPasswordError?.data.message;
  const isCommonPasswordError =
    createNewPasswordErrorMessage === 'Password does not meet the requirements';
  const createNewPasswordRequestError = isCommonPasswordError
    ? 'This password is too common or on a list of exposed passwords. Please choose a stronger password.'
    : createNewPasswordErrorMessage;

  /* Since the user's refresh token becomes invalid after changing their password,
   * and because the magic token is one-time use, we must log the user in again
   * with their email/password combo.
   */
  const user = useSelector(getUser);
  const email = User.getEmail(user);
  const onSubmitNewPassword = handleSubmit(({ password } = {}) => {
    trackCreateNewPasswordAttempted();
    createNewPassword({ email, newPassword: password, token: magicLinkToken });
  });

  const onCancelCreatePassword = () => {
    setUiState(UI_STATES.welcomeBack);
  };

  return [
    {
      confirmPasswordError:
        createNewPasswordFormErrors?.confirmPassword?.message,
      createNewPasswordRequestError,
      hasLoggedIn,
      isCreatingNewPassword,
      isLoggingIn,
      isMagicLoginEnabled,
      isOpen,
      passwordCaption,
      passwordError: createNewPasswordFormErrors?.password?.message,
      UI_STATES,
      uiState,
    },
    {
      onCancelCreatePassword,
      onClose,
      onCreateNewPasswordClicked,
      onResendLoginEmailClicked,
      onStartParking,
      onSubmitNewPassword,
      registerFields,
    },
  ];
}
