import styled from 'styled-components';

export const LogoLink = styled.div(({ theme, modifiers }) => {
  const focusedColor = theme.colors.state('focused', {
    on: theme.settings.useAlternateTopbar ? 'surface' : 'brand2',
    stroke: theme.settings.useAlternateTopbar ? 'onSurface' : 'onBrand2',
  });

  return {
    color: 'currentColor',
    display: 'flex',
    height: theme.sizes.componentHeight,
    overflow: 'hidden',
    padding: theme.space.custom(0, 0.5),
    // eslint-disable-next-line sort-keys
    '&:focus': {
      borderRadius: theme.radii.sm,
      boxShadow: modifiers.focusVisible ? `0 0 0 2px ${focusedColor}` : 'none',
      outline: 'none',
      textDecoration: 'none',
    },
    // eslint-disable-next-line sort-keys
    img: {
      height: '100%',
      minWidth: '100%',
      objectFit: 'contain',
      objectPosition: '0 50%',
    },
  };
});

export const ActionContent = styled.div(({ modifiers }) => ({
  '&::before': {
    background: 'currentColor',
    bottom: '-5px',
    content: '""',
    height: '3px',
    left: '0',
    opacity: '0',
    position: 'absolute',
    transition: 'all 0.2s ease-in-out',
    width: '0',
  },
  '&:focus': {
    '&::before': {
      opacity: modifiers.focusVisible ? 1 : 0,
      width: modifiers.focusVisible ? '100%' : 0,
    },
  },
  '&:hover': {
    '&::before': {
      opacity: 1,
      width: '100%',
    },
  },
  alignItems: 'center',
  color: 'currentColor',
  display: 'flex',
  flex: '0 0 auto',
  maxWidth: '100%',
  minHeight: '16px',
  outline: 'none',
  position: 'relative',
}));

export const ToggleButton = styled.div(({ theme, modifiers }) => ({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  flex: '0 0 auto',
  height: '40px',
  justifyContent: 'center',
  position: 'relative',
  userSelect: 'none',
  width: '48px',
  // eslint-disable-next-line sort-keys
  '&:focus': {
    '&::before': {
      borderRadius: theme.radii.sm,
      boxShadow: '0 0 0 2px currentColor',
      content: '""',
      height: '100%',
      opacity: modifiers.focusVisible ? theme.colors.stateOpacities.focused : 0,
      pointerEvents: 'none',
      position: 'absolute',
      width: '100%',
    },
    outline: 'none',
    textDecoration: 'none',
  },
  '&:hover': {
    '&::after': {
      backgroundColor: 'currentColor',
      borderRadius: theme.radii.sm,
      content: '""',
      height: '100%',
      opacity: theme.colors.stateOpacities.hovered,
      pointerEvents: 'none',
      position: 'absolute',
      width: '100%',
    },
  },
}));

export const ToggleButtonIcon = styled.div(({ theme, modifiers }) => ({
  height: '20px',
  position: 'relative',
  width: '32px',
  // eslint-disable-next-line sort-keys
  span: {
    backgroundColor: 'currentColor',
    borderRadius: '4px',
    height: '2px',
    left: 0,
    position: 'absolute',
    transition: theme.transitions.create('all'),
    width: '100%',
  },
  'span:first-child': {
    top: 0,
    ...(modifiers.open && {
      left: '50%',
      top: '9px',
      width: 0,
    }),
  },
  'span:nth-child(2)': {
    top: '9px',
    transform: modifiers.open ? 'rotate(45deg)' : 'rotate(0deg)',
  },
  'span:nth-child(3)': {
    top: '9px',
    transform: modifiers.open ? 'rotate(-45deg)' : 'rotate(0deg)',
  },
  'span:nth-child(4)': {
    bottom: 0,
    ...(modifiers.open && {
      bottom: '9px',
      left: '50%',
      width: 0,
    }),
  },
}));

export const Scrim = styled.div(({ modifiers, theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.25)',
  bottom: '0',
  height: '100%',
  left: '0',
  opacity: '0',
  position: 'fixed',
  right: '0',
  top: '0',
  transition: 'visibility 0s 0.3s, opacity 0.3s linear',
  visibility: 'hidden',
  width: '100%',
  zIndex: theme.zIndices.drawer,
  ...(modifiers.visible && {
    opacity: '1',
    transition: 'opacity 0.3s linear',
    visibility: 'visible',
  }),
}));

export const Drawer = styled.div(({ theme, modifiers }) => ({
  backgroundColor: theme.settings.useAlternateMenu
    ? theme.colors.surface
    : theme.colors.brand2,
  bottom: '0',
  color: theme.settings.useAlternateMenu
    ? theme.colors.onSurface.primary
    : theme.colors.onBrand2.primary,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  position: 'fixed',
  right: '0',
  top: '0',
  transform: 'translateX(300px)',
  transition: theme.transitions.create('all'),
  width: '300px',
  zIndex: theme.zIndices.drawer,
  ...(modifiers.visible && {
    boxShadow: theme.shadows.xl,
    transform: 'translateX(0)',
  }),
}));

export const DrawerAccount = styled.div(({ theme }) => ({
  alignItems: 'center',
  color: 'inherit',
  display: 'flex',
  justifyContent: 'space-between',
  minHeight: theme.sizes.appbarHeight,
  paddingLeft: theme.space.lg,
  paddingRight: theme.space.sm,
}));

export const DrawerAccountTitle = styled.div(() => ({
  textOverflow: 'ellipsis',
}));
