const resolvePromise = require('./resolve-promise');
/**
 * Resolves and wraps Promises in objects with data, error, and ok
 * @param  {obj} object an object with Promises as values
 * @return {Promise}    an object with keys from passed-in object
 *                      and values in { ok, data, error } format.
 */
module.exports = function resolveAllPromises(obj) {
  const keys = Object.keys(obj);
  const promises = keys.map((key) => resolvePromise(obj[key]));

  const handleSuccess = (results) =>
    keys.reduce(
      (result, key, i) => ({
        ...result,
        [key]: results[i],
      }),
      {}
    );

  return Promise.all(promises).then(handleSuccess);
};
