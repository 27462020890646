import oneConfig from '@parkmobile/one-config';
import { eventChannel } from 'redux-saga';
import { all, fork, call, take } from 'redux-saga/effects';
import { sagaRegistry } from './saga-registry';

/*
 * A factory function to create a channel that will emit whenever a
 * new saga is registered to the SagaRegistry.
 */
const createRegisterChannel = () =>
  eventChannel((emitter) => {
    const unsubscribe = sagaRegistry.setChangeListener(emitter);
    return unsubscribe;
  });

/*
 * An injector saga that dynamically forks new sagas as they are registered.
 *
 * NOTE: Per Redux Saga docs: "You can understand how attached forks behave
 * by simply considering it as a dynamic parallel Effect." Essentially, this
 * means that we are dynamically appending parallel effects to our yield all() call
 * executed in main*.
 *
 * NOTE: browserOnlyInjector is not needed on the server. See comments for configureSagas below.
 */
function* browserOnlyInjector() {
  const registerChannel = yield call(createRegisterChannel);
  /* eslint-disable no-constant-condition, no-console, fp/no-loops */
  while (true) {
    const nextSaga = yield take(registerChannel);

    yield fork(nextSaga);

    if (oneConfig.get('ENABLE_LOGGING')) {
      console.log(`Started saga: "${sagaRegistry.getName(nextSaga)}"`);
    }
  }
  /* eslint-enable */
}

/*
 * This function runs the provided sagas in parallel and configures
 * the browserOnlyInjector to fork any newly registered sagas dynamically.
 *
 * NOTE: We don't want to run the browserOnlyInjector on the server because
 * all sagas will be registered before the store is created and therefore before
 * configureSagas is called. This means that the inputSagas argument will already
 * contain a list of all the sagas needed for that request.
 */
export const configureSagas = (inputSagas = []) => {
  function* main() {
    // On the client we should also run the browserOnlyInjector saga
    const isServer = typeof window === 'undefined';
    const sagas = isServer
      ? inputSagas
      : inputSagas.concat(browserOnlyInjector);

    // Create a list of call effects for sagas
    const effects = sagas.map((saga) => call(saga));

    // Start all of the effects in parallel
    yield all(effects);

    // Log the sagas that were started
    if (oneConfig.get('ENABLE_LOGGING')) {
      sagas.forEach((saga) => {
        // eslint-disable-next-line no-console
        console.log(`Started saga: "${sagaRegistry.getName(saga)}"`);
      });
    }
  }

  return main;
};
