import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import icons from './icons';
import { StyledRoot, StyledContent } from './icon-styles';

const sizes = ['auto', 'x-large', 'large', 'medium', 'small'];
const variants = [
  'black',
  'white',
  'default',
  'full-color',
  'primary-text',
  'hint-text',
  'primary',
  'primary-dark',
  'secondary',
  'warning',
  'error',
];

export class Icon extends React.PureComponent {
  static propTypes = {
    ariaLabel: PropTypes.string,
    className: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    iconName: PropTypes.string,
    inheritColor: PropTypes.bool,
    onClick: PropTypes.func,
    size: PropTypes.oneOf(sizes),
    variant: PropTypes.oneOf(variants),
  };

  static defaultProps = {
    size: 'small',
    variant: 'default',
  };

  render() {
    const {
      ariaLabel,
      className,
      color,
      disabled,
      iconName = '',
      inheritColor,
      onClick,
      size,
      variant,
      ...rest
    } = this.props;

    return (
      <StyledRoot
        {...rest}
        {...(ariaLabel ? { 'aria-label': ariaLabel } : {})}
        className={className}
        modifiers={{
          auto: size === 'auto',
          color,
        }}
        onClick={onClick}
        role={onClick ? 'button' : null}
        style={{
          cursor: onClick ? 'pointer' : 'inherit',
        }}
      >
        <StyledContent
          dangerouslySetInnerHTML={{
            __html: icons[_.kebabCase(iconName)],
          }}
          modifiers={{
            color,
            disabled,
            inheritColor,
            size,
            variant,
          }}
        />
      </StyledRoot>
    );
  }
}
