import _ from 'lodash';
import get from 'lodash/fp/get';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { NAME } from '../constants';
import { indexIdsByField } from '../helpers';

export const getRootState = get([NAME]);

/**
 * Creates a selector that returns stored information for a given resource
 * @param  {Function} resourceSelector A selector that returns the resource given data state
 * @return {Function}             A selector that receives state and returns a resource
 */
export const resourceSelectorFactory = (resourceSelector) =>
  createSelector(getRootState, resourceSelector);

/**
 * Creates a selector that maps all entities for a resource into an array
 */
export const allAsListSelectorFactory = (resourceSelector) =>
  createSelector(resourceSelector, (resourceState) =>
    _.get(resourceState, 'allIds', []).map((id) =>
      _.get(resourceState, ['byId', id])
    )
  );

/**
 * Creates a selector that returns all ids of a resource.
 */
export const allIdsAsListSelectorFactory = (resourceSelector) =>
  createSelector(resourceSelector, (resourceState) =>
    _.get(resourceState, 'allIds', [])
  );

/**
 * Creates a selector that returns an object with resource entities keyed by ID
 */
export const byIdSelectorFactory = (resourceSelector) =>
  createSelector(resourceSelector, (resourceState) =>
    _.get(resourceState, 'byId', {})
  );

/**
 * Creates a selector that returns an object with resource entities keyed by a field
 */
export const byFieldSelectorFactory = (byIdSelector, fieldGetter) =>
  createSelector(byIdSelector, indexIdsByField(fieldGetter));

/**
 * Creates a selector that finds a resource entity with a given field value
 */
export const findByFieldSelectorFactory =
  (byIdSelector, byFieldSelector) => (state, field) => {
    const byId = byIdSelector(state);
    const byField = byFieldSelector(state);
    const id = _.get(byField, field);
    return _.get(byId, id);
  };

/**
 * Creates a selector that finds a resource entity with a given id
 */
export const findByIdSelectorFactory = (resourceSelector) => (state, id) => {
  const resourceState = resourceSelector(state);
  return _.get(resourceState, ['byId', id]);
};

/**
 * Creates a selector that finds all resource entities by a given tag. Tags
 * are used to associate a list of resources with a specific API call. For example,
 * search results can be stored under a tag.
 */
export const findByTagSelectorFactory = (resourceSelector) =>
  createCachedSelector(
    resourceSelector,
    (state, tag) => tag,
    (resourceState, tag) =>
      _.map(_.get(resourceState, ['byTag', tag], []), (id) =>
        _.get(resourceState, ['byId', id])
      )
  )(
    /* Each ByTag selector will be cached separately from each other, based on the resourceSelector.
  ByTag selectors of the same resourceSelector will be cached based on tag. */
    (resourceState, tag) => tag
  );
