import React from 'react';
import {
  AuthenticationProvider,
  ErrorView,
  Layout,
  PrismicToolbar,
  ReactQueryProvider,
  StylesProvider,
} from '@/app/components';
import { Component as ComponentModel } from '@/app/models';
import { getIsAuthenticated } from '@/app/store';
import { User } from '@/api/entities/users/models';
import {
  accountRequestStarted,
  ACCOUNT_REQUEST_SUCCEEDED,
  getUser,
} from '@/api/entities/users/store';
import {
  identifyMixpanelUser,
  loadBrazeServiceWorker,
} from '@/lib/analytics/helpers';

import {
  extractAuthorization,
  getRequestActionInfo,
  resolveRequestAction,
} from '@/lib/api/helpers';
import { TooltipProvider } from '@/lib/communication/components';
import { FeatureFlags } from '@/lib/feature-flags/models';
import { clientDataInitialized, getAllFlags } from '@/lib/feature-flags/store';
import { RouterHistoryProvider } from '@/lib/routing/components';
import { redirect } from '@/lib/routing/helpers';
import {
  getI18nLanguage,
  getPreferredLanguage,
} from '@/views/global-onstreet-parking/helpers';
import {
  routeInitializedOnClient,
  routeInitializedOnServer,
} from '@/lib/routing/store';
import { ProgressIndicatorProvider } from '@/lib/shared/components';
import { AppConfig } from '@/lib/shared/models';
import {
  appConfigLoaded,
  appMounted,
  getAppConfig,
  languagePreferenceLoaded,
} from '@/lib/shared/store';
import { trackVisitDeviceType, DEVICE_OPTIONS } from '@/app/analytics';
import { getIsNavigatorAgentDeviceMobile } from '@/lib/shared/helpers';

import { configureStore } from '@/lib/store';
import {
  getCookie,
  getClientsideIsGlobalUserAuthenticated,
  isMatch,
  logRedactor,
  getServersideIsGlobalUserAuthenticated,
} from '@/lib/utils';
import { datadogRum } from '@datadog/browser-rum';
import { GoogleOAuthProvider } from '@react-oauth/google';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import _ from 'lodash';
import withRedux from 'next-redux-wrapper';
import App from 'next/app';
import Head from 'next/head';
import oneConfig from '@parkmobile/one-config';
import h from 'react-hyperscript';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import i18n from '../../i18n';

const getAppConfigFromReq = (req) => _.get(req, 'appConfig', null);
const getLdClientDataFromReq = (req) => _.get(req, 'ldClientData', {});
const getPageComponent = (Component, pageProps) => {
  const { errorCode, ...componentProps } = pageProps;
  const layout = ComponentModel.getLayout(Component);
  const clipContent = ComponentModel.getClipContent(Component);

  if (errorCode) return h(ErrorView, { statusCode: errorCode });
  if (_.isFunction(layout)) return layout(componentProps);
  if (layout === 'none') return h(Component, componentProps);
  return h(
    Layout,
    { clipContent, display: layout },
    h(Component, componentProps)
  );
};

class MyApp extends App {
  static datadogInitHasBeenCalled = false;

  static initializeDatadog(options) {
    datadogRum.init(options);
    MyApp.datadogInitHasBeenCalled = true;
  }

  static async getInitialProps({ Component, ctx }) {
    const { asPath, isServer, req, res, store } = ctx;
    const initializedAction = isServer
      ? routeInitializedOnServer
      : routeInitializedOnClient;
    const isAuthRequired = ComponentModel.getIsAuthRequired(Component);

    // Get the app config and feature flags when req is defined (on server)
    if (req) {
      const userPreferredLanguage = getPreferredLanguage(req);
      store.dispatch(languagePreferenceLoaded(userPreferredLanguage));
      const i18nLanguage = getI18nLanguage(req);
      const appConfig = getAppConfigFromReq(req);
      store.dispatch(appConfigLoaded(appConfig));

      const ldClientData = getLdClientDataFromReq(req);
      const { bootstrap, user, userHash, flags } = ldClientData;
      store.dispatch(
        clientDataInitialized({ bootstrap, flags, user, userHash })
      );

      // Set user agent language
      i18n.changeLanguage(i18nLanguage);
    }

    // Dispatch the initialized action so that any sagas can perform inital effects before render
    store.dispatch(initializedAction({ ...ctx, isAuthRequired }));

    // Bail out early if we didn't have an app config
    const appConfig = getAppConfig(store.getState());

    if (!appConfig) {
      await store.finalize();
      return {
        pageProps: { errorCode: 500 },
      };
    }

    const IS_LOGGED_IN_COOKIE = oneConfig.get('IS_LOGGED_IN_COOKIE');

    const checkIfAuthenticated = async () => {
      /* Fetch the user if:
       *  1. We are on the server and there is a jwt
       *  2. We are on the client and there is an IS_LOGGED_IN cookie
       *
       *  We are adding these conditions so that we don't generate 401s
       *  by requesting user info when the user is logged out.
       */
      const isLoggedIn = isServer
        ? extractAuthorization(req)
        : getCookie(IS_LOGGED_IN_COOKIE);
      if (!isLoggedIn) return false;

      const userAction = accountRequestStarted(req);
      const userRequest = getRequestActionInfo(userAction);
      const resultAction = await resolveRequestAction(userRequest);
      store.dispatch(resultAction);
      const userIsAuthenticated =
        resultAction.type === ACCOUNT_REQUEST_SUCCEEDED;
      return userIsAuthenticated;
    };

    // parkmobile authentication
    const isAuthenticated = await checkIfAuthenticated();
    // easypark authentication
    const isGlobalUserAuthenticated = isServer
      ? await getServersideIsGlobalUserAuthenticated(req)
      : getClientsideIsGlobalUserAuthenticated();

    // Redirect when an unauthorized user tries to view a restricted page
    if (isAuthRequired && !isAuthenticated) {
      redirect(`/login?redirect=${asPath}`, res);
      return {
        pageProps: { errorCode: 401 },
      };
    }

    // Redirect when an authorized user tries to view the login or registration page
    if (isAuthenticated && isMatch(['/login', '/register'], asPath)) {
      const redirectUrl = AppConfig.getAfterAuthenticationLink(appConfig);
      redirect(redirectUrl, res);
    }

    // Get the inital props from the Component (which could dispatch actions to store)
    // and return the result
    const faviconHref = AppConfig.getFaviconLogoSrc(appConfig);
    const googleOauthClientId = oneConfig.get('GOOGLE_OAUTH_CLIENT_ID');
    const pageProps = Component.getInitialProps
      ? await Component.getInitialProps(ctx)
      : {};

    await store.finalize();
    return {
      faviconHref,
      googleOauthClientId,
      isGlobalUserAuthenticated,
      pageProps: {
        ...pageProps,
        errorCode: null,
      },
    };
  }

  componentDidMount() {
    const { router } = this.props;
    const domain = typeof window !== 'undefined' && window.location.origin;
    const { viewType } = router.query;
    const state = this.props.store.getState();
    const flags = getAllFlags(state);
    const datadogRumFlag = FeatureFlags.datadogRum(flags);
    const isDatadogRumEnabled = _.get(datadogRumFlag, 'enabled');
    const isAuthenticatedForParkMobile = getIsAuthenticated(state);
    const isEasyPark = oneConfig.get('IS_EASYPARK');

    if (isDatadogRumEnabled && !isEasyPark) {
      const DD_RUM_APPLICATION_ID = oneConfig.get('DD_RUM_APPLICATION_ID');
      const DD_RUM_CLIENT_TOKEN = oneConfig.get('DD_RUM_CLIENT_TOKEN');
      const DD_SERVICE = oneConfig.get('DD_SERVICE');

      MyApp.initializeDatadog({
        applicationId: DD_RUM_APPLICATION_ID,
        beforeSend: (event) => {
          // Removing data that can potentially have PII
          const error = event?.error;
          if (error?.source && error.source !== 'network') {
            error.message = logRedactor(error.message);
          }
          // Logging request errors that are removed in v3
          if (event.type === 'resource' && event.resource.status_code) {
            datadogRum.addError(
              `${event.resource.method} ${event.resource.url} ${event.resource.status_code}`
            );
          }
        },
        clientToken: DD_RUM_CLIENT_TOKEN,
        service: DD_SERVICE,
        sessionReplaySampleRate: 100,
        trackInteractions: _.get(datadogRumFlag, 'track_interactions'),
        useSecureSessionCookie: true,
      });
    }

    // Need to call identify here for Mixpanel in case the user lands on the site already logged-in.
    if (isAuthenticatedForParkMobile) {
      const user = getUser(state);
      const userId = User.getId(user);
      identifyMixpanelUser(userId);
    }

    if (viewType?.toLocaleLowerCase() === 'inappbrowser') {
      trackVisitDeviceType({
        deviceType: DEVICE_OPTIONS.inApp,
        whiteLabel: domain,
      });
    } else {
      trackVisitDeviceType({
        deviceType: getIsNavigatorAgentDeviceMobile()
          ? DEVICE_OPTIONS.mobile
          : DEVICE_OPTIONS.desktop,
        whiteLabel: domain,
      });
    }

    if (!isEasyPark) {
      // load braze service worker to enable in-app messages
      loadBrazeServiceWorker();
    }

    this.props.store.dispatch(appMounted());
  }

  render() {
    const {
      Component,
      faviconHref,
      googleOauthClientId,
      isGlobalUserAuthenticated,
      pageProps = {},
      store,
    } = this.props;

    const pageComponent = getPageComponent(Component, pageProps);

    return (
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <ReactQueryProvider dehydratedState={pageProps?.dehydratedState}>
            <PrismicToolbar />
            <Head>
              {/* https://github.com/vercel/next.js/blob/master/errors/no-document-viewport-meta.md */}
              <meta
                content='width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=2'
                name='viewport'
              />
              <link href={faviconHref} rel='icon' type='image/png' />
              <link
                href='https://fonts.googleapis.com/css?family=Inter'
                rel='stylesheet'
              />
            </Head>
            <StylesProvider>
              <ProgressIndicatorProvider>
                <RouterHistoryProvider>
                  <TooltipProvider>
                    <GoogleOAuthProvider clientId={googleOauthClientId}>
                      <AuthenticationProvider
                        isGlobalUserAuthenticated={isGlobalUserAuthenticated}
                      >
                        {/* TODO: Add EasyPark's TokenRefreshManager if IS_EASYPARK is true  */}
                        {pageComponent}
                      </AuthenticationProvider>
                    </GoogleOAuthProvider>
                  </TooltipProvider>
                </RouterHistoryProvider>
              </ProgressIndicatorProvider>
            </StylesProvider>
          </ReactQueryProvider>
        </I18nextProvider>
      </Provider>
    );
  }
}

/*
 * Exported function used by NextJS framework. Do not delete.
 * More Info: https://nextjs.org/docs/advanced-features/measuring-performance
 */
export function reportWebVitals({ id, name, label, value }) {
  if (MyApp.datadogInitHasBeenCalled) {
    datadogRum.addAction(name, {
      webVital: { id, label, value },
    });
  }
}

export default withRedux(configureStore, {
  debug: false,
})(MyApp);
