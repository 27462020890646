import {
  PRIVACY_POLICY_URL,
  TERMS_AND_CONDITIONS_URL,
} from '@/lib/shared/constants';
import { AppConfig } from '@/lib/shared/models';
import {
  accessibilityViewToggled,
  getAppConfig,
  getIsAccessibilityViewActive,
  getLanguagePreference,
} from '@/lib/shared/store';
import { useRouter } from 'next/router';
import oneConfig from '@parkmobile/one-config';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useGlobalUserAuthenticationContext } from '@/app/components/authentication-provider/authentication-provider';
import { getAllLegalUrls } from '@/views/global-onstreet-parking/helpers';

export function useFooter() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();

  const isGlobalUserAuthenticated = useGlobalUserAuthenticationContext();
  const appConfig = useSelector(getAppConfig);
  const userPreferredLanguage = useSelector(getLanguagePreference);
  const doNotSellUrl = oneConfig.get('ONETRUST_DO_NOT_SELL_URL');
  const isA11yThemeActive = useSelector(getIsAccessibilityViewActive);
  const isA11yThemeEnabled = AppConfig.getEnableAccessibilityView(appConfig);

  const [isAccountDeletionModalOpen, setIsAccountDeletionModalOpen] =
    useState(false);
  const [policyUrls, setPolicyUrls] = useState();

  const asPath = router.asPath;
  const isEasyParkFlow = asPath.includes('/global');
  const EASYPARK_PRIVACY_POLICY_URL = t(
    'common:footer:links.privacyPolicy.href'
  );
  const EASYPARK_TERMS_URL = t('common:footer:links.privacyPolicy.href');

  const cookiePolicyUrl = t('common:footer:links.cookiePolicy.href');

  const privacyUrl = isEasyParkFlow
    ? EASYPARK_PRIVACY_POLICY_URL
    : PRIVACY_POLICY_URL;

  const termsUrl = isEasyParkFlow
    ? EASYPARK_TERMS_URL
    : TERMS_AND_CONDITIONS_URL;

  const logoImageSrc =
    isA11yThemeEnabled && isA11yThemeActive
      ? AppConfig.getFooterAccessibilityThemeLogoSrc(appConfig)
      : AppConfig.getFooterLogoSrc(appConfig);

  const date = new Date();
  const currentYear = date.getFullYear();

  useEffect(() => {
    const userLocale = userPreferredLanguage.split('-');
    const language = userLocale[0];
    const country = userLocale[1];
    setPolicyUrls(getAllLegalUrls(country, language));
  }, [userPreferredLanguage]);

  // Handlers
  const onA11yButtonClick = useCallback(() => {
    dispatch(accessibilityViewToggled(!isA11yThemeActive));
  }, [dispatch, isA11yThemeActive]);

  const onDeleteAccountLinkClick = useCallback(() => {
    setIsAccountDeletionModalOpen(true);
  }, []);

  const onHideDeleteAccountModalClick = useCallback(() => {
    setIsAccountDeletionModalOpen(false);
  }, []);

  return [
    {
      cookiePolicyUrl: policyUrls?.COOKIE_POLICY || cookiePolicyUrl,
      currentYear,
      doNotSellUrl,
      isA11yThemeActive,
      isA11yThemeEnabled,
      isAccountDeletionModalOpen,
      isGlobalUserAuthenticated,
      logoImageSrc,
      privacyUrl: policyUrls?.PRIVACY_POLICY || privacyUrl,
      termsUrl: policyUrls?.TERMS_AND_CONDITIONS || termsUrl,
    },
    {
      onA11yButtonClick,
      onDeleteAccountLinkClick,
      onHideDeleteAccountModalClick,
    },
  ];
}
