import styled from 'styled-components';

const darkImgShade = `
linear-gradient(to bottom, rgba(255, 255, 255, 0) 30%, rgba(0, 0, 0, 0.40) 90%),linear-gradient(to top, rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.45) 90%, rgba(0, 0, 0, 0.90) 100%), linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.45) 50%, rgba(0, 0, 0, 0.90) 85%),`;

const lightImgShade = `
linear-gradient(to bottom, rgba(255, 255, 255, 0) 30%, rgba(0, 0, 0, 0.20) 90%),
linear-gradient(to top, rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.70) 100%),
linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.25) 50%, rgba(0, 0, 0, 0.70) 85%),`;

export const BackgroundImage = styled.div(({ modifiers }) => ({
  background: `url(${modifiers.imgSrc}) ${modifiers.backgroundPosition} / cover no-repeat`,
  height: '100%',
  width: '100%',
  ...(modifiers.overlay === 'light' && {
    background: `${lightImgShade}
    url(${modifiers.imgSrc})
    ${modifiers.backgroundPosition} / cover no-repeat`,
  }),
  ...(modifiers.overlay === 'dark' && {
    background: `${darkImgShade}
    url(${modifiers.imgSrc})
    ${modifiers.backgroundPosition} / cover no-repeat`,
  }),
}));

export const Image = styled.img(({ modifiers }) => ({
  ...(modifiers.fillContainer && {
    width: '100%',
  }),
}));
