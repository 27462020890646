import {
  createTheme as createPmTheme,
  darkTheme,
  lightTheme,
  common,
} from '@parkmobile/ui';
import _ from 'lodash';
import oneConfig from '@parkmobile/one-config';
import { grey } from './colors';

const isDev = oneConfig.get('NODE_ENV') !== 'production';

const light = {
  action: {
    // The color of an active action like an icon button
    active: 'rgba(0, 0, 0, 0.54)',
    // The color of a disabled action
    disabled: 'rgba(0, 0, 0, 0.26)',
    // The background color of a disabled action
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
    // The color of a hovered action
    hover: lightTheme.colors.state('hovered'),
    // The opacity of a hovered action
    hoverOpacity: lightTheme.colors.stateOpacities.hovered,
    // The color of a selected action
    selected: lightTheme.colors.state('selected'),
  },
  background: {
    // Background that goes behind everything (cards, etc.)
    default: lightTheme.colors.background,
    // Background color for overlays (i.e. behind a modal)
    overlay: 'rgba(0, 0, 0, 0.25)',
    // Background for cards, sheets, etc.
    paper: lightTheme.colors.surface,
  },
  divider: lightTheme.colors.onSurface.divider,
  text: {
    // Disabled text
    disabled: lightTheme.colors.onSurface.disabled,
    // Hints
    hint: lightTheme.colors.onSurface.hint,
    // The most important text
    primary: lightTheme.colors.onSurface.primary,
    // Less important text
    secondary: lightTheme.colors.onSurface.secondary,
  },
};

const dark = {
  action: {
    active: common.white,
    disabled: 'rgba(255, 255, 255, 0.3)',
    disabledBackground: 'rgba(255, 255, 255, 0.12)',
    hover: darkTheme.colors.state('hovered'),
    hoverOpacity: darkTheme.colors.stateOpacities.hovered,
    selected: darkTheme.colors.state('selected'),
  },
  background: {
    default: darkTheme.colors.background,
    overlay: 'rgba(0, 0, 0, 0.25)',
    paper: darkTheme.colors.surface,
  },
  divider: darkTheme.colors.onSurface.divider,
  text: {
    disabled: darkTheme.colors.onSurface.disabled,
    hint: darkTheme.colors.onSurface.hint,
    primary: darkTheme.colors.onSurface.primary,
    secondary: darkTheme.colors.onSurface.secondary,
  },
};

/**
 * Creates a theme object from theme settings
 * @param {Object} [obj] - object containing all parameters
 * @param {String} [obj.type='light'] - the type of theme, light or dark
 * @param {Object} [obj.primary] - primary color variants
 * @param {String} [obj.primary.dark] - primary dark color
 * @param {String} [obj.primary.light] - primary light color
 * @param {String} [obj.primary.main] - primary main color
 * @param {String} [obj.primary.on] - primary on color
 * @param {String} [obj.primary.onDark] - primary on dark color
 * @param {String} [obj.primary.onLight] - primary on light color
 * @param {String} [obj.primary.onMain] - primary on main color
 * @param {Object} [obj.secondary] - secondary color variants
 * @param {String} [obj.secondary.dark] - secondary dark color
 * @param {String} [obj.secondary.light] - secondary light color
 * @param {String} [obj.secondary.main] - secondary main color
 * @param {String} [obj.secondary.on] - secondary on color
 * @param {String} [obj.secondary.onDark] - secondary on dark color
 * @param {String} [obj.secondary.onLight] - secondary on light color
 * @param {String} [obj.secondary.onMain] - secondary on main color
 * @param {Boolean} [obj.useAlternateDetailsToolbar=false] - should use alternate colors for toolbar
 * @param {Boolean} [obj.useAlternateFooter=false] - should use alternate colors for footer
 * @param {Boolean} [obj.useAlternateMenu=false] - should use alternate colors for menu
 * @param {Boolean} [obj.useAlternateTopbar=false] - should use alternate colors for topbar
 * @returns {Object} - a complete theme object
 */
export function createTheme({
  type = 'light',
  primary,
  secondary,
  useAlternateDetailsToolbar = false,
  useAlternateFooter = false,
  useAlternateMenu = false,
  useAlternateTopbar = false,
}) {
  /* eslint-disable sort-keys */
  const lightOrDark = type === 'light' ? light : dark;
  const palette = {
    ...lightOrDark,
    type,
    common,
    neutral: grey,
  };

  const theme = createPmTheme({
    colors: {
      brand: {
        main: secondary.main,
        light: secondary.light,
        dark: secondary.dark,
        on: secondary.on,
        onDark: secondary.onDark,
        onLight: secondary.onLight,
        onMain: secondary.onMain,
      },
      brand2: {
        main: primary.main,
        light: primary.light,
        dark: primary.dark,
        on: primary.on,
        onDark: primary.onDark,
        onLight: primary.onLight,
        onMain: primary.onMain,
      },
      error: {
        main: lightTheme.colors.error,
        light: lightTheme.colors.errorLight,
        dark: lightTheme.colors.errorDark,
        onDark: '#fff',
        onMain: '#fff',
        onLight: lightTheme.colors.errorDark,
      },
    },
    settings: {
      useAlternateDetailsToolbar,
      useAlternateFooter,
      useAlternateMenu,
      useAlternateTopbar,
    },
    type,
  });

  const legacyTheme = Object.freeze({
    animation: {
      easeTransition: {
        transition: 'all 0.3s ease 0s',
      },
      underlineOnHover: {
        position: 'relative',
        '&::before': {
          background: 'currentColor',
          bottom: '0',
          left: '0',
          content: '""',
          height: '3px',
          opacity: '0',
          position: 'absolute',
          transition: 'all 0.2s ease-in-out',
          width: '0',
        },
        '&:hover': {
          '&::before': {
            opacity: 1,
            width: '100%',
          },
        },
      },
    },
    breakpoint: {
      desktop: [1025],
      tablet: [0, 1024],
      tabletOnly: [769, 1024],
      mobile: [0, 768],
      mobileSmall: [0, 380],
    },
    palette,
    settings: {
      useAlternateDetailsToolbar,
      useAlternateFooter,
      useAlternateMenu,
      useAlternateTopbar,
    },
    shadow: {
      default: theme.shadows.xs,
      top: '0 -1px 12px 0 rgba(0, 0, 0, 0.15)',
    },
    shape: {
      borderRadius: theme.radii.sm,
      borderRadiusLarge: theme.radii.lg,
      borderRadiusCircular: theme.radii.circular,
      buttonHeight: '34px',
      buttonHeightLarge: '44px',
      componentHeight: '48px',
      inputHeight: '44px',
      printPageHeight: '271mm',
      printPageWidth: '210mm',
      topbarHeight: '64px',
    },
    spacing: 8,
    typography: {
      // Mega Header - Mostly for marketing site
      display1: {
        ...theme.typography.display1,
        color: _.get(palette, 'text', 'primary'),
      },
      // Big Header - mostly for marketing site
      display2: {
        ...theme.typography.display2,
        color: _.get(palette, 'text', 'primary'),
      },
      // Alpha Header - Mostly for marketing site
      display3: {
        ...theme.typography.display3,
        color: _.get(palette, 'text', 'primary'),
      },
      // Major callouts - White labels, Image sections
      h1: {
        ...theme.typography.h1,
        color: _.get(palette, 'text', 'primary'),
      },
      // Section Callouts - Checkout, My Account, My Reservations
      h2: {
        ...theme.typography.h2,
        color: _.get(palette, 'text', 'primary'),
      },
      // Line Item Title/Name - Lot Name, Event Name, Venue Name
      h3: {
        ...theme.typography.h2,
        color: _.get(palette, 'text', 'primary'),
      },
      body: {
        ...theme.typography.body,
        color: _.get(palette, 'text', 'primary'),
      },
      body2: {
        ...theme.typography.body,
        color: _.get(palette, 'text', 'primary'),
        fontWeight: theme.fontWeights.boldest,
      },
      button: {
        ...theme.typography.button,
        color: _.get(palette, 'text', 'primary'),
      },
      label: {
        ...theme.typography.label,
        color: _.get(palette, 'text', 'primary'),
      },
      caption: {
        ...theme.typography.caption,
        color: _.get(palette, 'text', 'primary'),
      },
      overline: {
        ...theme.typography.overline,
        color: _.get(palette, 'text', 'primary'),
      },
    },
    zIndex: {
      0: 0,
      1: 100,
      2: 200,
      3: 300,
      4: 400,
      5: 500,
    },
  });

  return {
    ...theme,
    applyAnimation(variant) {
      return _.get(legacyTheme, ['animation', variant]);
    },
    applyMediaQuery(variant, styles) {
      if (variant === 'print') return { '@media print': styles };

      const [min, max] = _.get(legacyTheme, ['breakpoint', variant]);
      const minQuery = min ? ` and (min-width: ${min}px)` : '';
      const maxQuery = max ? ` and (max-width: ${max}px)` : '';
      const mediaQuery = `@media only screen${minQuery}${maxQuery}`;

      return { [mediaQuery]: styles };
    },
    applyTypographyStyle(variant, useImportant) {
      const styles = _.get(legacyTheme, ['typography', variant], {});

      if (useImportant) {
        return _.mapValues(styles, (value) => `${value} !important`);
      }

      return styles;
    },
    calculateContrastColor(color) {
      return theme.colors.on(color);
    },
    getActionStyle(variant) {
      return _.get(legacyTheme, ['palette', 'action', variant]);
    },
    getBackgroundColor(variant) {
      return _.get(legacyTheme, ['palette', 'background', variant]);
    },
    getBreakpoint(step) {
      return _.get(legacyTheme, ['breakpoint', step]);
    },
    getContrastTextColor(color) {
      const colorName =
        _.get({ primary: 'brand2', secondary: 'brand' }, color) || color;
      return _.get(theme, `colors.on${_.capitalize(colorName)}.primary`);
    },
    getDividerColor() {
      return _.get(legacyTheme, ['palette', 'divider']);
    },
    getPaletteColor(color, shade = 'main') {
      const colorName =
        _.get({ primary: 'brand2', secondary: 'brand' }, color) || color;
      if (colorName === 'neutral' || colorName === 'common')
        return _.get(legacyTheme, ['palette', color, shade]);
      if (shade !== 'main')
        return _.get(theme, `colors.${colorName}${_.capitalize(shade)}`);
      return _.get(theme, `colors.${colorName}`);
    },
    getSetting(name) {
      return _.get(legacyTheme, ['settings', name]);
    },
    getShadow(variant = 'default') {
      return _.get(legacyTheme, ['shadow', variant]);
    },
    getShape(name) {
      return _.get(legacyTheme, ['shape', name]);
    },
    getSpacing(...multipliers) {
      if (isDev && (multipliers.length > 4 || multipliers.length === 0)) {
        // eslint-disable-next-line no-console
        console.error('getSpacing received zero or more than four arguments');
      }

      if (isDev && _.some(multipliers, (n) => !_.isFinite(n))) {
        // eslint-disable-next-line no-console
        console.error(
          'getSpacing received one or more arguments that are not valid numbers.'
        );
      }

      const spacing = _.get(legacyTheme, 'spacing');
      const values = _.map(multipliers, (n) => spacing * n);

      if (isDev && _.some(values, (n) => !_.isInteger(n))) {
        // eslint-disable-next-line no-console
        console.warn(
          'getSpacing resulted in one or more values that are not integers.'
        );
      }

      return _.map(values, (n) => `${n}px`).join(' ');
    },
    getTextColor(variant) {
      return _.get(legacyTheme, ['palette', 'text', variant]);
    },
    getThemeType() {
      return _.get(legacyTheme, ['palette', 'type']);
    },
    getZIndex(step) {
      return _.get(legacyTheme, ['zIndex', step]);
    },
    isDark() {
      return _.get(legacyTheme, ['palette', 'type']) === 'dark';
    },
    isLight() {
      return _.get(legacyTheme, ['palette', 'type']) === 'light';
    },
    _root: legacyTheme,
  };
}
