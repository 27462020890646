export const NAME = 'communication';

export const REQUEST_FAILED_HEADER = 'Something went wrong';
export const REQUEST_FAILED_IMG_SRC =
  'https://res.cloudinary.com/parkmobile-llc/image/upload/v1568996495/ConsolidatedWeb/shared/car-flat-tire.svg';
export const REQUEST_FAILED_MESSAGE =
  "Sorry, that's on us. We're working on it. Please try again later.";

export const NOT_FOUND_HEADER = 'Page Not Found';
export const NOT_FOUND_IMG_SRC =
  'https://res.cloudinary.com/parkmobile-llc/image/upload/v1569339513/ConsolidatedWeb/shared/shared-404.png';
export const NOT_FOUND_MESSAGE = "The page you are looking for doesn't exist!";

export const NO_EVENT_LOTS_IMG_SRC =
  'https://res.cloudinary.com/parkmobile-llc/image/upload/v1544814124/ConsolidatedWeb/shared/unavailable_lot.svg';
export const NO_EVENTS_IMG_SRC =
  'https://res.cloudinary.com/parkmobile-llc/image/upload/v1521746674/ConsolidatedWeb/shared/pm-blue__no-events-found.svg';
export const NO_PACKAGE_LOTS_IMG_SRC =
  'https://res.cloudinary.com/parkmobile-llc/image/upload/v1544814124/ConsolidatedWeb/shared/unavailable_lot.svg';
export const NO_PACKAGES_IMG_SRC =
  'http://res.cloudinary.com/parkmobile-llc/image/upload/v1521746674/ConsolidatedWeb/shared/pm-blue_no-packages-found.svg';
export const NO_PHOTO_IMG_SRC =
  'http://res.cloudinary.com/parkmobile-llc/image/upload/v1515006103/ConsolidatedWeb/shared/shared_no-image-available.png';
export const NO_RESERVATIONS_PARKING_IMG_SRC =
  'https://res.cloudinary.com/parkmobile-llc/image/upload/v1520366299/ConsolidatedWeb/shared/pm-blue_no-reservations-no-zones.svg';
export const NO_PAYMENTS_IMG_SRC =
  'https://res.cloudinary.com/parkmobile-llc/image/upload/v1595264417/ConsolidatedWeb/shared/shared_no-payments.svg';
export const NO_VEHICLES_YET_IMG_SRC =
  'https://res.cloudinary.com/parkmobile-llc/image/upload/v1592846144/ConsolidatedWeb/shared/no-vehicles-yet.svg';
export const NO_ZONED_PARKING_IMG_SRC =
  'https://res.cloudinary.com/parkmobile-llc/image/upload/v1520604658/ConsolidatedWeb/shared/shared_zoned-parking-coming-soon.svg';

export const SERVER_ERROR_IMG_SRC =
  'https://res.cloudinary.com/parkmobile-llc/image/upload/v1516374622/ConsolidatedWeb/shared/shared-something_wrong.png';

// local storage keys
export const USER_SETTINGS_LOCAL_STORAGE_KEY = 'userSettings';

/* eslint-disable sort-keys */
export const TOURS = {
  // searchTour: [
  //   { tourStepID: 'searchTour_1', text: 'Tour Step 1' },
  //   { tourStepID: 'searchTour_2', text: 'Tour Step 2' },
  //   { tourStepID: 'searchTour_3', text: 'Tour Step 3' },
  // ],
};
