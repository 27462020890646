import { REQUEST } from '@/lib/api/constants';
import { extractAuthorization, extractCookies } from '@/lib/api/helpers';
import { UPDATE } from '@/lib/data/constants';
import {
  trackChangeEmailAttempted,
  trackChangeEmailFailure,
  trackChangeEmailSuccess,
  trackChangePhoneNumberAttempted,
  trackChangePhoneNumberFailure,
  trackChangePhoneNumberSuccess,
  trackChangePasswordSuccess,
  trackChangePasswordFailure,
  trackChangePasswordAttempted,
} from '@/views/account/analytics';
import _ from 'lodash';
import fp from 'lodash/fp';
import { normalize } from 'normalizr';
import { NAME } from '../constants';
import { User } from '../models';

export const ACCOUNT_REQUEST_FAILED = `${NAME}/ACCOUNT_REQUEST_FAILED`;
export const ACCOUNT_REQUEST_STARTED = `${NAME}/ACCOUNT_REQUEST_STARTED`;
export const ACCOUNT_REQUEST_SUCCEEDED = `${NAME}/ACCOUNT_REQUEST_SUCCEEDED`;

export const CHANGE_PASSWORD_REQUEST_STARTED = `${NAME}/CHANGE_PASSWORD_REQUEST_STARTED`;
export const CHANGE_PASSWORD_REQUEST_SUCCEEDED = `${NAME}/CHANGE_PASSWORD_REQUEST_SUCCEEDED`;
export const CHANGE_PASSWORD_REQUEST_FAILED = `${NAME}/CHANGE_PASSWORD_REQUEST_FAILED`;

export const PROFILE_UPDATE_REQUEST_FAILED = `${NAME}/PROFILE_UPDATE_REQUEST_FAILED`;
export const PROFILE_UPDATE_REQUEST_STARTED = `${NAME}/PROFILE_UPDATE_REQUEST_STARTED`;
export const PROFILE_UPDATE_REQUEST_SUCCEEDED = `${NAME}/PROFILE_UPDATE_REQUEST_SUCCEEDED`;

export function accountRequestStarted(req, tag = ACCOUNT_REQUEST_STARTED) {
  return {
    [REQUEST]: {
      authorization: extractAuthorization(req),
      cookies: extractCookies(req),
      endpoint: '/user',
      failure: ACCOUNT_REQUEST_FAILED,
      method: 'GET',
      success: (responseData) => accountRequestSucceeded(responseData, tag),
      tag,
    },
    type: ACCOUNT_REQUEST_STARTED,
  };
}

export function accountRequestSucceeded(responseData, tag) {
  const normalized = normalize(responseData, User.schema);
  return {
    meta: {
      [UPDATE]: {
        [User.schemaName]: {
          byId: _.get(normalized, ['entities', User.schemaName], {}),
          orderedIds: [
            fp.compose(_.toString, _.getOr([], 'result'))(normalized),
          ],
          tag,
        },
      },
    },
    payload: responseData,
    type: ACCOUNT_REQUEST_SUCCEEDED,
  };
}

export function changePasswordRequestFailed(responseData) {
  // analytics
  trackChangePasswordFailure({ errorCode: responseData?.status });

  return {
    payload: responseData,
    type: CHANGE_PASSWORD_REQUEST_FAILED,
  };
}

export function changePasswordRequestSucceeded() {
  // analytics
  trackChangePasswordSuccess();

  return {
    type: CHANGE_PASSWORD_REQUEST_SUCCEEDED,
  };
}

export function changePasswordRequestStarted(
  { currentPassword, newPassword },
  tag = CHANGE_PASSWORD_REQUEST_STARTED
) {
  trackChangePasswordAttempted();

  return {
    [REQUEST]: {
      data: {
        currentPassword,
        newPassword,
      },
      endpoint: '/change-password',
      failure: (responseData) => changePasswordRequestFailed(responseData),
      method: 'PUT',
      success: () => changePasswordRequestSucceeded(),
      tag,
    },
    type: CHANGE_PASSWORD_REQUEST_STARTED,
  };
}

export function profileUpdateRequestFailed(responseData, changedFields) {
  // analytics
  if (_.includes(changedFields, 'email')) {
    trackChangeEmailFailure({ errorCode: responseData?.status });
  }
  if (_.includes(changedFields, 'mobile')) {
    trackChangePhoneNumberFailure({ errorCode: responseData?.status });
  }

  return {
    payload: responseData,
    type: PROFILE_UPDATE_REQUEST_FAILED,
  };
}

export function profileUpdateRequestSucceeded(responseData, changedFields) {
  // analytics
  if (_.includes(changedFields, 'email')) {
    trackChangeEmailSuccess();
  }
  if (_.includes(changedFields, 'mobile')) {
    trackChangePhoneNumberSuccess();
  }

  const normalized = normalize(responseData, User.schema);
  return {
    meta: {
      [UPDATE]: {
        [User.schemaName]: {
          byId: _.get(normalized, ['entities', User.schemaName], {}),
        },
      },
    },
    payload: responseData,
    type: PROFILE_UPDATE_REQUEST_SUCCEEDED,
  };
}

export function profileUpdateRequestStarted(
  data,
  tag = PROFILE_UPDATE_REQUEST_STARTED
) {
  const changedFields = _.keys(data);

  // analytics
  if (_.includes(changedFields, 'email')) {
    trackChangeEmailAttempted();
  }
  if (_.includes(changedFields, 'mobile')) {
    trackChangePhoneNumberAttempted();
  }

  return {
    [REQUEST]: {
      data,
      endpoint: '/user',
      failure: (responseData) =>
        profileUpdateRequestFailed(responseData, changedFields),
      method: 'PUT',
      success: (responseData) =>
        profileUpdateRequestSucceeded(responseData, changedFields),
      tag,
    },
    type: PROFILE_UPDATE_REQUEST_STARTED,
  };
}
