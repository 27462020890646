import has from 'lodash/has';
import { combineReducers } from 'redux';

const createStub =
  () =>
  (state = null) =>
    state;

export const configureReducers = (reducers = {}, initialState) => {
  const stubs = Object.keys(initialState).reduce((result, current) => {
    if (has(reducers, current)) return result;
    return { ...result, [current]: createStub() };
  }, {});

  return combineReducers({
    ...stubs,
    ...reducers,
  });
};
