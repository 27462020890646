import { FeatureFlags } from '@/lib/feature-flags/models';
import { getAllFlags } from '@/lib/feature-flags/store';
import Head from 'next/head';
import oneConfig from '@parkmobile/one-config';
import h from 'react-hyperscript';
import { useSelector } from 'react-redux';

const config = oneConfig.config();
const { PRISMIC_API_ENDPOINT } = config;

export function PrismicToolbar() {
  const flags = useSelector(getAllFlags);
  const isEnabled = FeatureFlags.isPrismicPreviewsEnabled(flags);

  if (isEnabled) {
    return h(
      Head,
      {
        key: 'prismic-preview-toolbar',
      },
      [
        h('script', {
          dangerouslySetInnerHTML: {
            __html: `
            window.prismic = {
              endpoint: '${PRISMIC_API_ENDPOINT}',
            }
            `,
          },
        }),
        h('script', {
          async: true,
          src: 'https://static.cdn.prismic.io/prismic.min.js',
          type: 'text/javascript',
        }),
      ]
    );
  }

  return null;
}
