import { NAME } from '../constants';

// Action Types
export const NOTIFICATION_DISMISSED = `${NAME}/NOTIFICATION_DISMISSED`;
export const NOTIFICATION_EXPIRED = `${NAME}/NOTIFICATION_EXPIRED`;
export const NOTIFICATION_SHOWN = `${NAME}/NOTIFICATION_SHOWN`;

export const USER_SETTINGS_LOADED = `${NAME}/USER_SETTINGS_LOADED`;

export const TOOLTIP_SHOWN = `${NAME}/TOOLTIP_SHOWN`;
export const TOUR_SHOWN = `${NAME}/TOUR_SHOWN`;

// Action Creators
export const notificationDismissed = (index) => ({
  payload: index,
  type: NOTIFICATION_DISMISSED,
});

export const notificationExpired = (index) => ({
  payload: index,
  type: NOTIFICATION_EXPIRED,
});

export const userSettingsLoaded = (stateSlice) => ({
  payload: stateSlice,
  type: USER_SETTINGS_LOADED,
});

export const tooltipShown = (tooltipId) => ({
  payload: tooltipId,
  type: TOOLTIP_SHOWN,
});

export const tourShown = (tourID) => ({
  payload: tourID,
  type: TOUR_SHOWN,
});

export const notificationShown = ({
  canDismiss = true,
  content = '',
  type = 'info',
  duration = 4000,
  persist = false,
}) => ({
  payload: { canDismiss, content, duration, persist, type },
  type: NOTIFICATION_SHOWN,
});
