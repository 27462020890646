const fp = require('lodash/fp');

const FeatureFlags = {
  apiServices: fp.get('api-services'),
  checkoutV2Venues: fp.get('checkout-v2-venues'),
  datadogRum: fp.get('datadog-rum'),
  disableLeavingPagePopupGuest: fp.get('disable-leaving-page-popup-guest'),
  easyParkCountryConfigurations: fp.get('b2c-country-specific-configurations'),
  easyParkRecaptchaV3Threshold: fp.get('ep-recaptcha-v3-threshold-score'),
  easyParkWhitelist: fp.get('easy-park-whitelist'),
  guestCheckoutBlacklist: fp.get('guest-checkout-blacklist'),
  isAccessCodesForTransientLotsEnabled: fp.get(
    'access-codes-for-transient-lots'
  ),
  isApiKillSwitchEnabled: fp.get('api-kill-switch'),
  isApple3PAEnabled: fp.get('apple-3-pa'),
  isAppleWalletEnabled: fp.get('apple-wallet-pass'),
  isB2BSidebarLinkEnabled: fp.get('b2b-sidebar-link'),
  isCheckoutV2Enabled: fp.get('is-checkout-v2-enabled'),
  isComplexPasswordValidationEnabled: fp.get(
    'complex-password-requirements-enabled'
  ),
  isCreditCardInfoSkippable: fp.get(
    'on-demand-hide-payment-details-when-zone-is-start-duration-0-and-does-not-use-pre-auth'
  ),
  isCustomerMigrationEnabled: fp.get('isCustomerMigrationEnabled'),
  isDevtoolsEnabled: fp.get('developer-tools'),
  isEasyparkActivityEnabled: fp.get('enable-easypark-active-parkings'),
  isEasyParkDirectTokenOnRegistrationEnabled: fp.get(
    'enable-direct-token-on-registration'
  ),
  isEasyParkEpicEndpointEnabled: fp.get('update-easy-park-epic-endpoint'),
  isEasyParkMaintenancePageEnabled: fp.get('easy-park-maintenance-page'),
  isEasyParkMultiChoiceMultiTypeParkingEnabled: fp.get(
    'enable-multichoice-multitype-parking'
  ),
  isEasyParkNewEpicOTPEndpointsEnabled: fp.get(
    'ep-new-upgraded-epic-otp-endpoint'
  ),
  isEasyParkPaymentManagementEnabled: fp.get('enable-payment-management'),
  isEasyParkRecaptchaEnabled: fp.get('enable-easy-park-recaptcha'),
  isEasyParkV2EpicEndpointEnabled: fp.get('update-easy-park-epic-endpoint'),
  isEmailConfirmationEnabled: fp.get('email-validation'),
  isEmailOnlyForLoginEnabled: fp.get('email-only-for-login'),
  isEmailVerificationSkipButtonEnabled: fp.get(
    'email-verification-skip-button'
  ),
  isEnableGeneralLoginFlow: fp.get('enable-general-login-flow'),
  isEPInputPhoneValidationsEnabled: fp.get('ep-input-phone-validations'),
  isGlobalOnStreetParkingEnabled: fp.get('global-onstreet-parking'),
  isGlobalReviewStickyCTA: fp.get('global-review-sticky-cta'),
  isGoogle3PAEnabled: fp.get('google-3-pa'),
  isLicensePlateHelperTextEnabled: fp.get('license-plate-helper-text'),
  isMagicLoginEnabled: fp.get('magic-login'),
  isMaintenanceKillSwitchEnabled: fp.get('maintenance-kill-switch'),
  isNormalBucketParkingEnabled: fp.get('normal-bucket-parking-enabled'),
  isOnDemandActivateApiErrorMessageEnabled: fp.get(
    'WEBEU-251-Use-On-Demand-Activate-Api-Error-Message'
  ),
  isOnDemandConfirmationScreenRegistrationModalEnabled: fp.get(
    'on-demand-confirmation-screen-registration-modal'
  ),
  isOnDemandGuestCheckoutEnabled: fp.get('on-demand-guest-checkout'),
  isOnDemandGuestCheckoutStartStopEnabled: fp.get(
    'on-demand-guest-checkout-start-stop'
  ),
  isOnDemandMapListEnabled: fp.get('on-demand-map-list'),
  isOnDemandRedirectEnabled: fp.get('on-demand-redirect'),
  isOnDemandStickyStartParkingButtonEnabled: fp.get(
    'on-demand-sticky-start-parking-button'
  ),
  isPackageMultiQuantityEnabled: fp.get('package-multi-quantity'),
  isParkBostonLoginWarningEnabled: fp.get('park-boston-login-warning'),
  isPriceCalculationBannerEnabled: fp.get('price-calculation-banner'),
  isPrismicPreviewsEnabled: fp.get('prismic-previews'),
  isRecaptchaV3Enabled: fp.get('recaptcha-v3'),
  isRegistrationEmailVerificationEnabled: fp.get(
    'registration-email-verification'
  ),
  isReservationsCheckoutCustomFieldsEnabled: fp.get(
    'reservations-checkout-custom-fields'
  ),
  isReservationsGuestCheckoutEnabled: fp.get('reservations-guest-checkout'),
  isReservationsGuestCheckoutSecurityCodeEnabled: fp.get(
    'reservations-guest-security-code'
  ),
  isReservationsPriceBreakdownEnabled: fp.get('reservations-price-breakdown'),
  isReservationsPromoCodeEnabled: fp.get('reservations-promo-code'),
  isReservationsUserCheckoutSecurityCodeEnabled: fp.get(
    'reservations-user-checkout-security-code'
  ),
  isResetPasswordFlowEnabled: fp.get('reset-password-flow'),
  isRetrievePaymentInfoFromBillingApiEnabled: fp.get(
    'retrieve-payment-device-info-from-billing-api'
  ),
  isSessionsPageEnabled: fp.get('sessions-page'),
  isSettingsEmailVerificationEnabled: fp.get('settings-email-verification'),
  isSettingsPageAddEmailNotificationEnabled: fp.get(
    'settings-page-add-email-notification'
  ),
  isShoppingCartLimitEnabled: fp.get('shopping-cart-item-limit'),
  isStateFarmArenaCustomPinColorsEnabled: fp.get(
    'state-farm-arena-custom-pin-colors'
  ),
  isTechnicalDifficultiesKillSwitchEnabled: fp.get(
    'technical-difficulties-kill-switch'
  ),
  isWebWidgetContentEnabled: fp.get('web-widget-content'),
  isWebWidgetInfoEnabled: fp.get('web-widget-info'),
  magicLoginRedirectWhitelist: fp.get('magic-login-redirect-whitelist'),
  parkingTypeWhitelist: fp.get('b2c-parking-type-whitelist'),
  passwordExpiredErrorString: fp.get('password-expired-error-string'),
  recaptchaV3ThresholdScore: fp.get('recaptcha-v3-threshold-score'),
  tcModalGuestCheckoutWhitelist: fp.get('tc-modal-guest-checkout-whitelist'),
  updateAppBffEndpoint: fp.get('update-app-bff-endpoint'),
  webeu89HardCodeTermsPrivacyLinks: fp.get(
    'WEBEU-89-Hard-Code-Terms-Privacy-Links'
  ),
  webeu201UseHardCodedCookiePolicyUrls: fp.get(
    'WEBEU-201-Use-Hard-Coded-Cookie-Policy-Urls'
  ),
};

module.exports = { FeatureFlags };
