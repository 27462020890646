const oneConfig = require('@parkmobile/one-config');

const config = oneConfig.config();

const NAME = 'routing';

const ROUTES_LIST = config.PAGE_PATHNAMES.reduce(
  (result, pathname) => [
    ...result,
    {
      name: `file__${pathname}`,
      page: pathname,
      pattern: pathname,
    },
  ],
  []
).concat([
  // ---------- Account Routes ----------
  {
    name: 'accountReservationHistory',
    page: '/account/reservations',
    pattern: '/account/reservations/history',
  },
  // ---------- Checkout Routes ----------
  {
    name: 'checkoutEvent',
    page: '/checkout',
    pattern: '/checkout/event/:event_id/:product_id',
  },
  {
    name: 'checkoutPackage',
    page: '/checkout',
    pattern: '/checkout/package/:package_id/:product_id',
  },
  {
    name: 'checkoutReservation',
    page: '/checkout',
    pattern: '/checkout/reservation/:internal_zone_code/:product_id',
  },
  // ---------- Order Routes ----------
  {
    name: 'orderConfirmationDeprecated',
    page: '/confirmation',
    pattern: '/confirmation/:order_id',
  },
  {
    name: 'orderConfirmationDeprecated2',
    page: '/order/confirmation',
    pattern: '/order/confirmation/:order_uuid',
  },
  {
    name: 'orderConfirmation',
    page: '/order-confirmation',
    pattern: '/orders/:order_uuid/confirmation',
  },
  {
    name: 'orderDetails',
    page: '/order-details',
    pattern: '/orders/:order_uuid/details',
  },

  // ---------- Permit Routes ----------
  {
    name: 'permits',
    page: '/permits',
    pattern: '/permits/:uuid',
  },
  {
    name: 'orderPermits',
    page: '/order-permits',
    pattern: '/orders/:order_uuid/permits',
  },
  // ---------- Reservation Routes ----------
  {
    name: 'reservationDetails',
    page: '/search',
    pattern: '/reservation/:internalZoneCode',
  },
  // ---------- Zone Routes -----------
  {
    name: 'zoneDetails',
    page: '/search',
    pattern: '/zone/:internalZoneCode',
  },
  // ---------- Venue Routes ----------
  {
    name: 'venueDetails',
    page: '/search',
    pattern: '/venue/:venue_slug',
  },
  // ---------- Event Routes ----------
  {
    name: 'venueEvents',
    page: '/search',
    pattern: '/venue/:venue_slug/events',
  },
  {
    name: 'venueEventLots',
    page: '/search',
    pattern: '/venue/:venue_slug/events/:event_id',
  },
  {
    name: 'venueEventLotDetails',
    page: '/search',
    pattern: '/venue/:venue_slug/events/:event_id/:lot_id',
  },
  // ---------- Package Routes ----------
  {
    name: 'venuePackages',
    page: '/search',
    pattern: '/venue/:venue_slug/packages',
  },
  {
    name: 'venuePackageLots',
    page: '/search',
    pattern: '/venue/:venue_slug/packages/:package_id',
  },
  {
    name: 'venuePackageLotDetails',
    page: '/search',
    pattern: '/venue/:venue_slug/packages/:package_id/:lot_id',
  },
  // ---------- Search Routes ----------
  {
    name: 'searchCity',
    page: '/search',
    pattern: '/search/:value',
  },
  // ---------- Preview Route ----------
  {
    name: 'preview',
    page: '/',
    pattern: '/preview',
  },
  // ---------- Dynamic Routes ----------
  {
    name: 'homePage',
    page: '/_page',
    pattern: '/',
  },
  {
    name: 'page',
    page: '/_page',
    pattern: '/:slug',
  },
]);

module.exports = { NAME, ROUTES_LIST };
