import _ from 'lodash';
import { fetch } from '@/lib/api/helpers';
import { User } from './models';
import { NAME } from './constants';

getUserDetails.queryKey = `${NAME}/getUserDetails`;
export async function getUserDetails({
  raw = false,
  validateAccount = false,
} = {}) {
  const result = await fetch({
    endpoint: '/global/user',
    method: 'GET',
    validateStatus(status) {
      return status >= 200 && status <= 300;
    },
  });

  if (raw) return result;

  const data = _.get(result, 'data');
  const account = User.getAccountWithActivePaymentDevice(data);

  if (validateAccount && !account) {
    throw new Error('Wrong account information.');
  }

  return data;
}

export async function updateEmailAddress({ email, parkingUserId }) {
  const result = await fetch({
    data: {
      email,
      parkingUserId,
    },
    endpoint: '/global/user/update-email',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });

  return result;
}
