import {
  Box,
  Button,
  hideIf,
  Link,
  showIf,
  Text,
  useTheme,
  Space,
} from '@parkmobile/ui';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Image, Modal } from '@/lib/shared/components';
import { useTranslation } from 'react-i18next';
import { useFooter } from './footer-hook';

const Logo = styled.div`
  height: 100%;
  min-width: 100%;
  object-fit: contain;
  object-position: 0 50%;
`;

Footer.propTypes = {
  className: PropTypes.string,
  condensed: PropTypes.bool,
  style: PropTypes.object,
};

export function Footer({ className, condensed, style }) {
  const { t } = useTranslation();
  const [data, handlers] = useFooter();
  const theme = useTheme();
  const backgroundColor = theme.settings.useAlternateFooter
    ? 'surface'
    : 'brand2';
  const color = theme.settings.useAlternateFooter
    ? 'onSurface.primary'
    : 'onBrand2.primary';

  const {
    cookiePolicyUrl,
    currentYear,
    isA11yThemeActive,
    isA11yThemeEnabled,
    isAccountDeletionModalOpen,
    isGlobalUserAuthenticated,
    logoImageSrc,
    privacyUrl,
    termsUrl,
  } = data;

  const {
    onA11yButtonClick,
    onDeleteAccountLinkClick,
    onHideDeleteAccountModalClick,
  } = handlers;

  return (
    <Box
      as='footer'
      backgroundColor={backgroundColor}
      borderTop='thin'
      className={className}
      color={color}
      flex='0 0 auto'
      marginTop='auto'
      overflow='hidden'
      style={style}
      width='100%'
    >
      {showIf(isAccountDeletionModalOpen)(() => (
        <Modal
          content={
            <Box overflowX='hidden' p='lg'>
              {t('common:footer:closeAccountModal.body1')}{' '}
              <Link
                href={`mailto:${t(
                  'common:footer:closeAccountModal.serviceEmail'
                )}`}
                underline='always'
              >
                email
              </Link>
              .&nbsp;
              {t('common:footer:closeAccountModal.body2')}
              <Space height='xl' />
              <Button
                data-pmtest-id='Close-Modal-Button'
                color='neutral'
                fullWidth
                onClick={onHideDeleteAccountModalClick}
              >
                {t('common:footer:closeAccountModal.ctas.closeModal')}
              </Button>
              <Space height='lg' />
            </Box>
          }
          contentLabel={t('common:footer:closeAccountModal.title')}
          isFullScreen={true}
          isOpen={isAccountDeletionModalOpen}
          onClose={onHideDeleteAccountModalClick}
          showContentPadding={false}
          title={
            <Text as='span' variant='h2'>
              {t('common:footer:closeAccountModal.title')}
            </Text>
          }
        />
      ))}
      <Box
        alignItems='center'
        color='inherit'
        display='flex'
        flex='1 1 auto'
        flexDirection={condensed ? { _: 'column', md: 'row' } : 'column'}
        flexWrap='nowrap'
        height={condensed ? 'inputHeight' : 'auto'}
        justifyContent='center'
        marginX='auto'
        maxWidth='1112px'
        paddingBottom={condensed ? { _: 'xs', md: '0px' } : 'lg'}
        paddingTop={condensed ? { _: 'xs', md: '0px' } : 'md'}
        paddingX='md'
        width='100%'
      >
        {/* Logo Image */}
        {hideIf(condensed)(
          <Box
            color='inherit'
            display='flex'
            flex='0 0 auto'
            height='componentHeight'
            marginBottom={{ _: 'md', sm: 'sm' }}
            marginLeft={{ _: '0px', sm: 'auto' }}
            marginRight='auto'
            overflow='hidden'
            width={{ _: '100%', sm: 'auto' }}
          >
            <Logo
              as={Image}
              altText='Footer logo'
              imgSrc={logoImageSrc}
              data-pmtest-id='footer-logo'
            />
          </Box>
        )}

        {/* Links */}
        <Box
          color='inherit'
          display='flex'
          flexDirection={condensed ? 'row' : { _: 'column', sm: 'row' }}
          marginLeft={
            condensed ? { _: 'auto', md: '0px' } : { _: '0px', sm: 'auto' }
          }
          marginRight='auto'
          role='list'
        >
          <Action
            aria-label={`${t('common:footer:links.cookiePolicy.ariaLabel')}`}
            href={cookiePolicyUrl}
            role='listitem'
            target='_blank'
            data-pmtest-id='footer-cookie-policy'
          >
            {condensed ? (
              <ResponsiveText
                _={t('common:footer:links.cookiePolicy.text')}
                sm={t('common:footer:links.cookiePolicy.text')}
                md={t('common:footer:links.cookiePolicy.text')}
              />
            ) : (
              t('common:footer:links.cookiePolicy.text')
            )}
          </Action>
          <Action
            aria-label={t('common:footer:links.privacyPolicy.ariaLabel')}
            href={privacyUrl}
            marginLeft={condensed ? 'md' : { _: '0px', sm: 'md' }}
            marginTop={condensed ? '0px' : { _: 'md', sm: '0px' }}
            role='listitem'
            target='_blank'
            data-pmtest-id='footer-privacy-policy'
          >
            {condensed ? (
              <ResponsiveText
                _='Privacy'
                sm={t('common:footer:links.privacyPolicy.text')}
              />
            ) : (
              t('common:footer:links.privacyPolicy.text')
            )}
          </Action>
          <Action
            aria-label={t('common:footer:links.termsOfUse.ariaLabel')}
            href={termsUrl}
            marginLeft={condensed ? 'md' : { _: '0px', sm: 'md' }}
            marginTop={condensed ? '0px' : { _: 'md', sm: '0px' }}
            role='listitem'
            target='_blank'
            data-pmtest-id='footer-terms-of-use'
          >
            {condensed ? (
              <ResponsiveText
                _='Terms'
                sm={t('common:footer:links.termsOfUse.text')}
              />
            ) : (
              t('common:footer:links.termsOfUse.text')
            )}
          </Action>
          {showIf(isA11yThemeEnabled)(() => (
            <Action
              aria-label={
                isA11yThemeActive
                  ? 'Change to light theme'
                  : 'Change to dark theme'
              }
              marginLeft={condensed ? 'md' : { _: '0px', sm: 'md' }}
              marginTop={condensed ? '0px' : { _: 'md', sm: '0px' }}
              onClick={onA11yButtonClick}
              role='listitem'
            >
              {isA11yThemeActive ? 'Light Theme' : 'Dark Theme'}
            </Action>
          ))}
          {showIf(isGlobalUserAuthenticated)(() => (
            <Action
              aria-label={t('common:footer:links.deleteAccount.ariaLabel')}
              marginLeft={condensed ? 'md' : { _: '0px', sm: 'md' }}
              marginTop={condensed ? '0px' : { _: 'md', sm: '0px' }}
              onClick={onDeleteAccountLinkClick}
              role='listitem'
              data-pmtest-id='footer-delete-account'
            >
              {t('common:footer:links.deleteAccount.text')}
            </Action>
          ))}
        </Box>

        {/* Copyright */}
        <Box
          color='inherit'
          flex='0 0 auto'
          marginLeft={condensed ? 'auto' : { _: '0px', sm: 'auto' }}
          marginRight={condensed ? { _: 'auto', md: '0px' } : 'auto'}
          marginTop={condensed ? { _: 'sm', md: '0px' } : { _: 'xl', sm: 'md' }}
        >
          <Text as='span' color='currentColor' truncate variant='caption'>
            <ResponsiveText
              _={`${t('common:footer:copyRight')} ${currentYear}`}
              xs={`${t('common:footer:copyRight')} ${currentYear}`}
            />
          </Text>
        </Box>
      </Box>
    </Box>
  );
}

function Action({
  'aria-label': ariaLabel,
  onClick,
  children,
  href,
  target,
  ...boxProps
}) {
  return (
    <Box {...boxProps} color='currentColor' flex='0 0 auto'>
      <Text as='span' color='inherit' transform='capitalize' variant='caption'>
        <Link
          aria-label={ariaLabel}
          as={onClick ? 'button' : 'a'}
          color='inherit'
          href={href}
          onClick={onClick}
          target={target}
          underline='hover'
        >
          {children}
        </Link>
      </Text>
    </Box>
  );
}

Action.propTypes = {
  'aria-label': PropTypes.string,
  children: PropTypes.node,
  href: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
  // Additional Box props can be specified here based on your usage
};

function ResponsiveText({ _: size, xs, sm, md }) {
  return (
    <>
      <Box as='span' color='inherit' display={{ _: 'inline', xs: 'none' }}>
        {size}
      </Box>
      <Box
        as='span'
        color='inherit'
        display={{ _: 'none', sm: 'none', xs: 'inline' }}
      >
        {xs || size}
      </Box>
      <Box
        as='span'
        color='inherit'
        display={{ _: 'none', md: 'none', sm: 'inline' }}
      >
        {sm || xs || size}
      </Box>
      <Box as='span' color='inherit' display={{ _: 'none', md: 'inline' }}>
        {md || sm || xs || size}
      </Box>
    </>
  );
}

ResponsiveText.propTypes = {
  _: PropTypes.node, // The default content
  md: PropTypes.node, // Content for medium screens
  sm: PropTypes.node, // Content for small screens
  xs: PropTypes.node, // Content for extra small screens
};
