const _ = require('lodash');

module.exports = function censorString(str, numOfAsterisks) {
  // enforce valid parameters
  if (typeof str !== 'string') return null;
  if (typeof numOfAsterisks !== 'number' || numOfAsterisks < 0) return null;

  // if no asterisks, return entire string
  if (numOfAsterisks === 0) return str;

  const asterisks = '*'.repeat(numOfAsterisks);
  const firstCharacters = str.slice(
    0,
    _.floor((str.length - numOfAsterisks) / 2)
  );
  const lastCharacters = str.slice(-_.ceil((str.length - numOfAsterisks) / 2));

  return `${firstCharacters}${asterisks}${lastCharacters}`;
};
