import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const Tooltip = styled.div(({ theme, modifiers }) => ({
  '&::after': {
    border: '5px solid transparent',
    bottom: 0,
    content: '',
    display: 'block',
    height: 0,
    left: 0,
    margin: 'aut0',
    position: 'absolute',
    right: 0,
    top: 0,
    width: 0,
    ...(modifiers.position === 'top' && {
      borderBottom: 'none',
      borderTopColor: theme.getPaletteColor('common', 'black'),
      top: 'auto',
    }),
    ...(modifiers.position === 'right' && {
      borderLeft: 'none',
      borderRightColor: theme.getPaletteColor('common', 'black'),
      right: 'auto',
    }),
    ...(modifiers.position === 'bottom' && {
      borderBottomColor: theme.getPaletteColor('common', 'black'),
      borderTop: 'none',
      bottom: 'auto',
    }),
    ...(modifiers.position === 'left' && {
      borderLeftColor: theme.getPaletteColor('common', 'black'),
      borderRight: 'none',
      left: 'auto',
    }),
  },
  animation: `0.5s ${fadeIn.getName()}`,
  cursor: 'default',
  fontSize: '0.875rem',
  padding: '5px',
  position: 'absolute',
  zIndex: 600,
}));
