module.exports = function getInLocalStorage(key) {
  try {
    const serialized = localStorage.getItem(key);
    return JSON.parse(serialized);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }

  return null;
};
