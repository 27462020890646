import { NAME } from '../constants';

export const DEVTOOLS_TOGGLED = `${NAME}/DEVTOOLS_TOGGLED`;
export const PAGE_LOADED = `${NAME}/PAGE_LOADED`;

export const devtoolsToggled = () => ({
  type: DEVTOOLS_TOGGLED,
});

export function pageLoaded() {
  return {
    type: PAGE_LOADED,
  };
}
