export const NAME = 'zones';

export const ZONE_DURATION_TYPES = {
  startDuration: 'StartDuration',
  startDurationStop: 'StartDurationStop',
  startStop: 'StartStop',
};

export const ZONE_TYPES = {
  garage: 1,
  onStreet: 0,
  reservation: 2,
};

export const ZONE_SERVICE_TYPES = {
  amenity: 'amenity',
  reservationService: 'reservationService',
  restriction: 'restriction',
};

/**
 * NOTE: The code corresponds to an icon name and the icon is rendered by the ZoneServices component
 * There are three types:
 * 1. reservationService - these types come from the CAPE system and are for reservations
 * 2. amenity - these types come from locations domain and are for on-demand
 * 3. restriction - these types come from locations domain and are for on-demand
 */
export const ZONE_SERVICES = [
  {
    code: 'MOBILE_ACCEPTED',
    description: '',
    name: 'Mobile Pass Accepted',
    type: ZONE_SERVICE_TYPES.reservationService,
  },
  {
    code: 'COVERED',
    description: '',
    name: 'Covered Parking',
    type: ZONE_SERVICE_TYPES.reservationService,
  },
  {
    code: 'DISABLED',
    description: '',
    name: 'Disabled Parking',
    type: ZONE_SERVICE_TYPES.reservationService,
  },
  {
    code: 'INOUT',
    description: '',
    name: 'Re-entry Allowed',
    type: ZONE_SERVICE_TYPES.reservationService,
  },
  {
    code: 'PAVED',
    description: '',
    name: 'Paved',
    type: ZONE_SERVICE_TYPES.reservationService,
  },
  {
    code: 'SECURE',
    description: '',
    name: 'Security On-site',
    type: ZONE_SERVICE_TYPES.reservationService,
  },
  {
    code: 'SELF',
    description: '',
    name: 'Self Parking',
    type: ZONE_SERVICE_TYPES.reservationService,
  },
  {
    code: 'TAILGATE',
    description: 'Tailgating is allowed at this location',
    name: 'Tailgating Allowed',
    type: ZONE_SERVICE_TYPES.reservationService,
  },
  {
    code: 'VP',
    description: 'Valet parking',
    name: 'Valet Parking',
    type: ZONE_SERVICE_TYPES.reservationService,
  },
  {
    code: 'CHARGE',
    description: '',
    name: 'EV Charging',
    type: ZONE_SERVICE_TYPES.reservationService,
  },
  {
    code: 'SUV',
    description: 'Oversized vehicle parking available',
    name: 'Oversized Vehicles',
    type: ZONE_SERVICE_TYPES.reservationService,
  },
  {
    code: 'ONSTREET',
    description: '',
    name: 'Street Parking',
    type: ZONE_SERVICE_TYPES.amenity,
  },
  {
    code: 'OFFSTREET',
    description: '',
    name: 'Lot/Garage',
    type: ZONE_SERVICE_TYPES.amenity,
  },
  {
    code: 'DISABLED',
    description: '',
    name: 'Accessible Parking',
    type: ZONE_SERVICE_TYPES.restriction,
  },
  {
    code: 'CHARGE',
    description: '',
    name: 'EV Charging',
    type: ZONE_SERVICE_TYPES.amenity,
  },
  {
    code: 'LOADING',
    description: '',
    name: 'Loading Zone',
    type: ZONE_SERVICE_TYPES.restriction,
  },
];

export const ZONE_SERVICES_BY_CODE = ZONE_SERVICES.reduce(
  (result, service) => ({
    ...result,
    [service.code]: service,
  }),
  {}
);

export const TIMEBLOCK_TYPES = {
  consecutive: 'Consecutive',
  predefined: 'PreDefined',
};

/**
 * Some of these errors are parameterized so if you plan on
 * using string matching use include/contains methods instead
 * of strict equality
 */
export const PARKING_ERRORS = {
  ACCESS_CODE_REQUIRED: 'Parking not allowed. Access code required',
  DUPLICATE_PARKING: 'NO PARKING: Already parked in zone',
  LPN_BLACKLISTED:
    'cannot be used to start a transaction. Please submit a support ticket at parkmobile.com for more information',
  MAXIMUM_PARKING_TIME:
    'NO PARKING: Parking beyond maximum restricted in this zone',
  NO_PARKING_BLOCK: 'This zone does not allow parking at this time',
  PAYMENT_METHOD_NOT_ACCEPTED:
    'Sorry, the payment method sent is not accepted at this location. Accepted payment methods are',
  VEHICLE_ALREADY_PARKED:
    'You must stop the active session to start a new one for this vehicle',
};
