import has from 'lodash/has';
import isFunction from 'lodash/isFunction';
import oneConfig from '@parkmobile/one-config';

const createReducerRegistry = () => {
  /* eslint-disable fp/no-let */
  let changeListener = () => {};
  let reducers = {};
  /* eslint-enable */

  const getReducers = () => ({ ...reducers });

  const register = (name, reducer) => {
    if (has(reducers, name)) return;
    if (!isFunction(changeListener)) return;

    reducers = {
      ...reducers,
      [name]: reducer,
    };

    if (oneConfig.get('ENABLE_LOGGING')) {
      // eslint-disable-next-line no-console
      console.log(`Registered reducer: "${name}"`);
    }

    changeListener(getReducers());
  };

  const setChangeListener = (fn) => {
    if (isFunction(fn)) {
      changeListener = fn;
    }

    return () => {
      changeListener = null;
    };
  };

  return {
    getReducers,
    register,
    setChangeListener,
  };
};

export const reducerRegistry = createReducerRegistry();
