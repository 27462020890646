import {
  ACCOUNT_REQUEST_FAILED,
  ACCOUNT_REQUEST_SUCCEEDED,
} from '@/api/entities/users/store';
import { getFailureActionInfo } from '@/lib/api/helpers';
import { UPDATE_FLAGS_REQUEST_SUCCEEDED } from '@/lib/feature-flags/store';
import { ROUTE_INITIALIZED_ON_SERVER } from '@/lib/routing/store';
import { combineReducers } from 'redux';
import * as actions from './actions';

// Used to determine if the user is authenticated when certain actions occur
export const isAuthenticated = (state = false, action) => {
  switch (action.type) {
    case ACCOUNT_REQUEST_FAILED: {
      // Don't treat user as signed out if request times out
      const { serverDidTimeout } = getFailureActionInfo(action);
      return serverDidTimeout ? state : false;
    }
    case ACCOUNT_REQUEST_SUCCEEDED:
      return true;
    case ROUTE_INITIALIZED_ON_SERVER:
      return action.payload.req.isAuthenticated();
    default:
      return state;
  }
};

export const isDevtoolsOpen = (state = false, { type }) => {
  if (type === UPDATE_FLAGS_REQUEST_SUCCEEDED) return false;
  if (type === actions.DEVTOOLS_TOGGLED) return !state;
  return state;
};

export const reducer = combineReducers({
  isAuthenticated,
  isDevtoolsOpen,
});
