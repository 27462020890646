import PropTypes from 'prop-types';
import React from 'react';
import h from 'react-hyperscript';
import { showIf } from 'react-render-helpers';
import { Text, Notification } from '@parkmobile/ui';
import * as styles from './notification-center-styles';

const ieMessage =
  "We've detected that you are using Internet Explorer. We recommend that you use Chrome, Edge, Firefox, or Safari for an optimal experience.";

export class NotificationCenter extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    isIE: PropTypes.bool,
    notifications: PropTypes.arrayOf(PropTypes.object),
    onNotificationDismissed: PropTypes.func,
  };

  static defaultProps = {
    notifications: [],
  };

  render() {
    return h(
      '.notification-center',
      {
        className: this.props.className,
      },
      [
        showIf(this.props.isIE)(
          h(
            styles.NotificationContainer,
            {
              as: Notification,
              'data-pmtest-id': 'notification-content-message',
              density: 'comfortable',
              dismissable: false,
              variant: 'info',
            },
            h(Text, ieMessage)
          )
        ),
        ...this.props.notifications.map((notification) =>
          h(
            styles.NotificationContainer,
            {
              as: Notification,
              'data-pmtest-id': 'notification-content-message',
              density: 'comfortable',
              dismissable: notification.canDismiss,
              onDismiss: () =>
                this.props.onNotificationDismissed(notification.id),
              variant: notification.type,
            },
            h(Text, notification.content)
          )
        ),
      ]
    );
  }
}
