import PropTypes from 'prop-types';
import React from 'react';
import h from 'react-hyperscript';
import ReactHintFactory from 'react-hint';
import { Tooltip } from '../tooltip/tooltip';

const tooltipInstance = ReactHintFactory(React);

// provides a single instance of react-hint for all hover tooltips

export class TooltipProvider extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    const { children } = this.props;
    return h(React.Fragment, [
      h(Tooltip, {
        'aria-hidden': true,
        attribute: 'data-tooltip-hover',
        delay: 0,
        events: { click: false, hover: true },
        persist: true,
        position: 'top',
        tooltipInstance,
        type: 'hover',
      }),
      ...React.Children.toArray(children),
    ]);
  }
}
