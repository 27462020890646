import oneConfig from '@parkmobile/one-config';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { logout, refresh } from '@/api/services/authentication/api';
import { AccountMergedModal } from '@/views/authentication/components/account-merged-modal/account-merged-modal';
import { getCookie, unloadWarning } from '@/lib/utils';
import {
  getIsAccountMergedModalOpen,
  accountMergedModalClosed,
} from '@/views/authentication/store';
import { WelcomeBackModal } from '@/views/search/components/welcome-back-modal/welcome-back-modal';
import { getIsAuthenticated } from '../../store';
import {
  trackSSORefreshTokenFailed,
  trackSSORefreshTokenSuccess,
} from '../../analytics';
import { emitLogoutEvent, subscribeToLogoutEvent } from '../../helpers';

const config = oneConfig.config();
const JWT_REFRESH_TOKEN_COOKIE = config.JWT_REFRESH_TOKEN_COOKIE;

const GlobalUserAuthenticationContext = createContext(false);

AuthenticationProvider.propTypes = {
  children: PropTypes.node.isRequired,
  isGlobalUserAuthenticated: PropTypes.bool.isRequired,
};

export function AuthenticationProvider({
  children,
  isGlobalUserAuthenticated,
}) {
  const isAuthenticated = useSelector(getIsAuthenticated);

  // handle logouts in other tabs
  useEffect(() => {
    if (isAuthenticated) {
      return subscribeToLogoutEvent((logoutEvent) => {
        // eslint-disable-next-line no-console
        console.info(logoutEvent);
        /* Need to make sure that the user won't be prompted for the redirect here,
         * or else, if the warning is cancelled,
         * localstorage would still handle the logout event and clear its auth data.
         */
        unloadWarning.disable();
        window.location.href = '/login';
      });
    }

    return () => {};
  }, [isAuthenticated]);

  // Send logout request and handle success
  const { mutateAsync: logoutUser } = useMutation(logout, {
    onSuccess: () => {
      emitLogoutEvent();
      localStorage.removeItem('accessToken');
      /* Need to make sure that the user won't be prompted for the redirect here,
       * or else, if the warning is cancelled,
       * localstorage would still handle the logout event and clear its auth data.
       */
      unloadWarning.disable();
      window.location.href = '/login';
    },
  });

  const { mutateAsync: refreshToken } = useMutation(refresh, {
    // when a refresh fails, redirect user to the /login page
    onError: (err, { token }) => {
      const error = err?.data?.message;
      trackSSORefreshTokenFailed({ error, refreshTokenUsed: token });
      logoutUser();
    },
    onSuccess: (response, { token }) => {
      const newRefreshTokenReceived = response?.newRefreshToken;
      trackSSORefreshTokenSuccess({
        newRefreshTokenReceived,
        refreshTokenUsed: token,
      });
    },
  });

  // refresh on app start
  useEffect(() => {
    const token = getCookie(JWT_REFRESH_TOKEN_COOKIE);
    if (token) {
      refreshToken({ token });
    }
  }, [refreshToken]);

  // refresh when user re-focuses on tab
  useEffect(() => {
    window.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        const token = getCookie(JWT_REFRESH_TOKEN_COOKIE);
        if (token) {
          refreshToken({ token });
        }
      }
    });

    return () => window.removeEventListener('visibilitychange', refreshToken);
  }, [refreshToken]);

  // refresh token every 20 minutes after user signs in (JWT expires after 30 minutes)
  useEffect(() => {
    if (!isAuthenticated) return () => {};

    const token = getCookie(JWT_REFRESH_TOKEN_COOKIE);
    const interval = setInterval(() => refreshToken({ token }), 20 * 60 * 1000);
    return () => clearInterval(interval);
  }, [isAuthenticated, refreshToken]);

  // account merged modal
  const isAccountMergedModalOpen = useSelector(getIsAccountMergedModalOpen);
  const dispatch = useDispatch();
  const handleContinue = () => dispatch(accountMergedModalClosed());

  return (
    <GlobalUserAuthenticationContext.Provider value={isGlobalUserAuthenticated}>
      <AccountMergedModal
        handleContinue={handleContinue}
        isFullScreen={false}
        isOpen={isAccountMergedModalOpen}
      />
      <WelcomeBackModal />
      {children}
    </GlobalUserAuthenticationContext.Provider>
  );
}

export function useGlobalUserAuthenticationContext() {
  const isGlobalUserAuthenticated = useContext(GlobalUserAuthenticationContext);
  if (isGlobalUserAuthenticated === undefined) {
    throw new Error(
      'useGlobalUserAuthenticationisGlobalUserAuthenticated must be used within AuthenticationProvider'
    );
  }
  return isGlobalUserAuthenticated;
}
