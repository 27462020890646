import _ from 'lodash';
import { formatCurrency } from '@/lib/shared/helpers';
import { RESIDENTIAL_PARKING_SUB_TYPES } from './constants';

export const Parking = {
  getCarCountryCode: (parkingSession) =>
    _.get(parkingSession, 'carCountryCode'),
  getCarLicenseNumber: (parkingInfo) => _.get(parkingInfo, 'carLicenseNumber'),
  getCommissionFeeInclusiveVAT: (parkingInfo) =>
    _.get(parkingInfo, 'commissionFeeInclusiveVAT'),
  getCommissionFeeInclusiveVATFormatted: (parkingInfo, opts = {}) => {
    const {
      cultureCode = navigator?.language ||
        `en-${Parking.getCountryCode(parkingInfo)}`,
      currency = Parking.getCurrency(parkingInfo),
    } = opts;
    const price = Parking.getCommissionFeeInclusiveVAT(parkingInfo);
    return !_.isNil(price)
      ? formatCurrency({ cultureCode, currency, price })
      : null;
  },
  getCountryCode: (parkingInfo) => _.get(parkingInfo, 'country.countryCode'),
  getCurrency: (parkingInfo) => _.get(parkingInfo, 'currency'),
  getEndDate: (parkingInfo) => _.get(parkingInfo, 'endDate'),
  getIsResidentialParking: (parkingType) =>
    RESIDENTIAL_PARKING_SUB_TYPES.includes(parkingType),
  getMinAuthAmountInUserCurrency: (parkingInfo) =>
    _.get(parkingInfo, 'properties.minAuthorizationAmountInUserCurrency'),
  getParkingAreaName: (parkingInfo) => _.get(parkingInfo, 'parkingAreaName'),
  getParkingAreaNo: (parkingInfo) => _.get(parkingInfo, 'parkingAreaNo'),
  getParkingAreaType: (parkingInfo) => _.get(parkingInfo, 'parkingAreaType'),
  getParkingFeeExclusiveVAT: (parkingInfo) =>
    _.get(parkingInfo, 'parkingFeeExclusiveVAT'),
  getParkingFeeExclusiveVATFormatted: (parkingInfo, opts = {}) => {
    const {
      cultureCode = navigator?.language ||
        `en-${Parking.getCountryCode(parkingInfo)}`,
      currency = Parking.getCurrency(parkingInfo),
    } = opts;
    const price = Parking.getParkingFeeExclusiveVAT(parkingInfo);
    return !_.isNil(price)
      ? formatCurrency({ cultureCode, currency, price })
      : null;
  },
  getParkingFeeInclusiveVAT: (parkingInfo) =>
    _.get(parkingInfo, 'parkingFeeInclusiveVAT'),
  getParkingFeeInclusiveVATFormatted: (parkingInfo, opts = {}) => {
    const {
      cultureCode = navigator?.language ||
        `en-${Parking.getCountryCode(parkingInfo)}`,
      currency = Parking.getCurrency(parkingInfo),
    } = opts;
    const price = Parking.getParkingFeeInclusiveVAT(parkingInfo);
    return !_.isNil(price)
      ? formatCurrency({ cultureCode, currency, price })
      : null;
  },
  getParkingFeeVAT: (parkingInfo) => _.get(parkingInfo, 'parkingFeeVat'),
  getParkingFeeVATFormatted: (parkingInfo, opts = {}) => {
    const {
      cultureCode = navigator?.language ||
        `en-${Parking.getCountryCode(parkingInfo)}`,
      currency = Parking.getCurrency(parkingInfo),
    } = opts;
    const price = Parking.getParkingFeeVAT(parkingInfo);
    return !_.isNil(price)
      ? formatCurrency({ cultureCode, currency, price })
      : null;
  },
  getParkingId: (parkingInfo) => _.get(parkingInfo, 'parkingId'),
  getParkingOperatorId: (parkingInfo) =>
    _.get(parkingInfo, 'parkingOperatorId'),
  getParkingOperatorName: (parkingSession) =>
    _.get(parkingSession, 'parkingOperatorName'),
  getParkingSubType: (parkingInfo) => _.get(parkingInfo, 'subType'),
  // TODO: We may need to explore to see if EPIC's
  // app/currentuserstatus's parking user is the exact same structure
  getParkingUser: (parkingInfo) => _.get(parkingInfo, 'parkingUser'),

  getParkingUserEmail: (parkingInfo) =>
    _.get(Parking.getParkingUser(parkingInfo), 'email'),
  getParkingUserOptOutOfEmails: (parkingInfo) =>
    _.get(Parking.getParkingUser(parkingInfo), 'login.optOutOfEmails'),
  getStartDate: (parkingInfo) => _.get(parkingInfo, 'startDate'),
  getTotalFeeInclusiveVAT: (parkingInfo) =>
    _.get(parkingInfo, 'totalFeeInclusiveVAT'),
  getTotalFeeInclusiveVATFormatted: (parkingInfo, opts = {}) => {
    const {
      cultureCode = navigator?.language ||
        `en-${Parking.getCountryCode(parkingInfo)}`,
      currency = Parking.getCurrency(parkingInfo),
    } = opts;
    const price = Parking.getTotalFeeInclusiveVAT(parkingInfo);
    return !_.isNil(price)
      ? formatCurrency({ cultureCode, currency, price })
      : null;
  },
  getTotalFeeInclusiveVATInUserCurrency: (parkingInfo) =>
    _.get(parkingInfo, 'totalFeeInclusiveVATInUserCurrency'),
  getTotalFeeVAT: (parkingInfo) => _.get(parkingInfo, 'totalFeeVat'),
  getTotalFeeVATFormatted: (parkingInfo, opts = {}) => {
    const {
      cultureCode = navigator?.language ||
        `en-${Parking.getCountryCode(parkingInfo)}`,
      currency = Parking.getCurrency(parkingInfo),
    } = opts;
    const price = Parking.getTotalFeeVAT(parkingInfo);
    return !_.isNil(price)
      ? formatCurrency({ cultureCode, currency, price })
      : null;
  },
  getTransactionFeeExclusiveVAT: (parkingInfo) =>
    _.get(parkingInfo, 'transactionFeeExclusiveVAT'),
  getTransactionFeeExclusiveVATFormatted: (parkingInfo, opts = {}) => {
    const {
      cultureCode = navigator?.language ||
        `en-${Parking.getCountryCode(parkingInfo)}`,
      currency = Parking.getCurrency(parkingInfo),
    } = opts;
    const price = Parking.getTransactionFeeExclusiveVAT(parkingInfo);
    return !_.isNil(price)
      ? formatCurrency({ cultureCode, currency, price })
      : null;
  },
  getTransactionFeeInclusiveVAT: (parkingInfo) =>
    _.get(parkingInfo, 'transactionFeeInclusiveVAT'),
  getTransactionFeeInclusiveVATFormatted: (parkingInfo, opts = {}) => {
    const {
      cultureCode = navigator?.language ||
        `en-${Parking.getCountryCode(parkingInfo)}`,
      currency = Parking.getCurrency(parkingInfo),
    } = opts;
    const price = Parking.getTransactionFeeInclusiveVAT(parkingInfo);
    return !_.isNil(price)
      ? formatCurrency({ cultureCode, currency, price })
      : null;
  },
  getTransactionFeeVAT: (parkingInfo) =>
    _.get(parkingInfo, 'transactionFeeVAT'),
  getTransactionFeeVATFormatted: (parkingInfo, opts = {}) => {
    const {
      cultureCode = navigator?.language ||
        `en-${Parking.getCountryCode(parkingInfo)}`,
      currency = Parking.getCurrency(parkingInfo),
    } = opts;
    const price = Parking.getTransactionFeeVAT(parkingInfo);
    return !_.isNil(price)
      ? formatCurrency({ cultureCode, currency, price })
      : null;
  },
  getUserCurrency: (parkingInfo) => _.get(parkingInfo, 'userCurrency'),
  getWarningStatus: (parkingInfo) => _.get(parkingInfo, 'warningStatus'),
  getZonedEndDate: (parkingInfo) => _.get(parkingInfo, 'zonedEndDate'),
  getZonedStartDate: (parkingInfo) => _.get(parkingInfo, 'zonedStartDate'),
};

export const ActiveParking = {
  getCarCountryCode: (parkingSession) =>
    _.get(parkingSession, 'carCountryCode'),
  getCarLicenseNumber: (parkingSession) =>
    _.get(parkingSession, 'carLicenseNumber'),
  getCountryCode: (parkingSession) =>
    _.get(parkingSession, 'parkingAreaCountryCode'),
  getCurrency: (parkingSession) => _.get(parkingSession, 'currency'),
  getEndDate: (parkingSession) => _.get(parkingSession, 'end'),
  getParkingAreaCountryCode: (parkingSession) =>
    _.get(parkingSession, 'parkingAreaCountryCode'),
  getParkingAreaName: (parkingSession) =>
    _.get(parkingSession, 'parkingAreaName'),
  getParkingAreaNumber: (parkingSession) =>
    _.get(parkingSession, 'parkingAreaNumber'),
  getParkingAreaType: (parkingSession) =>
    _.get(parkingSession, 'parkingAreaType'),
  getParkingId: (parkingSession) => _.get(parkingSession, 'id'),
  getParkingOperatorId: (parkingSession) =>
    _.get(parkingSession, 'parkingOperatorId'),
  getParkingOperatorName: (parkingSession) =>
    _.get(parkingSession, 'parkingOperatorName'),
  getParkingType: (parkingSession) => _.get(parkingSession, 'parkingType'),
  getParkingUserId: (parkingSession) => _.get(parkingSession, 'parkingUserId'),
  getStartDate: (parkingSession) => _.get(parkingSession, 'start'),
  getTotalFeeInclusiveVAT: (parkingSession) =>
    _.get(parkingSession, 'priceInclVat'),
  getTotalFeeInclusiveVATFormatted: (parkingSession, opts = {}) => {
    const {
      cultureCode = navigator?.language ||
        `en-${ActiveParking.getCountryCode(parkingSession)}`,
      currency = ActiveParking.getCurrency(parkingSession),
    } = opts;
    const price = ActiveParking.getTotalFeeInclusiveVAT(parkingSession);
    return !_.isNil(price)
      ? formatCurrency({ cultureCode, currency, price })
      : null;
  },
};
