import { NAME } from '../constants';

export const BEFORE_HISTORY_CHANGE = `${NAME}/BEFORE_HISTORY_CHANGE`;

export const ROUTE_INITIALIZED_ON_CLIENT = `${NAME}/ROUTE_INITIALIZED_ON_CLIENT`;
export const ROUTE_INITIALIZED_ON_SERVER = `${NAME}/ROUTE_INITIALIZED_ON_SERVER`;

export const ROUTE_CHANGE_ERROR = `${NAME}/ROUTE_CHANGE_ERROR`;
export const ROUTE_CHANGE_START = `${NAME}/ROUTE_CHANGE_START`;
export const ROUTE_CHANGE_COMPLETE = `${NAME}/ROUTE_CHANGE_COMPLETE`;

export const REDIRECT = `${NAME}/REDIRECT`;
export const PUSH = `${NAME}/PUSH`;
export const REPLACE = `${NAME}/REPLACE`;

export const beforeHistoryChange = ({ asPath, pathname, query }) => ({
  payload: { asPath, pathname, query },
  type: BEFORE_HISTORY_CHANGE,
});

export const push = (...args) => ({
  meta: { routerMethod: 'pushRoute' },
  payload: args,
  type: PUSH,
});

export const redirect = (payload) => ({
  payload,
  type: REDIRECT,
});

export const replace = (...args) => ({
  meta: { routerMethod: 'replaceRoute' },
  payload: args,
  type: REPLACE,
});

export const routeInitializedOnClient = (payload) => ({
  payload,
  type: ROUTE_INITIALIZED_ON_CLIENT,
});

export const routeInitializedOnServer = (payload) => ({
  payload,
  type: ROUTE_INITIALIZED_ON_SERVER,
});

export const routeChangeError = ({ asPath, error }) => ({
  payload: { asPath, error },
  type: ROUTE_CHANGE_ERROR,
});

export const routeChangeComplete = ({ asPath, pathname, query }) => ({
  payload: { asPath, pathname, query },
  type: ROUTE_CHANGE_COMPLETE,
});

export const routeChangeStart = ({ asPath, pathname, query }) => ({
  payload: { asPath, pathname, query },
  type: ROUTE_CHANGE_START,
});
