import React from 'react';
import { Link } from '@parkmobile/ui';
import _ from 'lodash';
import { countries } from 'countries-list';
import { FeatureFlags } from '@/lib/feature-flags/models';
import { Area } from '@/api/easypark/entities/areas/models';
import { INPUT_INTERFACE_TYPES } from '@/api/easypark/entities/parkings/constants';
import { LEGAL_URLS } from './constants';

import i18n from '../../../i18n';

export const renderHTML = (html) => {
  const wrapper = document.createElement('div');
  wrapper.innerHTML = html;

  const handleNode = (node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      return node.textContent;
    }
    if (node.nodeType === Node.ELEMENT_NODE) {
      const { tagName, attributes } = node;
      const props = {};
      Array.from(attributes).forEach((attr) => {
        props[attr.name] = attr.value;
      });

      if (tagName.toLowerCase() === 'a') {
        const { href, ...otherProps } = props;

        return (
          <Link href={href} target='_blank' {...otherProps}>
            {Array.from(node.childNodes).map(handleNode)}
          </Link>
        );
      }

      const children = Array.from(node.childNodes).map(handleNode);

      return React.createElement(tagName.toLowerCase(), props, ...children);
    }
    return null;
  };

  return Array.from(wrapper.childNodes).map(handleNode);
};

export const getCountryByName = (countryName) =>
  _.findKey(countries, (_country) => _country.name === countryName) || null;

export const getTranslatedCountryName = (countryCode) => {
  if (countryCode === null) {
    return null;
  }
  const userPreferredLanguage = navigator?.language || 'en-US';
  const regionNames = new Intl.DisplayNames([userPreferredLanguage], {
    type: 'region',
  });
  return regionNames.of(countryCode);
};

/**
 * Get the available URLs for a specific type of legal url based on the country code and language.
 * @param {string} countryCode - The country abbreviation code.
 * @param {string} language - The desired language.
 * @returns {Object} The URL of the legal document or null if not found.
 */
export const getAllLegalUrls = (countryCode, language) =>
  Object.keys(LEGAL_URLS).reduce((result, legalType) => {
    const legalData = LEGAL_URLS[legalType];
    const countryData = legalData[countryCode] || legalData.SE;
    return {
      ...result,
      [legalType]: countryData[language] || countryData.en || null,
    };
  }, {});

/**
 * @param {object} req - server request object
 * @returns {string} user agent's language
 */
export function getPreferredLanguage(req) {
  const userAgentLanguage = _.get(req.headers, 'accept-language');
  const [languages] = _.split(userAgentLanguage, ';');
  const [language] = _.split(languages, ',');

  return language;
}

/**
 * @param {object} req - server request object
 * @returns {string} user agent or cookies language
 */
export function getI18nLanguage(req) {
  const language = getPreferredLanguage(req);
  const normalizedLanguage = _.replace(language, /-.*/, '');
  const i18nLanguage = _.get(req.cookies, 'i18next');

  return normalizedLanguage || i18nLanguage || i18n.language;
}

/**
 * Get preferred country code by the current language
 * @param {string} i18nLanguage - i18n language key
 * @param {array | string} supportedCountries - supported countries and areas
 * @returns {string} country code
 */
export function getPreferredCountryCode(i18nLanguage, supportedCountries) {
  if (!_.isPlainObject(supportedCountries)) {
    return 'IT';
  }
  const language = _.replace(i18nLanguage, /-.*/, '').toUpperCase();
  const supportedCountryNames = _.keys(supportedCountries);
  return supportedCountryNames.includes(language) ? language : 'IT';
}

/**
 * Validate if it's a supported area by country and area code
 * @param {object} area - country area object
 * @param {array | string} supportedCountries - supported countries and areas
 * @returns {boolean} if it's a supported area
 */
export function isCountryAreaCodeSupported(area, supportedCountries) {
  if (!area) return false;

  if (_.isEqual(supportedCountries, 'all')) return true;

  if (_.isEqual(supportedCountries, 'none')) return false;

  const countryCode = Area.getCountryCode(area)?.toUpperCase();
  const countryAreasAllowed = _.get(supportedCountries, countryCode);

  return (
    _.isEqual(countryAreasAllowed, 'all') ||
    _.includes(countryAreasAllowed, area.id)
  );
}

/**
 * Get configuration value by country and key
 * @param {object} countryConfigurations - country configuration FF object
 * @param {object} params - key of the value and country code
 * @returns {boolean} value of configuration
 */
export function getCountryConfiguration(
  countryConfigurations,
  { key, countryCode }
) {
  return _.get(countryConfigurations, ['countries', countryCode, key], false);
}

/**
 * Determines the appropriate input interface type based on the parking session's cost-neutral status.
 * @param {Object} flags - An object representing all feature flags, typically retrieved from a global state using a selector.
 * @param {Object} parkingSession - An object containing details about the current parking session.
 *   - parkingSession.parkingAreaCountryCode: The country code where the parking area is located.
 *   - parkingSession.parkingOperatorId: The ID of the parking operator managing the parking area.
 * @returns {String} - Returns the corresponding input interface type, either B2C_WEB_COST_NEUTRAL or B2C_WEB.
 */
export const getInputInterfaceType = (flags, parkingSession) => {
  // Retrieve all country configurations based on the provided flags
  const countryConfigurations =
    FeatureFlags.easyParkCountryConfigurations(flags);

  // Extract the list of cost-neutral operator IDs for the specific parking area's country code
  const costNeutralOperatorIds = getCountryConfiguration(
    countryConfigurations,
    {
      countryCode:
        parkingSession?.countryCode || parkingSession?.parkingAreaCountryCode, // The country code of the parking area
      key: 'cost-neutral-operator-ids', // The specific configuration key we are interested in
    }
  );

  // Check if the current parking operator ID is included in the list of cost-neutral operator IDs
  const isCostNeutral = _.includes(
    costNeutralOperatorIds,
    parkingSession?.parkingOperatorId
  );

  // Return the appropriate input interface type based on the cost-neutral status
  return isCostNeutral
    ? INPUT_INTERFACE_TYPES.B2C_WEB_COST_NEUTRAL
    : INPUT_INTERFACE_TYPES.B2C_WEB;
};
