import { useEventCallback } from '@parkmobile/ui';
import { useEffect, useRef } from 'react';
import { AppConfig, Topbar } from '@/lib/shared/models';
import { getAppConfig } from '@/lib/shared/store';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';

export function useLayout({ contentId }) {
  const router = useRouter();
  const mainNode = useRef(null);

  const appConfig = useSelector(getAppConfig);
  const topbar = AppConfig.getTopbar(appConfig);

  const { widgetContent } = router.query;
  const hideTopBar = Topbar.getHideTopBar(topbar) || widgetContent;
  const hideFooter = widgetContent;

  useEffect(() => {
    mainNode.current = document.getElementById(contentId);
  });

  const onSkipToMain = useEventCallback(() => {
    if (mainNode.current) {
      mainNode.current.tabIndex = 0;
      mainNode.current.focus();
      mainNode.current.tabIndex = -1;
    }
  });

  return [
    { hideFooter, hideTopBar },
    {
      onSkipToMain,
    },
  ];
}
