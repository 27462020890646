import _ from 'lodash';
import { combineReducers } from 'redux';
import * as actions from './actions';

export const notificationCenter = (
  state = { lastId: 0, notifications: [] },
  action
) => {
  switch (action.type) {
    case actions.NOTIFICATION_SHOWN:
      return {
        lastId: state.lastId + 1,
        notifications: [
          ...state.notifications,
          {
            ...action.payload,
            id: state.lastId + 1,
          },
        ],
      };
    case actions.NOTIFICATION_DISMISSED:
    case actions.NOTIFICATION_EXPIRED:
      return {
        lastId: state.lastId,
        notifications: _.reject(state.notifications, { id: action.payload }),
      };
    default:
      return state;
  }
};

export const userSettings = (state = {}, action) => {
  switch (action.type) {
    case actions.USER_SETTINGS_LOADED:
      return { ...action.payload };
    case actions.TOOLTIP_SHOWN:
      return {
        ...state,
        tooltipsShown: [...(state.tooltipsShown || []), action.payload],
      };
    case actions.TOUR_SHOWN:
      return {
        ...state,
        toursShown: [...(state.toursShown || []), action.payload],
      };
    default:
      return state;
  }
};

export const reducer = combineReducers({
  notificationCenter,
  userSettings,
});
