import { NAME } from '../constants';

export const ACCOUNT_MERGED_MODAL_OPENED = `${NAME}/ACCOUNT_MERGED_MODAL_OPENED`;
export const ACCOUNT_MERGED_MODAL_CLOSED = `${NAME}/ACCOUNT_MERGED_MODAL_CLOSED`;
export const MAGIC_LINK_USERNAME_SET = `${NAME}/MAGIC_LINK_USERNAME_SET`;

export const accountMergedModalOpened = () => ({
  type: ACCOUNT_MERGED_MODAL_OPENED,
});
export const accountMergedModalClosed = () => ({
  type: ACCOUNT_MERGED_MODAL_CLOSED,
});

export const magicLinkUsernameSet = (payload) => ({
  payload,
  type: MAGIC_LINK_USERNAME_SET,
});
