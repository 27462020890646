const _ = require('lodash');
const pathToRegexp = require('path-to-regexp');
const { parse } = require('url');

const matchPattern = _.memoize(
  (pattern, pathname) => !!pathToRegexp(pattern).exec(pathname),
  (pattern, pathname) => `${pattern}-${pathname}`
);

const parseUrl = _.memoize((url = '') => parse(url));

module.exports = function isMatch(pattern, url) {
  if (!pattern || !url) return false;

  if (parseUrl.cache.size > 100) parseUrl.cache.clear();
  if (matchPattern.cache.size > 100) matchPattern.cache.clear();

  const { pathname } = parseUrl(url);

  return !!matchPattern(pattern, pathname);
};
