import _ from 'lodash';
import moment from 'moment';
import { setInLocalStorage } from '@/lib/utils';
import { LOGOUT_EVENT_NAME } from './constants';

/*eslint-disable */
export const showHelperWidget = () => {
  if (typeof window !== 'undefined') {
    if (window.zEmbed) {
      window.zEmbed.show()
      return;
    }
    (function(e, t) {
      var n,
          o,
          d,
          i,
          s,
          a = [],
          r = document.createElement('iframe');
      (window.zEmbed = function() { a.push(arguments); }),
      (window.zE = window.zE || window.zEmbed),
      (r.src = 'javascript:false'),
      (r.title = ''),
      (r.role = 'presentation'),
      ((r.frameElement || r).style.cssText = 'display: none'),
      (d = document.getElementsByTagName('script')),
      (d = d[d.length - 1]),
      d.parentNode.insertBefore(r, d),
      (i = r.contentWindow),
      (s = i.document);
      try {
        o = s;
      } catch (e) {
        (n = document.domain),
        (r.src = 'javascript:var d=document.open();d.domain="' + n + '";void(0);'),
        (o = s);
      }
      (o.open()._l = function() {
        var o = this.createElement('script');
        n && (this.domain = n),
          (o.id = 'js-iframe-async'),
          (o.src = e),
          (this.t = +new Date()),
          (this.zendeskHost = t),
          (this.zEQueue = a),
          this.body.appendChild(o);
      }),
      o.write('<body onload="document._l();">'),
      o.close();
    })(
      'https://assets.zendesk.com/embeddable_framework/main.js',
      'parkmobile.zendesk.com'
    );
  }
}

export const  hideHelperWidget = () => {
  if (typeof window !== 'undefined' && window.zEmbed) {
    window.zEmbed.hide();
  }
}
/* eslint-enable */

export const setHelperWidgetTheme = () => {
  if (window !== 'undefined' && window.zEmbed) {
    const color = document
      ? getComputedStyle(document.documentElement).getPropertyValue(
          '--pm-color-primary-light'
        )
      : null;

    if (color) {
      window.zESettings = {
        webWidget: {
          color: {
            button: color,
            header: '#fafafa',
            launcher: color,
            theme: color,
          },
        },
      };
    }
  }
};

export const subscribeToLogoutEvent = (subscriber) => {
  if (subscribeToLogoutEvent.eventListener) {
    window.removeEventListener('storage', subscribeToLogoutEvent.eventListener);
  }

  const eventListener = _.debounce((e) => {
    if (e.key === LOGOUT_EVENT_NAME) {
      subscriber(e);
    }
  }, 16);

  window.addEventListener('storage', eventListener);
  subscribeToLogoutEvent.eventListener = eventListener;

  return () => {
    window.removeEventListener('storage', eventListener);
  };
};

export const emitLogoutEvent = () => {
  setInLocalStorage(LOGOUT_EVENT_NAME, moment());
};
