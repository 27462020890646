import _ from 'lodash';
import { NAME } from '../constants';
import { AppConfig } from '../models';

const get = (state) => state[NAME];

export const getAppConfig = (state) => _.get(state, [NAME, 'appConfig']);

export const getBrowser = (state) =>
  _.pickBy(_.get(state, [NAME, 'userAgent'], {}));

export const getIsAccessibilityViewActive = (state) =>
  _.get(state, [NAME, 'isAccessibilityViewActive']);

export const getIsAppMounted = (state) => _.get(state, [NAME, 'isAppMounted']);

export const getLanguagePreference = (state) =>
  _.get(state, [NAME, 'languagePreference']);

export const getOS = (state) => _.get(state, [NAME, 'userOS']);

export const getIsIE = (state) =>
  _.get(state, [NAME, 'userAgent', 'ie'], false);

/**
 * @deprecated Use styled-system syntax in core-ui components or useMediaQuery instead
 */
export const getIsMobile = (state) => {
  const width = get(state).windowWidth;
  if (width !== 0) return width < 769;

  return get(state).userOS === 'Android' || get(state).userOS === 'iOS';
};

export const getIsDesktop = (state) => {
  const width = get(state).windowWidth;

  if (width !== 0) return get(state).windowWidth > 1080;

  return !getIsMobile(state);
};

export const getIsTabletOrDesktop = (state) => !getIsMobile(state);

export const getUseAccessibilityTheme = (state) =>
  getIsAccessibilityViewActive(state) &&
  AppConfig.getEnableAccessibilityView(getAppConfig(state));

export const getWindowHeight = (state) => get(state).windowHeight;

export const getWindowWidth = (state) => get(state).windowWidth;

export const getFeatureToggle = (state, featureToggle) =>
  _.get(state, [NAME, 'appConfig', featureToggle]);
