import { Box } from '@parkmobile/ui';
import { useNProgress } from '@tanem/react-nprogress';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import h from 'react-hyperscript';

const ProgressContext = React.createContext();

ProgressIndicatorProvider.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export function ProgressIndicatorProvider({ children, className }) {
  const [isAnimating, setIsAnimating] = useState(false);
  const { animationDuration, isFinished, progress } = useNProgress({
    isAnimating,
  });

  return h(
    ProgressContext.Provider,
    {
      value: setIsAnimating,
    },
    [
      h(
        Box,
        {
          className,
          display: 'block',
          height: '3px',
          left: 0,
          opacity: isFinished ? 0 : 1,
          position: 'absolute',
          right: 0,
          style: {
            transition: `opacity ${animationDuration}ms linear`,
          },
          width: '100%',
        },
        [
          h(
            Box,
            {
              backgroundColor: 'brand',
              height: '2px',
              left: 0,
              marginLeft: `${(-1 + progress) * 100}%`,
              position: 'fixed',
              style: {
                transition: `margin-left ${animationDuration}ms linear`,
              },
              top: 0,
              width: '100%',
              zIndex: 'notification',
            },
            [
              h(Box, {
                boxShadow: 'sm',
                display: 'block',
                height: '100%',
                opacity: 1,
                position: 'absolute',
                right: 0,
                width: '100%',
              }),
            ]
          ),
        ]
      ),
      React.Children.only(children),
    ]
  );
}

export function useProgressIndicator() {
  const context = useContext(ProgressContext);
  if (context === undefined) {
    throw new Error(
      'useProgressIndicator must be used within ProgressIndicatorProvider'
    );
  }
  return context;
}
