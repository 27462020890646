/* eslint-disable no-console */
import _ from 'lodash';
import oneConfig from '@parkmobile/one-config';
import { format, parse } from 'url';
import NextRoutes, { Router } from './routes';

const config = oneConfig.config();

/**
 * Helper to construct a valid link that can be passed to nextjs's router or link component
 * @param {string} href - defaults to `/`, as an invalid `href` will cause an error
 * @returns {object} Returns an object with `href` and `as`
 */
export function getLink(href = '/') {
  return NextRoutes.findAndGetUrls(href).urls || { href };
}

// Safely invoke any method on the Next Router (it's undefined on server)
export function callRouterMethod(method, ...args) {
  try {
    Router[method](...args);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

// Gets the existing query defined in the router
export function getRouteQuery() {
  try {
    return Router.query;
  } catch (e) {
    return {};
  }
}

// Get current pathname
export function getRoutePathname() {
  try {
    return Router.pathname;
  } catch (e) {
    return undefined;
  }
}

export function getUrlFromAsPath(asPath) {
  try {
    const { urls } = NextRoutes.findAndGetUrls(asPath);
    return urls;
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

const parseUrl = _.memoize((url = '') => parse(url));
export const isExternalLink = (link = '') => {
  // The link argument could be an object or a string
  const parsedLink = _.isObject(link) ? link : parseUrl(link);
  const linkHost = _.get(parsedLink, 'host');

  // Parse the HOST value because it includes protocol in config
  const appHost = _.get(parseUrl(config.HOST), 'host');

  // If there's only a pathname, then the link is internal
  if (!linkHost) return false;

  // Clear the memoized cache if necessary
  if (parseUrl.cache.size > 100) parseUrl.cache.clear();

  // Check that the link's host and app's host match
  return linkHost !== appHost;
};

export const isInternalLink = (link) => !isExternalLink(link);

export function prefetch(url) {
  callRouterMethod('prefetch', url);
}

/**
 * Redirect or push new route depending on whether we're on server or client
 * We use a 307 temporary redirect as opposed to a 301 moved permanently
 * here so that when we attempt to access the page we redirected from
 * in the future the browser doesn't continue to automatically redirect
 */
export function redirect(url, res) {
  if (res) {
    res.writeHead(307, { Location: url });
    res.end();
  } else {
    callRouterMethod('pushRoute', url);
  }
}

/**
 * @param {string} url - base url to read query from
 * @param {string} queryParam - key of query param to return
 * @returns query param value
 */
export function readQueryParam(url, queryParam) {
  if (!queryParam) return null;
  const parsedUrl = parse(url, true);
  const paramValue = _.get(parsedUrl, ['query', queryParam]);
  return paramValue;
}

/**
 * @param {string} url - base url to append query param
 * @param {object} queryParams - object that contains the key-value pair for query param
 * @returns {string} concatenated base url and query param
 */
export function writeQueryParam(url, queryParams) {
  // remove queryParams with no values
  const filteredQueryParams = _.omitBy(queryParams, _.isNil);
  if (!filteredQueryParams) return url;
  const { query, search, ...rest } = parse(url, true);
  const newURLObj = {
    ...rest,
    query: {
      ...query,
      ...filteredQueryParams,
    },
  };
  return format(newURLObj);
}
