export const NAME = 'analytics';
export const ANALYTICS = `${NAME}/ANALYTICS`;

// In the future, these constants will be custom events
// defined internally to best describe actions taken
// in the app
export const GOOGLE_TAG_MANAGER_EVENTS = {
  purchase: 'purchase',
};

export const GOOGLE_TAG_MANAGER_DLV = {
  flowType: 'flowType',
};
