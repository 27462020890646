import PropTypes from 'prop-types';
import h from 'react-hyperscript';
import { StyledRoot } from './typography-styles';

Typography.propTypes = {
  align: PropTypes.oneOf(['center', 'left', 'right']),
  children: PropTypes.node,
  className: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  inherit: PropTypes.bool,
  transform: PropTypes.oneOf(['none', 'capitalize', 'lowercase', 'uppercase']),
  variant: PropTypes.oneOf([
    'display1',
    'display2',
    'display3',
    'h1',
    'h2',
    'h3',
    'body',
    'body2',
    'button',
    'label',
    'caption',
    'overline',
  ]),
};

Typography.defaultProps = {
  align: 'left',
  component: 'span',
  inherit: false,
  transform: 'none',
  variant: 'body',
};

export function Typography({
  align,
  children,
  className,
  component,
  inherit,
  transform,
  variant,
  ...props
}) {
  return h(
    StyledRoot,
    {
      as: component,
      className,
      ...props,
      modifiers: {
        align,
        inherit,
        transform,
        variant,
      },
    },
    [children]
  );
}
