import { InternalError, NotFound } from '@/lib/communication/components';
import { Box, showIf } from '@parkmobile/ui';
import PropTypes from 'prop-types';
import h from 'react-hyperscript';

ErrorView.propTypes = {
  statusCode: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
};

export function ErrorView({ statusCode }) {
  return h(
    Box,
    {
      alignItems: 'center',
      alignSelf: 'stretch',
      display: 'flex',
      flex: '1 1 auto',
      justifyContent: 'center',
      justifySelf: 'stretch',
    },
    [
      showIf(statusCode === 404)(h(NotFound)),
      showIf(statusCode !== 404)(h(InternalError)),
    ]
  );
}
