import styled from 'styled-components';

export const Root = styled.div(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  margin: '0 auto',
  maxWidth: '600px',
  padding: theme.getSpacing(4),
  ...theme.applyMediaQuery('mobile', {
    alignItems: 'flex-start',
  }),
}));

export const Image = styled.div(() => ({
  maxWidth: '400px',
  width: '100%',
}));

export const Title = styled.div(({ theme }) => ({
  marginTop: theme.getSpacing(3),
  textAlign: 'center',
  ...theme.applyMediaQuery('mobile', {
    textAlign: 'left',
  }),
}));

export const Message = styled.div(({ theme }) => ({
  marginTop: theme.getSpacing(1),
  textAlign: 'center',
  ...theme.applyMediaQuery('mobile', {
    textAlign: 'left',
  }),
}));

export const Button = styled.div(({ theme }) => ({
  marginTop: theme.getSpacing(2),
}));
