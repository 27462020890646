const _ = require('lodash');
/**
 * Wraps a Promise in a Promise that always resolves to a payload with data, error, and ok
 * @param  {Promise} promise a Promise (note that async functions return Promises)
 * @return {Promise}         a wrapped Promise that will resolve to { ok, data, error }
 */
module.exports = function resolvePromise(value) {
  const flatten = (result) => (isWrapped(result) ? result.data : result);
  const handleError = (error) => ({
    data: null,
    error,
    ok: false,
  });
  const handleSuccess = (data) => ({
    data,
    error: null,
    ok: true,
  });

  const promise = Promise.resolve(value);

  return promise.then(flatten).then(handleSuccess).catch(handleError);
};

function isWrapped(result) {
  return _.has(result, 'data') && _.has(result, 'error') && _.has(result, 'ok');
}
