import { trackMixpanelEvent } from '@/lib/analytics/helpers';
import { Zone } from '@/api/entities/zones/models';

export function trackNavigateToSettings() {
  trackMixpanelEvent('Navigate To Account Settings');
}

// Change Email

export function trackChangeEmailAttempted() {
  trackMixpanelEvent('Change Email Attempted');
}

export function trackChangeEmailSuccess() {
  trackMixpanelEvent('Change Email Success');
}

export function trackChangeEmailFailure({ errorCode }) {
  trackMixpanelEvent('Change Email Failure', { error_code: errorCode });
}

// Change Phone Number

export function trackChangePhoneNumberAttempted() {
  trackMixpanelEvent('Change Phone Number Attempted');
}

export function trackChangePhoneNumberSuccess() {
  trackMixpanelEvent('Change Phone Number Success');
}

export function trackChangePhoneNumberFailure({ errorCode }) {
  trackMixpanelEvent('Change Phone Number Failure', { error_code: errorCode });
}

// Change Password

export function trackChangePasswordAttempted() {
  trackMixpanelEvent('Change Password Attempted');
}

export function trackChangePasswordSuccess() {
  trackMixpanelEvent('Change Password Success');
}

export function trackChangePasswordFailure({ errorCode }) {
  trackMixpanelEvent('Change Password Failure', { error_code: errorCode });
}

export function trackActiveSessionsViewed({ accountType, sessionCount }) {
  trackMixpanelEvent('Active Sessions Viewed', {
    account_type: accountType,
    session_count: sessionCount,
  });
}

// Modify active parking sessions
export function trackActiveSessionExtended({
  durationInMins,
  parkingFee,
  paymentAmount,
  paymentMethod,
  remainingDurationInMins,
  transactionFee,
  zone,
}) {
  trackMixpanelEvent('Active Session Extended', {
    duration_in_mins: durationInMins,
    internal_zone_code: Zone.getInternalZoneCode(zone),
    parking_amount: parkingFee,
    parking_type: 'Zone',
    payment_amount: paymentAmount,
    payment_method: paymentMethod,
    remaining_duration_in_mins: remainingDurationInMins,
    signage_code: Zone.getSignageCode(zone),
    supplier_id: Zone.getSupplierId(zone),
    supplier_name: Zone.getSupplierName(zone),
    transaction_fee: transactionFee,
    zone_location_name: Zone.getLocationName(zone),
  });
}

export function trackSessionStopped({
  paymentMethod,
  remainingDurationInMins,
  zone,
}) {
  trackMixpanelEvent('Session Stopped', {
    internal_zone_code: Zone.getInternalZoneCode(zone),
    parking_type: 'Zone',
    payment_method: paymentMethod,
    remaining_duration_in_mins: remainingDurationInMins,
    signage_code: Zone.getSignageCode(zone),
    supplier_id: Zone.getSupplierId(zone),
    supplier_name: Zone.getSupplierName(zone),
    zone_location_name: Zone.getLocationName(zone),
  });
}
