import { getIsIE } from '@/lib/shared/store';
import { connect } from 'react-redux';
import { getNotifications, notificationDismissed } from '../../store';
import { NotificationCenter } from './notification-center';

export const NotificationCenterContainer = connect(
  (state) => ({
    isIE: getIsIE(state),
    notifications: getNotifications(state),
  }),
  {
    onNotificationDismissed: notificationDismissed,
  }
)(NotificationCenter);
