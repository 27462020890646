/**
 * Takes a list and replaces an item at the specified index
 * @param  {Boolean} [canExpand=false]  Should the array expand when index exceeds bounds
 * @param  {Number}  index              Index where item should be replaced
 * @param  {Any}  item                  The item that will be replace existing item at index
 * @param  {Array<Any>}   [items=[]]    An array of items
 * @return {Array<Any>}                 A new array with the item at the specified index
 */
module.exports = function replaceAtIndex({
  canExpand = false,
  index,
  item,
  items = [],
}) {
  const lastIndex = items.length - 1;
  const replace = (arr) => [
    ...arr.slice(0, index),
    item,
    ...arr.slice(index + 1),
  ];

  if (index < 0) {
    throw new Error('Error: Cannot replace a negative index.');
  }

  if (index > lastIndex) {
    if (!canExpand) {
      throw new Error(
        'Error: Cannot expand the array. Try setting canExpand: true'
      );
    }
    const filler = new Array(index - lastIndex);
    return replace(items.concat(filler));
  }

  return replace(items);
};
