import { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import h from 'react-hyperscript';
import _ from 'lodash';
import fp from 'lodash/fp';
import oneConfig from '@parkmobile/one-config';
import { ThemeContext } from 'styled-components';
import * as styles from './image-styles';

const config = oneConfig.config();

const baseUrl = `${config.IMAGE_CDN_URL}/image/fetch/`;
const fallbackImgSrc =
  'https://res.cloudinary.com/parkmobile-llc/image/upload/v1515006103/ConsolidatedWeb/shared/shared_no-image-available.png';

const getGravity = fp.get('gravity');
const getHeight = fp.get('height');
const getWidth = fp.get('width');
const getType = fp.get('type');

Image.propTypes = {
  altText: PropTypes.string,
  backgroundPosition: PropTypes.string,
  className: PropTypes.string,
  fillContainer: PropTypes.bool,
  imgSrc: PropTypes.string,
  loading: PropTypes.oneOf(['auto', 'lazy', 'eager']),
  matchBackground: PropTypes.bool,
  onClick: PropTypes.func,
  overlay: PropTypes.oneOf(['dark', 'light', 'none']),
  resize: PropTypes.shape({
    gravity: PropTypes.oneOf(['west', 'east', 'north', 'south']),
    height: PropTypes.string,
    type: PropTypes.oneOf(['pad', 'clip', 'maintain']),
    width: PropTypes.string,
  }),
  useAsBackground: PropTypes.bool,
};

export function Image({
  altText = '',
  backgroundPosition = 'center center',
  className = '',
  fillContainer = false,
  imgSrc = fallbackImgSrc,
  loading = 'auto',
  matchBackground = false,
  onClick,
  overlay,
  resize,
  useAsBackground = false,
  ...props
}) {
  const width = getWidth(resize);
  const height = getHeight(resize);
  const gravity = getGravity(resize);
  const theme = useContext(ThemeContext);
  const type = getType(resize);
  const resizeTypes = {
    clip: 'c_fill',
    maintain: 'c_limit',
    pad: 'c_pad',
  };

  const gravityParam = gravity ? `g_${gravity}` : '';
  const heightParam = height ? `h_${height}` : '';
  const widthParam = width ? `w_${width}` : 'w_auto';
  const backgroundParam = type === 'c_pad' ? 'b_rgb:0000' : '';
  const colorParam =
    matchBackground && theme.isDark() ? 'e_colorize,co_rgb:ffffff' : '';
  const typeParam = type ? resizeTypes[type] : '';
  const imageParams = [
    colorParam,
    backgroundParam,
    gravityParam,
    heightParam,
    typeParam,
    widthParam,
    'f_auto',
    'fl_lossy',
    'q_auto',
    'dpr_auto',
  ]
    .filter(_.size)
    .join(',');

  const desiredImageSrc = `${baseUrl}${imageParams}/${imgSrc}`;
  const failureImageSrc = `${baseUrl}${imageParams}/${fallbackImgSrc}`;
  const optimizedParams = ['w_50', 'h_50', 'f_auto', backgroundParam]
    .filter(_.size)
    .join(',');
  const optimizedImageSrc = `${baseUrl}${optimizedParams}/${imgSrc}`;

  const [didRequestFail, setDidRequestFail] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(true);

  const currentImageSrc = (() => {
    if (didRequestFail) return failureImageSrc;
    if (!useAsBackground) return desiredImageSrc;
    return isImageLoading ? optimizedImageSrc : desiredImageSrc;
  })();

  useEffect(() => {
    if (useAsBackground) {
      setIsImageLoading(true);
      const img = document.createElement('img');
      img.src = desiredImageSrc;
      img.onload = () => setIsImageLoading(false);
      img.onerror = () => {
        setIsImageLoading(false);
        setDidRequestFail(true);
      };

      return () => {
        img.src = null;
        img.onload = _.noop;
        img.onerror = _.noop;
      };
    }

    setIsImageLoading(false);

    return _.noop;
  }, [desiredImageSrc, optimizedImageSrc, useAsBackground]);

  return useAsBackground
    ? h(styles.BackgroundImage, {
        className,
        modifiers: {
          backgroundPosition,
          imgSrc: currentImageSrc,
          overlay,
        },
        title: altText || '',
        ...props,
      })
    : h(styles.Image, {
        alt: altText,
        className,
        loading,
        modifiers: {
          fillContainer,
        },
        onClick,
        onError: () => {
          setDidRequestFail(true);
        },
        src: currentImageSrc,
        ...props,
      });
}
