import * as authServiceAPI from '@/api/services/authentication/api';
import { getLink, isExternalLink } from '@/lib/routing/helpers';
import { Icon, Image } from '@/lib/shared/components';
import {
  Box,
  Button,
  ButtonBase,
  Space,
  Text,
  useFocusVisible,
  useTheme,
  Link,
} from '@parkmobile/ui';
import NextLink from 'next/link';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FocusOn } from 'react-focus-on';
import { useTopbar } from './topbar-hooks';
import * as styles from './topbar-styles';

Topbar.defaultProps = {
  apis: {
    authentication: authServiceAPI,
  },
};

Topbar.propTypes = {
  apis: PropTypes.shape({
    authentication: PropTypes.object,
  }),
  className: PropTypes.string,
  onSkipToMain: PropTypes.func,
};

export function Topbar({ apis, className, onSkipToMain }) {
  const [data, handlers] = useTopbar({ apis });
  const theme = useTheme();
  const { useAlternateTopbar } = theme.settings;

  const backgroundColor = useAlternateTopbar ? 'surface' : 'brand2';
  const borderBottom = 'thin';
  const borderBottomColor = useAlternateTopbar
    ? 'onSurface.divider'
    : 'onBrand2.divider';
  const color = useAlternateTopbar ? 'onSurface.primary' : 'onBrand2.primary';
  const flex = '0 0 auto';
  const height = 'appbarHeight';
  const width = '100%';
  // b2b link
  const { href: b2bLinkHref, text: b2bLinkText } = data.b2bSidebarLink;
  const isB2BSidebarLinkEnabled = data.isB2BSidebarLinkEnabled;

  return (
    <Box
      backgroundColor={backgroundColor}
      borderBottom={borderBottom}
      borderBottomColor={borderBottomColor}
      className={className}
      color={color}
      flex={flex}
      height={height}
      width={width}
    >
      {/* ---------- Navbar (Logo, Links, Hamburger) -------------------- */}
      <Box
        alignItems='center'
        as='header'
        color='inherit'
        display='flex'
        height='100%'
        paddingX='sm'
      >
        <Box
          alignItems='center'
          color='inherit'
          display='flex'
          flex='1 1 auto'
          flexDirection='row'
          position='relative'
        >
          <Logo
            appName={data.appName}
            href={data.logoLinkHref}
            src={data.logoImageSrc}
          />
          <SkipLink marginLeft={{ _: '0px', sm: 'md' }} onClick={onSkipToMain}>
            Skip Navigation
          </SkipLink>
        </Box>
        <Box
          alignItems='center'
          aria-label='Main menu'
          as='nav'
          color='inherit'
          display={{ _: 'none', md: 'flex' }}
          height='100%'
          id='topbar-main-menu'
          marginLeft='auto'
        >
          <Box
            alignItems='center'
            color='inherit'
            display='flex'
            height='100%'
            role='list'
          >
            {data.primaryLinks.map(({ href, text }) => (
              <Action href={href} marginLeft='xl' role='listitem' key={href}>
                {text}
              </Action>
            ))}
            <Box
              aria-hidden='true'
              backgroundColor={
                theme.settings.useAlternateTopbar
                  ? 'onSurface.divider'
                  : 'onBrand2.divider'
              }
              display={{ _: 'none', md: 'block' }}
              height='100%'
              marginLeft='xl'
              width='1px'
            />
            {data.isAuthenticated ? (
              <Action
                aria-label={`Go to account settings for ${data.username}`}
                data-pmtest-id='topbar-account-button'
                marginLeft='xl'
                onClick={handlers.onAccountSettingsClick}
                role='listitem'
                transform='lowercase'
              >
                {data.username}
              </Action>
            ) : (
              <Action
                aria-label='Go to Log In screen'
                data-pmtest-id='topbar-login-button'
                marginLeft='xl'
                onClick={handlers.onSignInClick}
                role='listitem'
                transform='capitalize'
              >
                Log In/Sign Up
              </Action>
            )}
          </Box>
        </Box>
        <ToggleButton
          aria-controls='topbar-side-menu'
          aria-expanded={data.isDrawerOpen}
          aria-label='Open side menu'
          marginLeft={{ _: 'auto', md: 'xl' }}
          onClick={handlers.onToggleButtonClick}
          open={false}
        />
      </Box>

      {/* ---------- Drawer (Sidebar, Mobile)-------------------- */}
      <styles.Scrim
        aria-hidden={true}
        modifiers={{ visible: data.isDrawerOpen }}
      />
      <FocusOn
        enabled={data.isDrawerOpen}
        onClickOutside={handlers.onScrimClick}
        onEscapeKey={handlers.onDrawerClose}
        scrollLock={false}
      >
        <styles.Drawer
          aria-hidden={!data.isDrawerOpen}
          aria-label='Side menu'
          as='nav'
          id='topbar-side-menu'
          modifiers={{ visible: data.isDrawerOpen }}
        >
          {data.isDrawerOpen && (
            <styles.DrawerAccount>
              <styles.DrawerAccountTitle>
                {data.isAuthenticated ? (
                  <Action
                    aria-label={`Go to account settings for ${data.username}`}
                    autoFocus={true}
                    data-pmtest-id='drawer-account-button'
                    onClick={handlers.onAccountSettingsClick}
                    role='listitem'
                    transform='lowercase'
                  >
                    {data.username}
                  </Action>
                ) : (
                  <Action
                    aria-label='Go to Log In screen'
                    autoFocus={true}
                    data-pmtest-id='drawer-login-button'
                    onClick={handlers.onSignInClick}
                    role='listitem'
                    transform='capitalize'
                  >
                    Log In/Sign Up
                  </Action>
                )}
                {data.isDrawerOpen && (
                  <Box
                    alignItems='center'
                    color='inherit'
                    display='flex'
                    height='appbarHeight'
                    position='absolute'
                    right='12px'
                    top='0px'
                  >
                    <ToggleButton
                      aria-label='Close side menu'
                      display='flex'
                      onClick={handlers.onToggleButtonClick}
                      open={true}
                    />
                  </Box>
                )}
              </styles.DrawerAccountTitle>
            </styles.DrawerAccount>
          )}

          {/* --------------- Drawer Sections ------------------- */}
          {data.isDrawerOpen && (
            <Box color='inherit' overflow='auto'>
              {data.isAuthenticated && (
                <DrawerSection marginTop='md' title='Account'>
                  <Action
                    onClick={handlers.onAccountSettingsClick}
                    weight='bolder'
                  >
                    Settings
                  </Action>
                  {data.drawerAccountLinks.map(({ href, text }) => (
                    <Action href={href} weight='bolder' key={href}>
                      {text}
                    </Action>
                  ))}
                  {/* B2B Sidebar Link; #b2b-sidebar-link -FF */}
                  {isB2BSidebarLinkEnabled && (
                    <Action
                      href={b2bLinkHref}
                      weight='bolder'
                      key={b2bLinkHref}
                      onClick={handlers.onB2BClick}
                      customRender={({ children }) => (
                        <Link
                          href={b2bLinkHref}
                          target='_blank'
                          rel='noopener'
                          color='inherit'
                          underline='none'
                        >
                          <Text
                            as='h1'
                            color='inherit'
                            variant='button'
                            weight='bolder'
                            marginBottom='sm'
                          >
                            {children}
                          </Text>
                          <Text variant='caption'>
                            Split your parking costs for business and private
                            rides
                          </Text>
                        </Link>
                      )}
                    >
                      {b2bLinkText}
                    </Action>
                  )}
                </DrawerSection>
              )}
              {data.drawerPrimaryLinks.length > 0 && (
                <DrawerSection
                  marginTop={data.isAuthenticated ? 'xl' : 'md'}
                  title='Main Menu'
                >
                  {data.drawerPrimaryLinks.map(({ href, text }) => (
                    <Action href={href} weight='bolder' key={href}>
                      {text}
                    </Action>
                  ))}
                </DrawerSection>
              )}
              {(data.isAuthenticated || data.drawerSupportLinks.length > 0) && (
                <DrawerSection
                  marginTop={
                    data.isAuthenticated || data.drawerPrimaryLinks.length > 0
                      ? 'xl'
                      : 'md'
                  }
                >
                  {data.drawerSupportLinks.map(({ href, text }) => (
                    <Action href={href} weight='bolder' key={href}>
                      {text}
                    </Action>
                  ))}
                  {data.isAuthenticated && (
                    <Action
                      disabled={data.isLogoutPending}
                      onClick={handlers.onLogout}
                      weight='bolder'
                    >
                      Log Out
                    </Action>
                  )}
                </DrawerSection>
              )}
            </Box>
          )}
        </styles.Drawer>
      </FocusOn>
    </Box>
  );
}

export function LinkWrapper(props) {
  const { children, className, ...restProps } = props;
  return (
    <NextLink {...restProps}>
      <a className={className}>{children}</a>
    </NextLink>
  );
}

LinkWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

function Action(props) {
  const {
    autoFocus = false,
    'aria-label': ariaLabel,
    children,
    customRender,
    'data-pmtest-id': dataPmtestId,
    disabled,
    href,
    marginRight,
    marginLeft,
    marginBottom,
    marginTop,
    onClick,
    role,
    transform = 'capitalize',
    weight = 'boldest',
  } = props;
  const isLink = !onClick && Boolean(href);
  const isExternal = useMemo(
    () => (isLink ? isExternalLink(href) : false),
    [href, isLink]
  );
  const link = useMemo(
    () => (isExternal ? { href } : getLink(href)),
    [href, isExternal]
  );
  const focusManager = useFocusVisible();

  const buttonAttributes = {
    autoFocus,
    disabled,
    onClick,
  };

  const linkAttributes = {
    href: link.href,
    ...(isExternal && {
      rel: 'noopener',
      target: '_blank',
    }),
  };
  const hasCustomRender = Boolean(typeof customRender === 'function');

  return (
    <Box
      color='currentColor'
      flex='0 0 auto'
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}
      marginTop={marginTop}
      maxWidth='220px'
      role={role}
    >
      <styles.ActionContent
        aria-label={ariaLabel}
        as={
          isLink
            ? (linkProps) => <LinkWrapper {...linkProps} as={link.as} />
            : ButtonBase
        }
        data-pmtest-id={dataPmtestId}
        {...(isLink ? linkAttributes : buttonAttributes)}
        modifiers={{ focusVisible: focusManager.isFocusVisible }}
        onBlur={focusManager.createBlurHandler()}
        onFocus={focusManager.createFocusHandler()}
        ref={focusManager.register}
      >
        <>
          {hasCustomRender ? (
            customRender(props)
          ) : (
            <Text
              as='span'
              color='inherit'
              transform={transform}
              truncate={true}
              variant='button'
              weight={weight}
            >
              {children}
            </Text>
          )}
          {isExternal && !hasCustomRender && <Space width='xs' />}
          {isExternal && !hasCustomRender && (
            <Box
              aria-label='Link Opens in New Window'
              color='inherit'
              height='16px'
              overflow='hidden'
              role='img'
              width='16px'
            >
              <Icon
                iconName='arrow-top-right'
                inheritColor={true}
                size='auto'
              />
            </Box>
          )}
        </>
      </styles.ActionContent>
    </Box>
  );
}

Action.propTypes = {
  'aria-label': PropTypes.string,
  autoFocus: PropTypes.bool,
  children: PropTypes.node.isRequired,
  customRender: PropTypes.func,
  'data-pmtest-id': PropTypes.string,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  marginBottom: PropTypes.string,
  marginLeft: PropTypes.string,
  marginRight: PropTypes.string,
  marginTop: PropTypes.string,
  onClick: PropTypes.func,
  role: PropTypes.string,
  transform: PropTypes.string,
  weight: PropTypes.string,
};

function DrawerSection({ children, marginTop, title }) {
  const theme = useTheme(); // Assuming you have the useTheme() hook available

  return (
    <Box
      color='inherit'
      display='flex'
      flexDirection='column'
      marginTop={marginTop}
      overflow='hidden'
      paddingX='lg'
    >
      {title && (
        <Box
          alignItems='center'
          color='inherit'
          display='flex'
          flexDirection='row'
          justifyContent='stretch'
          marginBottom={theme.space.custom(1.5)}
        >
          <Text as='h3' color='inherit' variant='h2' weight='boldest'>
            {title}
          </Text>
          <Box
            backgroundColor={
              theme.settings.useAlternateMenu
                ? theme.colors.onSurface.divider
                : theme.colors.onBrand2.divider
            }
            color='inherit'
            flex='1 1 auto'
            height='1px'
            marginLeft='md'
            marginTop='2px'
          />
        </Box>
      )}
      <Box color='inherit' display='flex' flexDirection='column' role='list'>
        {React.Children.toArray(children).map((child, i) => (
          <Box
            key={i}
            color='inherit'
            marginBottom={theme.space.custom(1.5)}
            marginRight='auto'
            marginTop='sm'
            role='listitem'
          >
            {child}
          </Box>
        ))}
      </Box>
    </Box>
  );
}

DrawerSection.propTypes = {
  children: PropTypes.node.isRequired,
  marginTop: PropTypes.string,
  title: PropTypes.string,
};

function Logo({ appName, href, src }) {
  const focusManager = useFocusVisible();
  const isExternal = useMemo(() => isExternalLink(href), [href]);
  const link = useMemo(
    () => (isExternal ? { href } : getLink(href)),
    [href, isExternal]
  );

  // eslint-disable-next-line no-nested-ternary
  const ariaLabel = isExternal
    ? `Navigate to ${link.href} (external link)`
    : link.href === '/'
    ? 'Navigate to home page'
    : `Navigate to ${link.as}`;

  const content = (
    <styles.LogoLink
      aria-label={ariaLabel}
      as='a'
      href={link.href}
      {...(isExternal && {
        rel: 'noopener',
        target: '_blank',
      })}
      modifiers={{
        focusVisible: focusManager.isFocusVisible,
      }}
      onBlur={focusManager.createBlurHandler()}
      onFocus={focusManager.createFocusHandler()}
      ref={focusManager.register}
    >
      <Image altText={`Image of ${appName} logo`} imgSrc={src} />
    </styles.LogoLink>
  );

  if (isExternal) return content;

  return (
    <NextLink as={link.as} href={link.href} passHref={false}>
      {content}
    </NextLink>
  );
}

Logo.propTypes = {
  appName: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
};

function SkipLink(props) {
  const { children, onClick, marginLeft } = props;
  const focusManager = useFocusVisible();
  const { isFocusVisible } = focusManager;
  const theme = useTheme();
  const { useAlternateTopbar } = theme.settings;
  const { onBrand2 } = theme.colors;

  return (
    <Box
      backgroundColor={useAlternateTopbar ? 'surface' : 'brand2'}
      color='currentColor'
      left='0px'
      marginLeft={marginLeft}
      position={{
        _: 'absolute',
        sm: 'static',
      }}
      right='0px'
      zIndex='appbar'
      {...(!isFocusVisible && {
        opacity: 0,
        overflow: 'hidden',
        style: {
          pointerEvents: 'none',
        },
        width: '16px',
      })}
    >
      <Button
        color={useAlternateTopbar ? 'neutral' : onBrand2.primary}
        onBlur={focusManager.createBlurHandler()}
        onClick={onClick}
        onFocus={focusManager.createFocusHandler()}
        ref={focusManager.register}
        variant='primary'
      >
        {children}
      </Button>
    </Box>
  );
}

SkipLink.propTypes = {
  children: PropTypes.node.isRequired,
  marginLeft: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object, // eg., { _: '0px', sm: 'md' }
  ]),
  onClick: PropTypes.func,
};

function ToggleButton(props) {
  const {
    as,
    display,
    marginLeft,
    marginRight,
    marginTop,
    marginBottom,
    open,
    ...rest
  } = props;
  const focusManager = useFocusVisible();

  return (
    <Box
      color='inherit'
      display={display}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}
      marginTop={marginTop}
    >
      <styles.ToggleButton
        {...rest}
        as={ButtonBase}
        modifiers={{ focusVisible: focusManager.isFocusVisible }}
        onBlur={focusManager.createBlurHandler()}
        onFocus={focusManager.createFocusHandler()}
        ref={focusManager.register}
      >
        <styles.ToggleButtonIcon modifiers={{ open }}>
          <span />
          <span />
          <span />
          <span />
        </styles.ToggleButtonIcon>
      </styles.ToggleButton>
    </Box>
  );
}

ToggleButton.propTypes = {
  as: PropTypes.elementType,
  display: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  marginBottom: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object, // eg., { _: '0px', sm: 'md' }
  ]),
  marginLeft: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object, // eg., { _: '0px', sm: 'md' }
  ]),
  marginRight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object, // eg., { _: '0px', sm: 'md' }
  ]),
  marginTop: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object, // eg., { _: '0px', sm: 'md' }
  ]),
  open: PropTypes.bool,
};
