import * as helpers from './helpers';

export const bailoutMiddleware = (store) => (next) => (action) => {
  if (helpers.isRequestAction(action)) {
    const { bailout = () => false } = helpers.getRequestActionInfo(action);

    if (bailout(store.getState())) {
      return;
    }
  }

  // eslint-disable-next-line consistent-return
  return next(action);
};
