import { AppConfig, Topbar } from '@/lib/shared/models';
import { getAppConfig, getIsAccessibilityViewActive } from '@/lib/shared/store';
import {
  unloadWarning,
  getClientsideIsGlobalUserAuthenticated,
} from '@/lib/utils';
import { useEventCallback, useOnce } from '@parkmobile/ui';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { User } from '@/api/easypark/entities/users/models';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import {
  identifyUser,
  trackMixPanelInstanceReset,
} from '@/views/global-onstreet-parking/analytics';
import { FeatureFlags } from '@/lib/feature-flags/models';
import { getAllFlags } from '@/lib/feature-flags/store';
import { trackEasyParkLogoutSuccess } from '../../../analytics';
import { useGlobalUserAuthenticationContext } from '../../authentication-provider/authentication-provider';

export function useTopbar({ apis }) {
  const router = useRouter();
  const { t } = useTranslation();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // AppConfig flags and values
  const appConfig = useSelector(getAppConfig);
  const appName = AppConfig.getName(appConfig);
  const flags = useSelector(getAllFlags);
  const isAccessibilityViewActive = useSelector(getIsAccessibilityViewActive);
  const isAccessibilityViewEnabled =
    AppConfig.getEnableAccessibilityView(appConfig);
  const logoImageSrc =
    isAccessibilityViewEnabled && isAccessibilityViewActive
      ? AppConfig.getTopbarAccessibilityThemeLogoSrc(appConfig)
      : AppConfig.getTopbarLogoSrc(appConfig);
  const topbar = AppConfig.getTopbar(appConfig);
  const isEasyparkActiveParkingsEnabled =
    FeatureFlags.isEasyparkActivityEnabled(flags);
  const isEnableGeneralLoginFlow = FeatureFlags.isEnableGeneralLoginFlow(flags);

  // Logo
  const logoLinkHref = Topbar.getLogoHref(topbar);

  // Primary links
  const primaryLinks = [
    {
      href: '/global/start',
      text: t('global:topbar:home'),
    },
  ];

  // Email / sign in
  const isGlobalUserAuthenticated = useGlobalUserAuthenticationContext();
  const { data: user, isLoading: isLoadingUserInfo } = useQuery(
    [apis.users.getUserDetails.queryKey],
    () => apis.users.getUserDetails(),
    { enabled: isGlobalUserAuthenticated, staleTime: 0 }
  );
  const username = User.getUsername(user);
  const ssoId = User.getSSOId(user);

  const isUserLoggedIn = getClientsideIsGlobalUserAuthenticated();

  const drawerAccountLinks = [
    {
      href: '/global/parkings/active',
      text: t('global:topbar:activity'),
    },
    {
      href: '/global/parkings/history',
      text: t('global:topbar:history'),
    },
  ];

  useEffect(() => {
    if (ssoId) {
      identifyUser({ ssoId });
    }
  }, [ssoId]);

  useOnce(() => {
    if (!isUserLoggedIn) {
      trackMixPanelInstanceReset();
    }
  });

  // Toggle Button
  const onToggleButtonClick = useEventCallback(() => {
    setIsDrawerOpen((prev) => !prev);
  });

  const onDrawerClose = useEventCallback(() => {
    setIsDrawerOpen(false);
  });

  // Scrim
  const onScrimClick = useEventCallback(() => {
    setIsDrawerOpen(false);
  });

  // Drawer
  const drawerPrimaryLinks = Topbar.getMenuMainLinks(topbar);

  // Close topbar menu on route change
  useOnce(() => {
    const closeMenu = () => {
      setIsDrawerOpen(false);
    };

    router.events.on('routeChangeStart', closeMenu);

    return () => {
      router.events.off('routeChangeStart', closeMenu);
    };
  });

  // Send logout request and handle success
  const { mutateAsync: logout, isLoading: isLoggingOut } = useMutation(
    apis.authentication.logoutUser,
    {
      onSuccess: () => {
        trackEasyParkLogoutSuccess();
        trackMixPanelInstanceReset();
        unloadWarning.disable();
        window.location.href = '/global/start';
      },
    }
  );

  const onLogoutClick = async () => {
    await logout();
  };

  return [
    {
      appName,
      drawerAccountLinks,
      drawerPrimaryLinks,
      isActiveParkingsEnabled: isEasyparkActiveParkingsEnabled,
      isDrawerOpen,
      isEnableGeneralLoginFlow,
      isGlobalUserAuthenticated,
      isLoadingUserInfo,
      isLoggingOut,
      logoImageSrc,
      logoLinkHref,
      primaryLinks,
      username,
    },
    {
      onDrawerClose,
      onLogoutClick,
      onScrimClick,
      onToggleButtonClick,
    },
  ];
}
