const moment = require('moment');
const fp = require('lodash/fp');

const validUnits = ['d', 'h', 'm', 's'];

const defaultTranslations = {
  day: 'day',
  days: 'days',
  hour: 'hour',
  hours: 'hours',
  minute: 'minute',
  minutes: 'minutes',
  second: 'second',
  seconds: 'seconds',
};

/**
 * Takes milliseconds and converts them to a list of days/hours/minutes/seconds units.
 * @param {Number} ms - Milliseconds of a duration
 * @param {Object} [options={}] - Additional options
 * @param {('d'|'h'|'m'|'s')} [options.precision=s] - The smallest unit of measurement
 * @param {Object} [options.translations=defaultTranslations] - Translations for unit names
 * @returns {Object[]} durationUnits - Array of duration units
 * @returns {Object} durationUnits.units - Description of unit
 * @returns {String} durationUnits.units.abbreviation - Abbreviation of unit
 * @returns {String} durationUnits.units.name - Name of unit
 * @returns {Number} durationUnits.value - Value of unit
 */
module.exports = function getDurationUnits(
  ms,
  { precision = 's', translations = defaultTranslations } = {}
) {
  if (!translations) {
    return [];
  }
  if (!ms || !fp.isNumber(ms)) {
    return [];
  }

  if (!fp.includes(precision, validUnits)) {
    return [];
  }

  const duration = moment.duration(ms);
  const days = fp.floor(duration.asDays());
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  const isPlural = (val) => val !== 1;

  const durationUnits = [
    {
      units: {
        abbreviation: 'd',
        name: isPlural(days) ? translations.days : translations.day,
      },
      value: days,
    },
    {
      units: {
        abbreviation: 'h',
        name: isPlural(hours) ? translations.hours : translations.hour,
      },
      value: hours,
    },
    {
      units: {
        abbreviation: 'm',
        name: isPlural(minutes) ? translations.minutes : translations.minute,
      },
      value: minutes,
    },
    {
      units: {
        abbreviation: 's',
        name: isPlural(seconds) ? translations.seconds : translations.second,
      },
      value: seconds,
    },
  ];

  const i = fp.findIndex(fp.isEqual(precision), validUnits);
  const isLessThan = fp.lt(i);
  const isZeroValue = fp.isEqual(0);

  const reject = fp.reject.convert({ cap: false });
  return reject(
    (durationUnit, index) =>
      isLessThan(index) || isZeroValue(durationUnit.value)
  )(durationUnits);
};
