import { NAME } from '../constants';

export const ACCESSIBILITY_VIEW_TOGGLED = `${NAME}/ACCESSIBILITY_VIEW_TOGGLED`;
export const APP_CONFIG_LOADED = `${NAME}/APP_CONFIG_LOADED`;
export const APP_MOUNTED = `${NAME}/APP_MOUNTED`;

export const WINDOW_HEIGHT_CHANGED = `${NAME}/WINDOW_HEIGHT_CHANGED`;
export const WINDOW_WIDTH_CHANGED = `${NAME}/WINDOW_WIDTH_CHANGED`;

export const LANGUAGE_PREFERENCE_LOADED = `${NAME}/LANGUAGE_PREFERENCE_LOADED`;

export const accessibilityViewToggled = () => ({
  type: ACCESSIBILITY_VIEW_TOGGLED,
});

export const appConfigLoaded = (appConfig) => ({
  payload: appConfig,
  type: APP_CONFIG_LOADED,
});

export const appMounted = () => ({
  type: APP_MOUNTED,
});

export const windowHeightChanged = (height) => ({
  payload: height,
  type: WINDOW_HEIGHT_CHANGED,
});

export const windowWidthChanged = (width) => ({
  payload: width,
  type: WINDOW_WIDTH_CHANGED,
});

export const languagePreferenceLoaded = (language) => ({
  payload: language,
  type: LANGUAGE_PREFERENCE_LOADED,
});
