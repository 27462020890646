import get from 'lodash/get';
import { createSelector } from 'reselect';
import { NAME } from '../constants';

export const getCurrentUrl = (state) => get(state, [NAME, 'currentUrl']);

export const getCurrentUrlAsPath = createSelector(getCurrentUrl, (currentUrl) =>
  get(currentUrl, 'asPath')
);

export const getError = (state) => get(state, [NAME, 'error']);

export const getPendingUrl = (state) => get(state, [NAME, 'pendingUrl']);

export const getPreviousUrl = (state) => get(state, [NAME, 'previousUrl']);

export const getQueryParamFromCurrentUrl = (state, paramKey) =>
  get(getCurrentUrl(state), ['query', paramKey]);
