import { bailoutMiddleware } from '@/lib/api/middlewares';
import _ from 'lodash';
import oneConfig from '@parkmobile/one-config';
import { applyMiddleware, createStore } from 'redux';
import { BATCH, enableBatching } from 'redux-batched-actions';
/* eslint-disable import/no-extraneous-dependencies */
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxFreeze from 'redux-freeze';
/* eslint-enable import/no-extraneous-dependencies */
import { createAnalyticsMiddleware } from '@/lib/analytics/helpers';
import { createLogger } from 'redux-logger';
import createSagaMiddleware, { END } from 'redux-saga';
import { configureReducers } from './configure-reducers';
import { configureSagas } from './configure-sagas';
import { reducerRegistry } from './reducer-registry';
import { sagaRegistry } from './saga-registry';

const thunkMiddleware = (store) => (next) => (action) =>
  _.isFunction(action) ? next(action(store.getState)) : next(action);

export const configureStore = (initialState = {}) => {
  const isBrowser = typeof window !== 'undefined';
  const debug = (action) => action && action.meta && action.meta.debug;
  const shouldLog = (action) =>
    typeof debug(action) === 'undefined' || debug(action);
  const isDev = oneConfig.get('NODE_ENV') !== 'production';
  const logger = createLogger({
    collapsed: true,
    predicate: (getState, action) => isBrowser && shouldLog(action),
  });
  const loggerMiddleware = oneConfig.get('ENABLE_LOGGING') ? [logger] : [];
  const reduxFreezeMiddleware = isDev ? [reduxFreeze] : [];
  const sagaMiddleware = createSagaMiddleware({
    emitter: (emit) => (action) => {
      if (action.type === BATCH) {
        action.payload.forEach(emit);
        return;
      }
      emit(action);
    },
  });
  const analyticsMiddleware = oneConfig.get('ENABLE_GTM')
    ? [createAnalyticsMiddleware()]
    : [];

  const middleware = [
    thunkMiddleware,
    bailoutMiddleware,
    sagaMiddleware,
    ...analyticsMiddleware,
    ...reduxFreezeMiddleware,
    ...loggerMiddleware,
  ];

  const reducers = reducerRegistry.getReducers();
  const rootReducer = enableBatching(configureReducers(reducers, initialState));
  const enhancer = applyMiddleware(...middleware);
  const composeEnhancers = composeWithDevTools({});
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(enhancer)
  );

  // Register a change listener that replaces the reducer
  reducerRegistry.setChangeListener((newReducers) => {
    store.replaceReducer(
      enableBatching(configureReducers(newReducers, store.getState()))
    );
  });

  // Add our saga task to the store
  const rootSaga = configureSagas(sagaRegistry.getSagas());
  const task = sagaMiddleware.run(rootSaga);

  store.finalize = async () => {
    if (isBrowser) return;

    store.dispatch(END);

    await task.done;
  };

  return store;
};
