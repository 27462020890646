/**
 * Takes any number of functions and returns a composed function that resolves each in order
 * @param  {Array[Function]} fns An array of functions
 * @return {Promise}     A promise object
 */
module.exports = function composeAsyncFns(fns = []) {
  return (initialValue) =>
    fns.reduceRight(
      (result, fn) => Promise.resolve(result).then(fn),
      initialValue
    );
};
