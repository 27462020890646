import fp from 'lodash/fp';

export const Request = {
  didCompleteAndFail: (request) => Boolean(request && request.failed),
  didCompleteAndSucceed: (request) =>
    Boolean(request && !request.isPending && !request.failed),
  didSendAndIsPending: (request) => Boolean(request && request.isPending),
  getId: fp.get('id'),
  getMetadata: fp.get('metadata'),
  getStatus: (request) => {
    if (Request.didCompleteAndFail(request)) return 'failed';
    if (Request.didCompleteAndSucceed(request)) return 'succeeded';
    if (Request.didSendAndIsPending(request)) return 'loading';
    return 'unsent';
  },
  isUnsentOrPending: (request) => Boolean(!request || request.isPending),
};

export const Error = {
  getId: fp.get('id'),
  getIsDismissed: fp.get('isDismissed'),
  getMessage: fp.get('message'),
  getStatus: fp.get('status'),
  getTag: fp.get('tag'),
};
