import {
  getLatestRequestByTag,
  getLatestUnhandledErrorByTag,
} from '@/lib/api/store';
import {
  allAsListSelectorFactory,
  findByIdSelectorFactory,
  findByTagSelectorFactory,
  resourceSelectorFactory,
} from '@/lib/data/store';
import fp from 'lodash/fp';
import * as actions from './actions';

// ---------------- Data ---------------------------
const getResource = resourceSelectorFactory(fp.get(['users']));

export const getAllUsers = allAsListSelectorFactory(getResource);

export const getUserById = findByIdSelectorFactory(getResource);

export const getUsersByTag = findByTagSelectorFactory(getResource);

export const getUser = (state, tag = actions.ACCOUNT_REQUEST_STARTED) =>
  getUsersByTag(state, tag)[0];

// -------------- API ---------------------------------

export const getUnhandledChangePasswordError = (state) =>
  getLatestUnhandledErrorByTag(state, actions.CHANGE_PASSWORD_REQUEST_STARTED);

export const getUnhandledProfileUpdateError = (state) =>
  getLatestUnhandledErrorByTag(state, actions.PROFILE_UPDATE_REQUEST_STARTED);

export const getUserRequestByTag = (state) =>
  getLatestRequestByTag(state, actions.ACCOUNT_REQUEST_STARTED);
