import { Image, Modal } from '@/lib/shared/components';
import { Box, Button, Space, Text } from '@parkmobile/ui';
import oneConfig from '@parkmobile/one-config';
import PropTypes from 'prop-types';
import React from 'react';

AccountMergedModal.propTypes = {
  handleContinue: PropTypes.func.isRequired,
  isFullScreen: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
};

const config = oneConfig.config();

export function AccountMergedModal({
  isFullScreen = false,
  isOpen,
  handleContinue,
}) {
  const content = (
    <Box py='sm'>
      <Image
        altText='' // presentational image, so '' alt text
        imgSrc={`${config.IMAGE_CDN_URL}/image/upload/ConsolidatedWeb/Merge_Illustration_u5w59w.png`}
        resize={{ width: '300' }}
      />
      <Space height='lg' />
      <Text as='h2' variant='h1'>
        {"We've connected your accounts!"}
      </Text>
      <Space height='lg' />
      <Text>{`We noticed you're using multiple ways to sign in to ParkMobile.
      To make it easier for you, we've connected your accounts.`}</Text>
      <Space height='lg' />
      <Button
        color='neutral'
        data-pmtest-id='account-merged-modal-continue-button'
        fullWidth={true}
        onClick={handleContinue}
      >
        Continue
      </Button>
    </Box>
  );

  return (
    <Modal
      content={content}
      hideCloseButton={true}
      isFullScreen={isFullScreen}
      isOpen={isOpen}
      onClose={handleContinue}
      title={null}
    />
  );
}
