export const NAME = 'users';

/**
 * Phonixx returns the following values for membershipTypeId for zone price:
 * 20 -> ParkMobilePro
 * 21 -> Basic Membership
 * 22 -> Company Membership
 * 23 -> Membership
 * 28 -> Firm Costcenters
 * 30 -> Retail Membership
 * Should use basic membership (membershipTypeId === 21) for most users.
 */
export const MEMBERSHIP_TYPES = {
  basic: 21,
  company: 22,
  firmCostcenters: 28,
  membership: 23,
  parkMobilePro: 20,
  retail: 30,
};

export const USER_ERRORS = {
  DUPLICATE_MOBILE_NUMBER_ERROR:
    'Another user is registered with this mobile number.',
};
