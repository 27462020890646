import { REQUEST } from '@/lib/api/constants';
import { NAME } from '../constants';

export const CLIENT_DATA_INITIALIZED = `${NAME}/CLIENT_DATA_INITIALIZED`;
export const CUSTOM_EVENT_LOGGED = `${NAME}/CUSTOM_EVENT_LOGGED`;
export const FLAG_CHANGES_RECEIVED = `${NAME}/FLAG_CHANGES_RECEIVED`;
export const UPDATE_FLAGS_REQUEST_FAILED = `${NAME}/UPDATE_FLAGS_REQUEST_FAILED`;
export const UPDATE_FLAGS_REQUEST_STARTED = `${NAME}/UPDATE_FLAGS_REQUEST_STARTED`;
export const UPDATE_FLAGS_REQUEST_SUCCEEDED = `${NAME}/UPDATE_FLAGS_REQUEST_SUCCEEDED`;

export const clientDataInitialized = ({
  bootstrap,
  flags,
  user,
  userHash,
}) => ({
  payload: { bootstrap, flags, user, userHash },
  type: CLIENT_DATA_INITIALIZED,
});

export const customEventLogged = ({
  data = null,
  eventName,
  metricValue = null,
}) => ({
  payload: { data, eventName, metricValue },
  type: CUSTOM_EVENT_LOGGED,
});

export const flagChangesReceived = (flags) => ({
  payload: flags,
  type: FLAG_CHANGES_RECEIVED,
});

export const updateFlagsRequestStarted = (flags) => ({
  meta: {
    [REQUEST]: {
      data: { flags },
      endpoint: '/user/flags',
      failure: UPDATE_FLAGS_REQUEST_FAILED,
      method: 'PUT',
      success: UPDATE_FLAGS_REQUEST_SUCCEEDED,
      tag: UPDATE_FLAGS_REQUEST_STARTED,
    },
  },
  payload: flags,
  type: UPDATE_FLAGS_REQUEST_STARTED,
});
