import { debounce } from 'lodash/fp';
import { eventChannel } from 'redux-saga';
import { call, fork, put, select, take } from 'redux-saga/effects';
import * as helpers from '../helpers';
import * as actions from './actions';
import * as selectors from './selectors';

function* initializeInBrowser() {
  yield fork(watchWindowSize);
}

function* watchWindowSize() {
  const windowHeight = yield call(helpers.getWindowHeight);
  const windowWidth = yield call(helpers.getWindowWidth);
  const windowResizeChannel = windowResizeChannelFactory();

  yield put(actions.windowHeightChanged(windowHeight));
  yield put(actions.windowWidthChanged(windowWidth));

  // eslint-disable-next-line no-constant-condition, fp/no-loops
  while (true) {
    yield take(windowResizeChannel);
    const previousHeight = yield select(selectors.getWindowHeight);
    const previousWidth = yield select(selectors.getWindowWidth);

    if (helpers.getWindowHeight() !== previousHeight) {
      yield put(actions.windowHeightChanged(helpers.getWindowHeight()));
    }

    if (helpers.getWindowWidth() !== previousWidth) {
      yield put(actions.windowWidthChanged(helpers.getWindowWidth()));
    }
  }
}

// eslint-disable-next-line fp/no-let
let windowListener;

function removeWindowListener() {
  if (windowListener) window.removeEventListener('resize', windowListener);
}

function windowResizeChannelFactory() {
  return eventChannel((emit) => {
    // Remove the existing listener to avoid memory leaks
    removeWindowListener();

    // Overwrite the old listener
    windowListener = debounce(16)(emit);
    window.addEventListener('resize', windowListener);

    // Return the unsubscribe method for redux-saga to call on END
    return removeWindowListener;
  });
}

export default function* sharedSaga() {
  const isBrowser = typeof window !== 'undefined';

  if (isBrowser) {
    yield fork(initializeInBrowser);
  }
}
