import { NAME } from '../constants';

export const ERROR_CLEARED = `${NAME}/ERROR_CLEARED`;
export const ERRORS_CLEARED = `${NAME}/ERRORS_CLEARED`;

export const REQUEST_CLEARED = `${NAME}/REQUEST_CLEARED`;
export const REQUESTS_CLEARED = `${NAME}/REQUESTS_CLEARED`;

export const ERROR_DISMISSED = `${NAME}/ERROR_DISMISSED`;

/**
 * Clear a single error by its ID
 */
export const errorCleared = (id) => ({
  payload: id,
  type: ERROR_CLEARED,
});

/**
 * Clear all errors with a matching tag
 */
export const errorsCleared = (tag) => ({
  payload: tag,
  type: ERRORS_CLEARED,
});

export const errorDismissed = (id) => ({
  payload: id,
  type: ERROR_DISMISSED,
});

/**
 * Clear a single request by its ID
 */
export const requestCleared = (id) => ({
  payload: id,
  type: REQUEST_CLEARED,
});

/**
 * Clear all requests with a matching tag
 */
export const requestsCleared = (tag) => ({
  payload: tag,
  type: REQUESTS_CLEARED,
});
