import _ from 'lodash';
import { combineReducers } from 'redux';
import * as actionTypes from './actions';
import * as helpers from '../helpers';
import { Error } from '../models';

export const errors = (state = {}, action) => {
  if (action.type === actionTypes.ERROR_CLEARED) {
    const id = action.payload;
    return _.omit(state, id);
  }

  if (action.type === actionTypes.ERRORS_CLEARED) {
    const tag = action.payload;
    return _.omitBy(state, (error) => Error.getTag(error) === tag);
  }

  if (action.type === actionTypes.ERROR_DISMISSED) {
    const id = action.payload;

    return {
      ...state,
      ...(!_.isNil(id) ? { [id]: { ...state[id], isDismissed: true } } : {}),
    };
  }

  if (helpers.isRequestAction(action)) {
    const { id } = helpers.getRequestActionInfo(action);
    return _.omit(state, id);
  }

  if (helpers.isSuccessAction(action)) {
    const { id } = helpers.getSuccessActionInfo(action);
    return _.omit(state, id);
  }

  if (helpers.isFailureAction(action)) {
    const info = helpers.getFailureActionInfo(action);
    const {
      endpoint,
      id,
      message,
      method,
      serverDidRespond,
      serverDidTimeout,
      status,
      tag = null,
      timestamp,
    } = info;

    return {
      ...state,
      ...(!_.isNil(id)
        ? {
            [id]: {
              endpoint,
              id,
              isDismissed: false,
              lastUpdatedAt: timestamp,
              message,
              method,
              serverDidRespond,
              serverDidTimeout,
              status,
              tag,
            },
          }
        : {}),
    };
  }

  return state;
};

export const requests = (state = {}, action) => {
  if (helpers.isRequestAction(action)) {
    const {
      endpoint,
      id,
      method,
      tag = null,
    } = helpers.getRequestActionInfo(action);

    return {
      ...state,
      ...(!_.isNil(id)
        ? {
            [id]: {
              // Set cacheCount to previous value or 0
              cacheCount: _.get(state, [id, 'cacheCount'], 0),
              // Increment count or set to 1 if first request
              count: _.get(state, [id, 'count'], 0) + 1,
              // Set the baseEndpoint
              endpoint,
              // Set failed to false since the request just started
              failed: false,
              // Set the id
              id,
              // Set isPending to true since the request just started
              isPending: true,
              // Set lastSentAt to now
              lastSentAt: new Date().toISOString(),
              // Set lastUpdatedAt to previous value or null
              lastUpdatedAt: _.get(state, [id, 'lastUpdatedAt'], null),
              // Set the request method
              method,
              // Set status to null since request is pending
              status: null,
              // Set the tag (used to identify who initiated the request)
              tag,
            },
          }
        : {}),
    };
  }

  if (helpers.isSuccessAction(action)) {
    const successInfo = helpers.getSuccessActionInfo(action);
    const {
      cached,
      endpoint,
      id,
      metadata,
      method,
      status,
      tag = null,
      timestamp,
    } = successInfo;
    const prevCacheCount = _.get(state, [id, 'cacheCount'], 0);

    return {
      ...state,
      ...(!_.isNil(id)
        ? {
            [id]: {
              ..._.get(state, id, {}),
              // Increment cache count if we got a cached response
              cacheCount: cached ? prevCacheCount + 1 : prevCacheCount,
              // Set the endpoint
              endpoint,
              // The request succeeded
              failed: false,
              // Set the id
              id,
              // The request is complete
              isPending: false,
              // This request was last updated now
              lastUpdatedAt: timestamp,
              // Any metadata the request might have (eg., page number)
              metadata,
              // Set the request method
              method,
              // Set the status
              status,
              // Set the tag (used to identify which feature initiated the request)
              tag,
            },
          }
        : {}),
    };
  }

  if (helpers.isFailureAction(action)) {
    const failureInfo = helpers.getFailureActionInfo(action);
    const {
      endpoint,
      id,
      metadata,
      method,
      status = null,
      timestamp,
      tag = null,
    } = failureInfo;

    return {
      ...state,
      ...(!_.isNil(id)
        ? {
            [id]: {
              ..._.get(state, id, {}),
              // Set the endpoint
              endpoint,
              // The request failed
              failed: true,
              // Set the id
              id,
              // The request is complete
              isPending: false,
              // The request was last updated now
              lastUpdatedAt: timestamp,
              // Any metadata the request might have (eg., page number)
              metadata,
              // Set the request method
              method,
              // Set the status
              status,
              // Set the tag (used to identify which feature initiated the request)
              tag,
            },
          }
        : {}),
    };
  }

  return state;
};

export default combineReducers({
  errors,
  requests,
});
