const { parse } = require('url');

module.exports = (...args) => {
  if (args.length === 0) {
    throw new Error('Must specify at least one "hostname" argument');
  }

  if (args.length === 1) {
    const [url1] = args;
    return (url2) => compareHostnames(url1, url2);
  }

  const [url1, url2] = args;
  return compareHostnames(url1, url2);
};

function compareHostnames(url1, url2) {
  if (!url1) {
    throw new Error('Must specify an argument for first "URL"');
  }

  if (!url2) {
    throw new Error('Must specify an argument for second "URL"');
  }

  return parseHostname(url1) === parseHostname(url2);
}

function parseHostname(url) {
  const parsedUrl = parse(url);

  if (parsedUrl.hostname) {
    return parsedUrl.hostname;
  }

  const { port, protocol, slashes } = parsedUrl;
  return url
    .replace(slashes ? `${protocol}://` : protocol, '')
    .replace('www.', '')
    .replace(port, '');
}
