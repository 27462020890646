/* eslint-disable sort-keys */

/* The order within the object is important to ensure proper functioning */
function redact(message = '', secretWord = 'SECRET') {
  const regexPatterns = {
    email: /\b[A-Za-z0-9._%+-]+[@|%40][A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g,
    phone: /\b(\d{3}[-. ]?\d{3}[-. ]?\d{4})\b/g,
    card: /\b(?:\d{4}[- ]?){3}\d{4}\b/g,
    address: /\b\d{1,5} [A-Za-z0-9 .,]+\b/g,
    // Modified usernameUrl and passwordUrl to use capturing groups instead of lookbehind to be compatible with ios 16.2 JS engine
    usernameUrl: /\b(username=)[^&\s]+/g,
    passwordUrl: /\b(password=)[^&\s]+/g,
  };

  // Use a replacement function to keep the parameter name and replace only its value
  const regexKeys = Object.keys(regexPatterns);
  return regexKeys.reduce((obscuredMessage, key) => {
    if (key === 'usernameUrl' || key === 'passwordUrl') {
      // For username and password, keep the parameter name and replace the value with 'SECRET'
      return obscuredMessage.replace(regexPatterns[key], `$1${secretWord}`);
    }

    return obscuredMessage.replace(regexPatterns[key], secretWord);
  }, message);
}

module.exports = redact;
