import { ROUTE_INITIALIZED_ON_SERVER } from '@/lib/routing/store';
import _ from 'lodash';
import { combineReducers } from 'redux';
import * as helpers from '../helpers';
import * as actions from './actions';

export const appConfig = (state = null, { payload, type }) => {
  if (type === actions.APP_CONFIG_LOADED) {
    return payload;
  }

  return state;
};

export const isAccessibilityViewActive = (state = false, { type }) => {
  if (type === actions.ACCESSIBILITY_VIEW_TOGGLED) return !state;
  return state;
};

export const isAppMounted = (state = false, { type }) => {
  if (type === actions.APP_MOUNTED) return true;
  return state;
};

export const languagePreference = (state = null, { payload, type }) => {
  if (type === actions.LANGUAGE_PREFERENCE_LOADED) {
    return payload;
  }

  return state;
};

export const userAgent = (state = {}, action) => {
  switch (action.type) {
    case ROUTE_INITIALIZED_ON_SERVER:
      return _.get(action, 'payload.req.user-agent-data', {});
    default:
      return state;
  }
};

export const userOS = (state = {}, action) => {
  switch (action.type) {
    case ROUTE_INITIALIZED_ON_SERVER:
      return _.get(action, 'payload.req.user-os-data', {});
    default:
      return state;
  }
};

export const windowHeight = (
  state = helpers.getWindowHeight(),
  { payload, type }
) => {
  if (type === actions.WINDOW_HEIGHT_CHANGED) {
    return payload;
  }

  return state;
};

export const windowWidth = (
  state = helpers.getWindowWidth(),
  { payload, type }
) => {
  if (type === actions.WINDOW_WIDTH_CHANGED) {
    return payload;
  }

  return state;
};

export default combineReducers({
  appConfig,
  isAccessibilityViewActive,
  isAppMounted,
  languagePreference,
  userAgent,
  userOS,
  windowHeight,
  windowWidth,
});
