import _ from 'lodash';
import {
  deepMergeStrategy,
  getDeleteActionInfo,
  getDropActionInfo,
  getUpdateActionInfo,
  isDeleteAction,
  isDropAction,
  isUpdateAction,
} from '../helpers';

const deleteEntity = (entity = {}, update = {}) => {
  const { ids = [], tag = null } = update;
  const idsAsStrings = _.map(ids, _.toString);
  const allIds = _.without(entity.allIds, ...idsAsStrings);
  const allTags = _.union(entity.allTags, tag ? [tag] : []);
  const byId = _.omit(entity.byId, ids);
  const byTag = {
    ...entity.byTag,
    ...(tag ? { [tag]: idsAsStrings } : {}),
  };
  return {
    allIds,
    allTags,
    byId,
    byTag,
  };
};

const updateEntity = (entity = {}, update = {}) => {
  const {
    byId = {},
    orderedIds = null,
    mergeStrategy = deepMergeStrategy,
    tag = null,
  } = update;
  const newIds = orderedIds ? orderedIds.map(_.toString) : Object.keys(byId);
  return {
    ...entity,
    allIds: _.union(entity.allIds, newIds),
    allTags: _.union(entity.allTags, tag ? [tag] : []),
    byId: {
      ...entity.byId,
      ...Object.keys(byId).reduce((result, id) => {
        const oldItem = _.get(entity, ['byId', id], {});
        const newItem = _.get(byId, id);
        return {
          ...result,
          [id]: mergeStrategy(oldItem, newItem),
        };
      }, {}),
    },
    byTag: {
      ...entity.byTag,
      ...(tag ? { [tag]: orderedIds } : {}),
    },
  };
};

export const reducer = (state = {}, action) => {
  if (isDeleteAction(action)) {
    const entitiesInfo = getDeleteActionInfo(action);

    return _.reduce(
      entitiesInfo,
      (nextState, entityUpdates, entityName) => ({
        ...nextState,
        [entityName]: deleteEntity(state[entityName], entityUpdates),
      }),
      { ...state }
    );
  }

  if (isDropAction(action)) {
    const entityNames = getDropActionInfo(action);
    return _.omit(state, entityNames);
  }

  if (isUpdateAction(action)) {
    const entitiesInfo = getUpdateActionInfo(action);

    return _.reduce(
      entitiesInfo,
      (nextState, entityUpdates, entityName) => ({
        ...nextState,
        [entityName]: updateEntity(state[entityName], entityUpdates),
      }),
      { ...state }
    );
  }

  return state;
};
