import { fetch } from '@/lib/api/helpers';

export async function registerUser({
  countryCode,
  decodedPhoneNumber,
  language,
  recaptchaV3Token,
}) {
  const result = await fetch({
    data: {
      countryCode,
      decodedPhoneNumber,
      language,
    },
    endpoint: '/global/register',
    headers: {
      'Content-Type': 'application/json',
      ...(recaptchaV3Token ? { 'x-recaptcha-v3-token': recaptchaV3Token } : {}),
    },
    method: 'POST',
  });

  return result;
}

export async function loginUser({ decodedPhoneNumber, password }) {
  const result = await fetch({
    data: {
      decodedPhoneNumber,
      password,
    },
    endpoint: '/global/login',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });

  return result;
}

export async function loginUserWithPinCode({ decodedPhoneNumber, pinCode }) {
  const result = await fetch({
    data: {
      decodedPhoneNumber,
      pinCode,
    },
    endpoint: '/global/login/pin-code',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });

  return result;
}

export async function verifyUserExists({ decodedPhoneNumber }) {
  const result = await fetch({
    endpoint: `/global/user/verify/${decodedPhoneNumber}`,
    method: 'GET',

    validateStatus(status) {
      return status >= 200 && status <= 300;
    },
  });

  return result;
}

export async function sendPinCode({
  countryCode,
  decodedPhoneNumber,
  recaptchaV3Token,
}) {
  const result = await fetch({
    data: {
      countryCode,
      decodedPhoneNumber,
    },
    endpoint: '/global/user/send-code',
    headers: {
      'Content-Type': 'application/json',
      ...(recaptchaV3Token ? { 'x-recaptcha-v3-token': recaptchaV3Token } : {}),
    },
    method: 'POST',
  });

  return result;
}

export async function refreshToken() {
  const result = await fetch({
    endpoint: '/global/refresh',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });

  return result;
}

export async function logoutUser() {
  const result = await fetch({
    endpoint: '/global/logout',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });

  return result;
}
