const _ = require('lodash');

/**
 * @param {String} filename The filename with extension.
 * @return {String} The filename without extension.
 */
module.exports = function removeExtensionFromFileName(filename) {
  if (!_.isString(filename)) {
    return null;
  }

  const array = _.split(filename, '.');

  // Return regular filename if extension is not found.
  if (array.length === 1) {
    return filename;
  }

  return _.join(_.slice(array, 0, array.length - 1), '.');
};
