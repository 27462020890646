import { User } from '@/api/entities/users/models';
import { getUser } from '@/api/entities/users/store';
import { writeQueryParam } from '@/lib/routing/helpers';
import { getCurrentUrl } from '@/lib/routing/store';
import { AppConfig, Topbar } from '@/lib/shared/models';
import { identifyBrazeUser } from '@/lib/analytics/helpers';
import { getAppConfig, getIsAccessibilityViewActive } from '@/lib/shared/store';
import { isMatch, unloadWarning } from '@/lib/utils';
import { FeatureFlags } from '@/lib/feature-flags/models';
import { getAllFlags } from '@/lib/feature-flags/store';
import { useEventCallback, useOnce } from '@parkmobile/ui';
import Router from 'next/router';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  trackNavigateToLogin,
  trackNavigateToSettings,
  trackNavigateToB2BContactForm,
} from '../../analytics';
import { emitLogoutEvent } from '../../helpers';
import { getIsAuthenticated } from '../../store';

export function useTopbar({ apis }) {
  // AppConfig flags and values
  const appConfig = useSelector(getAppConfig);
  const appName = AppConfig.getName(appConfig);
  const isAccessibilityViewActive = useSelector(getIsAccessibilityViewActive);
  const isAccessibilityViewEnabled =
    AppConfig.getEnableAccessibilityView(appConfig);
  const logoImageSrc =
    isAccessibilityViewEnabled && isAccessibilityViewActive
      ? AppConfig.getTopbarAccessibilityThemeLogoSrc(appConfig)
      : AppConfig.getTopbarLogoSrc(appConfig);
  const topbar = AppConfig.getTopbar(appConfig);
  const isEventParkingEnabled = AppConfig.getEnableEventParking(appConfig);
  const isReservationParkingEnabled =
    AppConfig.getEnableReservationParking(appConfig);
  const isZoneParkingEnabled = AppConfig.getEnableZoneParking(appConfig);
  const shouldShowAccountReservationsLink =
    isEventParkingEnabled || isReservationParkingEnabled;
  const shouldShowAccountZonesLink = isZoneParkingEnabled;
  const flags = useSelector(getAllFlags);
  // #b2b-sidebar-link -FF
  const isB2BSidebarLinkEnabled = FeatureFlags.isB2BSidebarLinkEnabled(flags);

  const accountLinkHref = '/account/settings';

  // Logo
  const logoLinkHref = Topbar.getLogoHref(topbar);

  // Primary links
  const primaryLinks = Topbar.getPrimaryLinks(topbar);

  // Email / sign in
  const isAuthenticated = useSelector(getIsAuthenticated);
  const user = useSelector(getUser);
  const mobile = User.getMobile(user);
  const formattedMobile = _.isEmpty(mobile)
    ? ''
    : `(${mobile.slice(0, 3)}) ${mobile.slice(3, 6)}-${mobile.slice(6, 10)}`;
  const username = User.getEmail(user) || formattedMobile;
  const { asPath, pathname } = useSelector(getCurrentUrl);
  const loginLinkHref = !isMatch(
    /\/login|\/register|\/reset-password|\/verify-email/,
    pathname
  )
    ? writeQueryParam('/login', { redirect: asPath })
    : '/login';

  // Toggle Button
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const onToggleButtonClick = useEventCallback(() => {
    setIsDrawerOpen((prev) => !prev);
  });

  const onDrawerClose = useEventCallback(() => {
    setIsDrawerOpen(false);
  });

  // Scrim
  const onScrimClick = useEventCallback(() => {
    setIsDrawerOpen(false);
  });

  // Sign In
  const onSignInClick = useEventCallback(() => {
    trackNavigateToLogin();
    window.location.href = loginLinkHref;
  });

  // Account Settings
  const onAccountSettingsClick = useEventCallback(() => {
    trackNavigateToSettings();
    window.location.href = accountLinkHref;
  });

  // B2B Form Link
  const onB2BClick = useEventCallback(() => {
    trackNavigateToB2BContactForm();
  });

  // Drawer
  const drawerPrimaryLinks = Topbar.getMenuMainLinks(topbar);
  const drawerAccountLinks = [
    shouldShowAccountReservationsLink && {
      href: '/account/reservations',
      text: 'Reservations',
    },
    shouldShowAccountZonesLink && {
      href: '/account/zones/active',
      text: 'Zones',
    },
  ].filter(Boolean);

  const b2bSidebarLink = {
    href: 'https://share.hsforms.com/1E7FrgGthTt--QhdxvrOaRg3m3gs',
    text: 'Business',
  };
  const helpHref = Topbar.getHelpHref(topbar);
  const contactHref = Topbar.getContactHref(topbar);
  const drawerSupportLinks = [
    helpHref && {
      href: helpHref,
      text: 'Need Help?',
    },
    contactHref && {
      href: contactHref,
      text: 'Contact Us',
    },
  ].filter(Boolean);

  // Close topbar menu on route change
  useOnce(() => {
    const closeMenu = () => {
      setIsDrawerOpen(false);
    };

    Router.events.on('routeChangeStart', closeMenu);

    return () => {
      Router.events.off('routeChangeStart', closeMenu);
    };
  });

  useEffect(() => {
    identifyBrazeUser(user);
    // not set dependencies to ensure that braze script is properly load
  });

  // Send logout request and handle success
  const { mutateAsync: logout, ...logoutInfo } = useMutation(
    apis.authentication.logout,
    {
      onSuccess: () => {
        emitLogoutEvent();
        /* Need to make sure that the user won't be prompted for the redirect here,
         * or else, if the warning is cancelled,
         * localstorage would still handle the logout event and clear its auth data.
         */
        unloadWarning.disable();
        window.location.href = '/login';
      },
    }
  );

  return [
    {
      appName,
      b2bSidebarLink,
      drawerAccountLinks,
      drawerPrimaryLinks,
      drawerSupportLinks,
      isAuthenticated,
      isB2BSidebarLinkEnabled,
      isDrawerOpen,
      isLogoutPending: logoutInfo.isLoading,
      logoImageSrc,
      logoLinkHref,
      primaryLinks,
      username,
    },
    {
      onAccountSettingsClick,
      onB2BClick,
      onDrawerClose,
      onLogout: logout,
      onScrimClick,
      onSignInClick,
      onToggleButtonClick,
    },
  ];
}
