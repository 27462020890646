/**
 * Return an array of list items in friendly text format
 * @param {string[]} list - An array of strings containing list items to join
 * @param {string} [separator=,] - string to use as separator
 * @param {string} [conjuction=and] - string to use as conjuction between last and second last value
 * @return {string} A string joined by separators and a conjunction (eg., 'dogs, cats, and horses')
 */
module.exports = function humanizeListPasswordValidation(
  list,
  separator = ',',
  conjunction = 'and'
) {
  if (!list || list.length === 0) return '';

  if (list.length === 1) {
    return list[0];
  }
  if (list.length === 2) {
    return list.join(` ${conjunction} `);
  }
  // for list with length > 2, last element will always have the "conjunction" (default "and")
  // preceding it
  const allButLast = list.slice(0, -1).join(`${separator} `);
  return `${allButLast}${separator} ${conjunction} ${list[list.length - 1]}`;
};
